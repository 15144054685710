import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  resetStatus,
  updateProfile,
  enterProfilePage,
  changePassword,
  goToStep,
  clickToStep,
  SELECTION_PROFILE_STEP,
  USER_INFO_PROFILE_STEP,
  CHANGE_PASSWORD_PROFILE_STEP,
  PAYMENT_METHOD_PROFILE_STEP,
  OLD_PAYMENT_METHOD_PROFILE_STEP,
} from 'ducks/profile';
import {
  loadPaymentMethod,
  updatePaymentMethod,
  loadAllAddress,
  updateConsentStatus,
  meLoadProfile,
  SetupStripeIntent,
} from 'ducks/user';
import { getRaceEnums, getEthnicityEnums } from 'ducks/enums';
import { showLoader, closeLoader } from 'ducks/ui';
import ProfileSelection from './ProfileSelection';
import UserInfoForm from './UserInfoForm';
import ChangePasswordForm from './ChangePasswordForm';
import CreditCardList from './CreditCardList';
import CreditCardFormV2 from './CreditCardFormV2';
import UpdateNotification from './UpdateNotification';
import StatusMessages from 'constants/StatusMessages';
import {
  getHomeAddress,
  getShippingAddress,
  getBillingAddress,
  HOME,
  SHIPPING,
  BILLING,
  redirectWithFlash,
} from 'services/utils';
import ResponsiveStepsPanel from 'components/widgets/SCP/StepsPanel/ResponsiveStepsPanel';
import gaTrack, { GA_TR_USER_PROFILE_PAGE } from '../../../../../services/gaTrack';
import track, { TR_HOME_ADDRESS_SUBMITTED } from 'services/track';
import { createAddress } from 'ducks/registrationWizard';
import _ from 'lodash';
import './ProfilePage.scss';
import { ConsentStatus } from '../../../../../constants/CommonConstants';
import schedulingService from '../../../../../services/api/schedulingService';
import api from 'services/api/api';

export class ProfilePage extends Component {
  static propTypes = {
    children: PropTypes.element,
    profile: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    updatePaymentMethod: PropTypes.func.isRequired,
    enterProfilePage: PropTypes.func.isRequired,
    loadPaymentMethod: PropTypes.func.isRequired,
    clickToStep: PropTypes.func,
    resetProfileStatus: PropTypes.func,
    loadAllAddress: PropTypes.func.isRequired,
    allAddress: PropTypes.array,
    dispatch: PropTypes.func,
    enums: PropTypes.object,
    getEthnicityEnums: PropTypes.func,
    getRaceEnums: PropTypes.func,
  };

  state = {
    showCCList: false,
    isMobile: false,
  };

  componentDidMount() {
    gaTrack(GA_TR_USER_PROFILE_PAGE);
    this.props.enterProfilePage();
    this.setState({ isMobile: schedulingService.isMobile() });
    window.addEventListener('resize', this.onResize);

    this.props.loadPaymentMethod();
    if (_.isEmpty(this.props.allAddress)) this.props.loadAllAddress();
  }

  onResize = () => {
    this.setViewMode();
  };

  setViewMode() {
    const { isMobile } = this.state;
    if (isMobile && !schedulingService.isMobile()) {
      this.setState({ isMobile: false });
    } else if (!isMobile && schedulingService.isMobile()) {
      this.setState({ isMobile: true });
    }
  }

  get onCloseNotification() {
    return this.props.resetProfileStatus;
  }

  getSelectedSaveAs = (billingAddress) => {
    const addressType = _.get(billingAddress, 'addressType', []);
    if (addressType.includes(HOME) && addressType.includes(SHIPPING)) {
      return 'home-shipping';
    } else if (addressType.includes(HOME)) {
      return 'home';
    } else if (addressType.includes(SHIPPING)) {
      return 'shipping';
    } else {
      return 'billing';
    }
  };

  updateProfileInformation = (values) => {
    const { dispatch } = this.props;
    const { billingSameAs, addresses, paymentInfo } = values;
    return createAddress(addresses, dispatch).then((data) => {
      dispatch(showLoader());
      track(TR_HOME_ADDRESS_SUBMITTED);
      let type = BILLING;
      if (['home-shipping', 'home'].includes(billingSameAs)) {
        type = HOME;
      } else if (['shipping'].includes(billingSameAs)) {
        type = SHIPPING;
      }
      let obj = (data || []).find((el) => el && el.addressType && el.addressType.includes(type));
      return obj;
    });
  };

  handleCCList = () => {
    this.setState({ showCCList: !this.state.showCCList });
  };

  handleUpdateProfile = (values) => {
    const {
      updateProfile,
      user: { me },
      updateConsentStatus,
      meLoadProfile,
      dispatch,
    } = this.props;
    const userConsent = _.get(me, ['latestPatientToReconatctStatusObject', 'user_consent']);
    const userConsentStatus = _.get(userConsent, ['status']);
    const isUserConsented = userConsentStatus == ConsentStatus.CONSENTED;
    const promiseAll = [];
    promiseAll.push(updateProfile(values));
    if (isUserConsented && values.consentRecontact == false) {
      const reqObj = {
        consent_version_id: me.latestPatientToReconatctStatusObject.id,
        status: ConsentStatus.REVOKED,
      };
      promiseAll.push(updateConsentStatus(reqObj));
    }
    dispatch(showLoader());
    return Promise.all(promiseAll)
      .then(() => {
        meLoadProfile();
        return dispatch(closeLoader());
      })
      .catch((err) => {
        dispatch(closeLoader());
        throw err;
      });
  };
  handlePaymentMethod = (data) => {
    const { updatePaymentMethod, dispatch, user } = this.props;
    dispatch(showLoader());
    return updatePaymentMethod(data)
      .then(() => {
        dispatch(closeLoader());
      })
      .catch((err) => {
        dispatch(closeLoader());
        if (err && err instanceof SubmissionError) {
          throw new SubmissionError(err.errors);
        }
        return err;
      });
  };

  render() {
    const {
      enums = {},
      profile,
      profile: { steps, activeStepIndex },
      user,
      user: { me, paymentMethod, allAddress },
      clickToStep,
      updateProfile,
      changePassword,
      getEthnicityEnums,
      getRaceEnums,
      getStripeIntent,
      registrationWizard: { formError },
    } = this.props;
    const homeAddress = getHomeAddress([...allAddress]);
    const shippingAddress = getShippingAddress([...allAddress]);
    const billingAddress = getBillingAddress([...allAddress]);
    const purePhone = me && me.primaryPhone && me.primaryPhone.replace(/[^\+0-9]/g, '');
    const purePhoneSecondary =
      me && me.secondaryPhone && me.secondaryPhone.replace(/[^\+0-9]/g, '');
    const dob =
      (me &&
        me.dateOfBirth &&
        moment(me.dateOfBirth).isValid() &&
        moment(me.dateOfBirth).format('MM/DD/YYYY')) ||
      null;
    const guardianDob =
      (me &&
        me.hasGuardian &&
        me.usersGuardian &&
        moment(me.usersGuardian.dob).isValid() &&
        moment(me.usersGuardian.dob).format('MM/DD/YYYY')) ||
      null;
    const guardianPrimaryPhone =
      (me.hasGuardian &&
        me.usersGuardian &&
        me.usersGuardian.guardianPrimaryPhone &&
        me.usersGuardian.guardianPrimaryPhone.replace(/[^\+0-9]/g, '')) ||
      null;
    const guardianPrimaryPhoneType =
      (me.hasGuardian && me.usersGuardian && me.usersGuardian.guardianPrimaryPhoneType) || null;
    const guardianPrimaryPhoneConsentToText =
      (me.hasGuardian && me.usersGuardian && me.usersGuardian.guardianPrimaryPhoneConsentToText) ||
      null;
    const guardianSecondaryPhone =
      (me.hasGuardian &&
        me.usersGuardian &&
        me.usersGuardian.guardianSecondaryPhone &&
        me.usersGuardian.guardianSecondaryPhone.replace(/[^\+0-9]/g, '')) ||
      null;
    const guardianSecondaryPhoneType =
      (me.hasGuardian && me.usersGuardian && me.usersGuardian.guardianSecondaryPhoneType) || null;

    const guardianPronouns =
      me.hasGuardian && me.usersGuardian && me.usersGuardian.guardianPronouns;
    const guardianPreferredName =
      me.hasGuardian && me.usersGuardian && me.usersGuardian.guardianPreferredName;
    const selectedSaveAs = this.getSelectedSaveAs(billingAddress);
    const preSelectedCountry = _.get(me, ['preSelectedCountry'], false);

    let payment = {
      ...paymentMethod,
    };

    const addressType = _.get(homeAddress, 'addressType', []);
    const sexAssignedAtBirth =
      me && me.sexAssignedAtBirth == null ? 'unknown' : me.sexAssignedAtBirth;
    const sexAssignedAtBirthIsNull =
      me && (me.sexAssignedAtBirth == null || me.sexAssignedAtBirth == 'unknown');

    const { showCCList, isMobile } = this.state;

    let ccInitialValues = {
      ...payment,
      ...homeAddress,
      addressCountry: _.get(homeAddress, 'addressCountry', preSelectedCountry),
    };
    const isCreateMode = _.isEmpty(_.get(payment, 'cardNumberLast4'));

    const userConsent =
      _.get(me, ['latestPatientToReconatctStatusObject', 'user_consent', 'status']) ==
      ConsentStatus.CONSENTED;
    const PaymentMethodStep = api.hasLegacyToken()
      ? OLD_PAYMENT_METHOD_PROFILE_STEP
      : PAYMENT_METHOD_PROFILE_STEP;
    return (
      <div className="scp-profile-page-container">
        {me && (
          <div>
            <UpdateNotification
              fade={false}
              profile={profile}
              user={user}
              onClose={this.onCloseNotification}
            />
            <div className="scp-profile-page full-height">
              <ResponsiveStepsPanel
                children={steps}
                activeStepIndex={activeStepIndex}
                showAll={true}
                onClickHandler={clickToStep}
              />

              {activeStepIndex === SELECTION_PROFILE_STEP && (
                <ProfileSelection
                  className={
                    [
                      USER_INFO_PROFILE_STEP,
                      CHANGE_PASSWORD_PROFILE_STEP,
                      PAYMENT_METHOD_PROFILE_STEP,
                    ].includes(activeStepIndex)
                      ? 'other-step'
                      : 'main-step'
                  }
                  title={'Profile'}
                  children={steps}
                  activeStepIndex={activeStepIndex}
                  onClickHandler={clickToStep}
                />
              )}

              {activeStepIndex === USER_INFO_PROFILE_STEP && (
                <UserInfoForm
                  onSubmit={this.handleUpdateProfile}
                  initialValues={{
                    ...me,
                    primaryPhone: purePhone,
                    secondaryPhone: purePhoneSecondary,
                    guardianPrimaryPhone,
                    guardianPrimaryPhoneType,
                    guardianPrimaryPhoneConsentToText,
                    guardianSecondaryPhone,
                    guardianSecondaryPhoneType,
                    dob,
                    guardianDob,
                    guardianPronouns,
                    guardianPreferredName,
                    sexAssignedAtBirth,
                    consentRecontact: userConsent,
                  }}
                  showDOB={false}
                  title={'General Information'}
                  sexAssignedAtBirthIsNull={sexAssignedAtBirthIsNull}
                  onClickHandler={clickToStep}
                  raceEnum={enums.races.data}
                  ethnicitiesEnum={enums.ethnicities.data}
                  getEthnicityEnums={getEthnicityEnums}
                  getRaceEnums={getRaceEnums}
                />
              )}

              {api.hasLegacyToken() && activeStepIndex === CHANGE_PASSWORD_PROFILE_STEP && (
                <ChangePasswordForm
                  onSubmit={(data) => changePassword(data)}
                  profile={profile}
                  title={steps[activeStepIndex].header}
                  onClickHandler={clickToStep}
                />
              )}

              {activeStepIndex === PaymentMethodStep && (
                <Fragment>
                  {showCCList && (
                    <CreditCardList
                      onClickHandler={this.handleCCList}
                      onBackHandler={clickToStep}
                      initialValues={ccInitialValues}
                      isCreateMode={isCreateMode}
                    />
                  )}
                  {!showCCList && (
                    <CreditCardFormV2
                      onSubmit={(data) => this.updateProfileInformation(data)}
                      user={user}
                      allAddress={allAddress}
                      homeAddress={homeAddress}
                      initialValues={ccInitialValues}
                      title={steps[activeStepIndex].header}
                      onClickHandler={this.handleCCList}
                      isCreateMode={isCreateMode}
                      CountriesStateEnums={enums.countryState.data}
                      getStripeIntent={getStripeIntent}
                      handlePaymentMethod={this.handlePaymentMethod}
                      dispatch={this.props.dispatch}
                      formError={formError}
                      isMobile={isMobile}
                    />
                  )}
                </Fragment>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  ({ enums, profile, user, registrationWizard }) => ({ enums, profile, user, registrationWizard }),
  (dispatch) => ({
    dispatch: dispatch,
    updateProfile: (data) => dispatch(updateProfile(data, false)),
    updateConsentStatus: (data) => dispatch(updateConsentStatus(data, false)),
    meLoadProfile: () => dispatch(meLoadProfile()),
    changePassword: (data) =>
      dispatch(changePassword({ oldPassword: data.oldPassword, newPassword: data.password })),
    updatePaymentMethod: (data) =>
      dispatch(updatePaymentMethod(data)).then(() => {
        dispatch(showLoader());
        dispatch(clickToStep(1));
        dispatch(clickToStep(3));
        dispatch(closeLoader());
        redirectWithFlash(StatusMessages.updatePayment);
      }),
    enterProfilePage: () => dispatch(enterProfilePage()),
    loadPaymentMethod: () => dispatch(loadPaymentMethod()),
    goToStep: (step) => dispatch(goToStep(step)),
    clickToStep: (step) => dispatch(clickToStep(step)),
    resetProfileStatus: () => dispatch(resetStatus()),
    loadAllAddress: () => dispatch(loadAllAddress()),
    getRaceEnums: () => dispatch(getRaceEnums()),
    getEthnicityEnums: () => dispatch(getEthnicityEnums()),
    getStripeIntent: () => dispatch(SetupStripeIntent()),
  })
)(ProfilePage);
