// @flow
import axios from 'axios';
import api from './api';
import _ from 'lodash';
import { getSurveyFrontendBaseUrl } from '../../ducks/misc/surveyScript';

let baseURL = null;
if (process.env.GM_ENV === 'development' && window.location.origin.indexOf('//portal') < 0) {
  baseURL = getSurveyFrontendBaseUrl() + '/api/v1';
} else baseURL = window.location.origin.replace('//portal', '//surveys') + '/api/v1';

export default class SurveyApi {
  api = axios.create({
    baseURL,
    transformRequest: [(data: Object): any => JSON.stringify(data)],
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    timeout: 35000,
    adapter: process.env.NODE_ENV === 'test' ? ['http'] : ['xhr'],
  });
  getSurveyResults(alias, token): Promise {
    return this.api
      .get(`/surveys/${alias}/results`, {
        params: { token },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (_.get(error, ['response', 'status'], 500) == 401 && api.hasToken()) {
          api.setToken(null);
          window.location = '/';
        }
        console.log('e', error);
        return null;
      });
  }

  getSurveys() {
    return api.get('/surveys');
  }
}
