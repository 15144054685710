import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import AppointmentInfoWithCancel from 'components/widgets/SCP/EventInfo/AppointmentInfoWithCancel';
import PhoneAppointmentModal from 'components/modals/SCP/PhoneAppointmentModal/PhoneAppointmentModal';
import VideoAppointmentModal from 'components/modals/SCP/VideoAppointmentModal/VideoAppointmentModal';
import CancelAppointmentModal from 'components/modals/SCP/CancelAppointmentModal/CancelAppointmentModal';
import AppointmentModalsSet from 'components/high-order-components/SCP/AppointmentModalsSet/AppointmentModalsSet';
import ButtonQuestionnaire from './ButtonQuestionnaire';
import {
  removeAction,
  OPEN_SWITCH_TO_PHONE,
  OPEN_SWITCH_TO_VIDEO,
} from 'ducks/misc/redirectActions';
import appointmentsService from 'services/api/appointmentsService';
import { setModalMode } from 'ducks/modals';
import modalNames from 'constants/ModalNames';
import YellowHint from 'components/widgets/SCP/YellowHint/YellowHint';
import { isAppointmentFromPackage } from 'containers/layouts/LayoutProps';
import { APPOINTMENT_TYPE_PHONE, APPOINTMENT_TYPE_VIDEO } from 'constants/CommonConstants';
import { CONTACT_PHONE } from 'services/constants';
import { clearDeletingError } from 'ducks/appointment';
import './AppointmentInfoWithYellowHint.scss';
import downloadIcon from 'images/common/download-gray.svg'; //eslint-disable-line
import phoneIcon from 'images/common/phone-gray.svg'; //eslint-disable-line
import AppointmentContainerForMobile from './AppointmentContainerForMobile';
import { withRouter } from 'react-router';

class AppointmentInfoWithYellowHint extends Component {
  componentDidUpdate() {
    this.checkPhoneAppointmentModal();
  }

  componenDidMount() {
    this.checkPhoneAppointmentModal();
  }

  openCancelAppointmentModal = () => {
    this.props.dispatch(clearDeletingError());
    this.props.dispatch(setModalMode(modalNames.cancelAppointment, { open: true }));
  };

  openPhoneAppointmentModal = () =>
    this.props.dispatch(setModalMode(modalNames.phoneAppointment, { open: true }));

  openVideoAppointmentModal = () =>
    this.props.dispatch(setModalMode(modalNames.videoAppointment, { open: true }));

  checkPhoneAppointmentModal() {
    const { dispatch, user, appointment, redirectActions, canSwitchToPhone, canSwitchToVideo } =
      this.props;
    if (!_.isEmpty(user.me) && appointment.loaded) {
      if (redirectActions.actions[OPEN_SWITCH_TO_PHONE]) {
        dispatch(removeAction(OPEN_SWITCH_TO_PHONE));
        if (canSwitchToPhone) {
          this.openPhoneAppointmentModal();
        }
      }
      if (redirectActions.actions[OPEN_SWITCH_TO_VIDEO]) {
        dispatch(removeAction(OPEN_SWITCH_TO_VIDEO));
        if (canSwitchToVideo) {
          this.openVideoAppointmentModal();
        }
      }
    }
  }

  goToSCPReschedule = () => {
    this.props.history.push('/patient/reschedule');
  };

  render() {
    const {
      user: { me },
      appointment,
      switchToPhone,
      canSwitchToPhone,
      canSwitchToVideo,
      isChangeable,
      modals,
      dispatch,
      isPackage,
      appointmentType,
      phone,
      showTitle,
      deletingError,
      isMobile,
      gotoAppointmentInfoForMobile,
      gotoVideoInstructionForMobile,
      showAppointmentInfoForMobile,
      showVideoInstructionForMobile,
      enums,
    } = this.props;

    const upcoming = !_.isEmpty(appointment) && appointment.data;
    const isLoading = _.isEmpty(upcoming) || _.isEmpty(me);
    const typeString = appointmentType === APPOINTMENT_TYPE_PHONE ? 'Phone' : 'Video';
    const { isPhoneOnly } = me;

    if (isLoading) return null;

    return isMobile ? (
      <Fragment>
        <AppointmentContainerForMobile
          gotoAppointmentInfoForMobile={gotoAppointmentInfoForMobile}
          gotoVideoInstructionForMobile={gotoVideoInstructionForMobile}
          showAppointmentInfoForMobile={showAppointmentInfoForMobile}
          showVideoInstructionForMobile={showVideoInstructionForMobile}
          openPhoneAppointmentModal={this.openPhoneAppointmentModal}
          openVideoAppointmentModal={this.openVideoAppointmentModal}
          onCancel={this.openCancelAppointmentModal}
          onReschedule={this.goToSCPReschedule}
        />
        <AppointmentModalsSet
          modals={modals}
          dispatch={dispatch}
          appointment={upcoming}
          user={_.get(this.props, 'user.me', {})}
        >
          <PhoneAppointmentModal
            phone={phone}
            error={switchToPhone.error}
            onHide={() => {}}
            isMobile={isMobile}
          />
          <VideoAppointmentModal
            phone={phone}
            error={switchToPhone.error}
            onHide={() => {}}
            isMobile={isMobile}
          />
          <CancelAppointmentModal enable={!isLoading} error={deletingError} isMobile={isMobile} />
        </AppointmentModalsSet>
      </Fragment>
    ) : (
      <div className="scp-appointment-info-with-yellow-hint">
        <AppointmentInfoWithCancel
          isChangeable={isChangeable}
          onReschedule={this.goToSCPReschedule}
          onCancel={this.openCancelAppointmentModal}
          title={showTitle ? `Your next ${typeString} appointment` : null}
          appointment={upcoming}
          hidePrice={isPackage !== false}
          appointmentType={appointmentType}
          enums={enums}
        />

        {upcoming && upcoming.byPhone ? (
          <YellowHint>
            <div className="by-phone">
              <img className="phone-icon" src={phoneIcon} alt="phone" />
              <p>
                Your genetic counselor will call you at{' '}
                <strong className="text-nowrap">{phone}</strong> at the time of your appointment.
                <br />
                If you want to change the contact phone number, call us at{' '}
                <strong className="text-nowrap">
                  <a className="gm-link" href={`tel:${CONTACT_PHONE}`}>
                    {CONTACT_PHONE}
                  </a>
                </strong>
              </p>
            </div>
          </YellowHint>
        ) : !isPhoneOnly ? (
          <YellowHint>
            <div className="install-zoom">
              <img className="download-icon" src={downloadIcon} alt="download" />
              <span className="install-zoom-text">
                <span>Install software for video appointment.</span>{' '}
                <a
                  className="scp-gm-link text-nowrap"
                  href="https://zoom.us/download"
                  target="_blank"
                >
                  Download Zoom Client
                </a>
                <div className="free-space" />
                <span>
                  Upon download completion,{' '}
                  <a
                    className="scp-gm-link text-nowrap"
                    href="https://zoom.us/test"
                    target="_blank"
                  >
                    test video
                  </a>{' '}
                  prior to appointment.
                </span>
              </span>
            </div>
          </YellowHint>
        ) : null}
        {!isPhoneOnly && canSwitchToPhone && (
          <p className="switch-to-phone-block">
            <span className="text-rpad text-nowrap">No microphone or camera?</span>{' '}
            <a
              className="scp-gm-link switch-phone-link text-nowrap"
              onClick={this.openPhoneAppointmentModal}
            >
              Switch to appointment by phone
            </a>
          </p>
        )}
        {canSwitchToVideo && !isPhoneOnly && (
          <p className="switch-to-phone-block">
            <span className="text-rpad text-nowrap">Microphone and camera available?</span>{' '}
            <a
              className="scp-gm-link switch-phone-link text-nowrap"
              onClick={this.openVideoAppointmentModal}
            >
              Switch to appointment by video
            </a>
          </p>
        )}

        {!isMobile && <ButtonQuestionnaire />}

        <div className="scp-cancellation-policy-section">
          <div className="cancellation-policy-header">Cancellation Policy:</div>
          <div className="cancellation-policy-body">
            Please call{' '}
            <a className="gm-link" href="tel:+18776880992">
              (877) 688-0992</a>{' '}
            at least 24 hours prior to your scheduled appointment to notify us of any changes or
            cancellations. To cancel a Monday appointment, please contact us by 3:00 PM EST on
            Friday.
          </div>
        </div>

        <AppointmentModalsSet
          modals={modals}
          dispatch={dispatch}
          appointment={upcoming}
          user={_.get(this.props, 'user.me', {})}
        >
          <PhoneAppointmentModal phone={phone} error={switchToPhone.error} onHide={() => {}} />
          <VideoAppointmentModal phone={phone} error={switchToPhone.error} onHide={() => {}} />
          <CancelAppointmentModal enable={!isLoading} error={deletingError} />
        </AppointmentModalsSet>
      </div>
    );
  }
}

AppointmentInfoWithYellowHint.propTypes = {
  user: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  appointment: PropTypes.object.isRequired,
  redirectActions: PropTypes.object.isRequired,
  switchToPhone: PropTypes.object.isRequired,
  canSwitchToPhone: PropTypes.bool.isRequired,
  isChangeable: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  isPackage: PropTypes.bool,
  appointmentType: PropTypes.string,
  phone: PropTypes.string.isRequired,
  showTitle: PropTypes.bool.isRequired,
  deletingError: PropTypes.string,
  isMobile: PropTypes.bool,
  gotoAppointmentInfoForMobile: PropTypes.func,
  gotoVideoInstructionForMobile: PropTypes.func,
  showAppointmentInfoForMobile: PropTypes.bool,
  showVideoInstructionForMobile: PropTypes.bool,
  enums: PropTypes.object.isRequired,
};

const getAppointmentWithFullPayment = (appointment) => {
  if (_.isEmpty(appointment)) return null;
  return {
    ...appointment,
    start: appointment.startTime,
    end: appointment.endTime,
    consultation: {
      ...appointment.consultation,
      price: appointment.payment ? appointment.payment.totalCharge : 0.0,
    },
  };
};

const mapStateToProps = (state) => {
  const { user, modals, switchToPhone, redirectActions, appointment, enums } = state;
  return {
    user,
    modals,
    switchToPhone,
    redirectActions,
    deletingError: appointment.deletingError,
    appointment: {
      loading: appointment.loading,
      loaded: appointment.loaded,
      data: getAppointmentWithFullPayment(appointment.data),
    },
    canSwitchToPhone: appointmentsService.canSwitchToPhone(appointment.data),
    canSwitchToVideo: appointmentsService.canSwitchToVideo(appointment.data),
    isChangeable: appointmentsService.isChangeable(appointment.data),
    isPackage: isAppointmentFromPackage(state),
    appointmentType:
      appointment.loaded && !_.isEmpty(appointment.data)
        ? appointment.data.byPhone
          ? APPOINTMENT_TYPE_PHONE
          : APPOINTMENT_TYPE_VIDEO
        : null,
    phone: !_.isEmpty(user.me) ? user.me.phone || '' : '',
    enums,
  };
};

export default connect(mapStateToProps)(withRouter(AppointmentInfoWithYellowHint));
