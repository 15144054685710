// @flow
import axios from 'axios';
import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import storage, { SessionStore } from 'services/storage';
import { getOriginApi, getOriginPortal } from 'services/utils';
import { ResponseError } from 'services/customErrors';

export const GM_ACCESS_TOKEN = 'GM_ACCESS_TOKEN';
export const GM_EDUCATION_SURVEY = 'GM_EDUCATION_SURVEY';
export const GM_NAVIGATOR_SURVEY = 'GM_NAVIGATOR_SURVEY';
export const X_API_AUTHORIZATION_HEADER = 'X-Api-Authorization';
const RESPONSE_TIMEOUT = 35000;
const UPLOADING_TIMEOUT = 180000;

import AppointmentsAPI from './appointmentsApi';
import AuthAPI from './authApi';
import SchedulingApi from './schedulingApi';
import UsersApi from './usersApi';
import ServicePackagesApi from './servicePackagesApi';
import EligibilityApi from './eligibilityApi';
import DocumentsApi from './documentsApi';
import SurveyApi from './surveyApi';
import InsuranceApi from './insuranceApi';
import SelectionApi from './selectionApi';
import EncountersApi from './encountersApi';
import AppMetaAPI from './appMetaApi';
import GeneticTestAPI from './geneticTestApi';

/**
 * @typedef {Object} API
 * @property {AppointmentsAPI} appointments
 * @property {AuthAPI} auth
 * @property {QuestionnaireAPI} questionnaire
 * @property {SchedulingAPI} scheduling
 * @property {UsersAPI} users
 * @property {EncountersApi} Encounters
 * @version 1.2.0
 */
class API {
  __appointments = new AppointmentsAPI();
  __auth = new AuthAPI();
  __scheduling = new SchedulingApi();
  __users = new UsersApi();
  __servicePackages = new ServicePackagesApi();
  __eligibility = new EligibilityApi();
  __documentsApi = new DocumentsApi();
  __surveyApi = new SurveyApi();
  __insuranceApi = new InsuranceApi();
  __selectionApi = new SelectionApi();
  __encountersApi = new EncountersApi();
  __AppMetaApi = new AppMetaAPI();
  __GeneticTestApi = new GeneticTestAPI();

  api = axios.create({
    baseURL: getOriginApi(),
    transformRequest: [(data: Object): any => JSON.stringify(data)],
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    timeout: RESPONSE_TIMEOUT,
    adapter: process.env.NODE_ENV === 'test' ? ['http'] : ['xhr'],
  });

  formsApi = axios.create({
    baseURL: getOriginApi(),
    timeout: UPLOADING_TIMEOUT,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  downloadApi = axios.create({
    baseURL: getOriginApi(),
  });

  portalApi = axios.create({
    baseURL: getOriginPortal(),
  });

  get appointments(): AppointmentsAPI {
    return this.__appointments;
  }
  get auth(): AuthAPI {
    return this.__auth;
  }
  get scheduling(): SchedulingApi {
    return this.__scheduling;
  }
  get users(): UsersApi {
    return this.__users;
  }
  get servicePackages(): ServicePackagesApi {
    return this.__servicePackages;
  }
  get eligibility(): EligibilityApi {
    return this.__eligibility;
  }
  get documents(): DocumentsApi {
    return this.__documentsApi;
  }
  get survey(): SurveyApi {
    return this.__surveyApi;
  }

  get insurance(): InsuranceApi {
    return this.__insuranceApi;
  }
  get selection(): SelectionApi {
    return this.__selectionApi;
  }

  get encounters(): EncountersApi {
    return this.__encountersApi;
  }

  get appmeta(): AppMetaAPI {
    return this.__AppMetaApi;
  }

  get genetictest(): GeneticTestAPI {
    return this.__GeneticTestApi;
  }

  get(url: string, ...args: any[]): Promise<any> {
    return this.sendRequestInternal(this.api.get, url, ...args);
  }

  post(url: string, ...args: any[]): Promise<any> {
    return this.sendRequestInternal(this.api.post, url, ...args);
  }

  patch(url: string, ...args: any[]): Promise<any> {
    return this.sendRequestInternal(this.api.patch, url, ...args);
  }

  // cause axios don't send request body if use 'axios.delete' alias
  delete(url: string, ...args: any[]): Promise<any> {
    return this.sendRequestInternal(
      (u, a) => {
        const conf = {
          method: 'delete',
          url,
          data: undefined,
        };
        if (a) {
          conf.data = a;
        }
        return this.api(conf);
      },
      url,
      ...args
    );
  }

  postMultipartFormData(url: string, data: Object, onUploadProgress: Function): Promise<any> {
    return this.sendRequestInternal(
      (u, a) => this.formsApi.post(u, a, { onUploadProgress }),
      url,
      data
    );
  }

  getResponseTypeData(url: string, ...args: any[]): Promise<any> {
    const accessToken = storage.get(GM_ACCESS_TOKEN);
    if (accessToken) {
      this.downloadApi.defaults.headers.common[X_API_AUTHORIZATION_HEADER] = accessToken;
    }

    return this.downloadApi.get(url, ...args);
  }

  sendRequestInternal(requestFunc: Function, url: string, ...args: any[]): Promise<any> {
    const accessToken = storage.get(GM_ACCESS_TOKEN);
    if (accessToken) {
      this.api.defaults.headers.common[X_API_AUTHORIZATION_HEADER] = accessToken;
      this.formsApi.defaults.headers.common[X_API_AUTHORIZATION_HEADER] = accessToken;
    }

    return requestFunc(url, ...args)
      .then((response: Object) => response.data && response.data.data)
      .catch((error: Object) => {
        if (error.response) {
          if (_.get(error, ['response', 'status'], 500) === 401 && this.hasToken()) {
            throw error;
          }
          throw this.prepareError(error.response);
        } else if (error.request) {
          const message = "Can't get response from the server";
          throw new ResponseError({
            message,
            fullMessage: this.getUnhandledErrorString(message),
          });
        } else throw error;
      });
  }

  getUnhandledErrorString(errorMessage: string) {
    return errorMessage ? `Error: ${errorMessage}` : 'Oops: something went wrong.';
  }

  prepareError(response: ?Object, message: string): ?Object {
    if (!response || !response.data) {
      /* eslint-disable */
      console.warn('Request error. ' + message);
      return new ResponseError({
        message: 'Request error. ' + message,
        fullMessage: 'Request error. ' + message,
      });
    }
    const data = response.data;
    if (typeof data === 'string') {
      /* eslint-disable */
      console.warn('Request error. ' + response.data);
      return new ResponseError({
        status: response.status,
        message: response.data,
        fullMessage: this.getUnhandledErrorString(response.data),
      });
    }
    /* eslint-disable */
    console.warn(
      'Request error: status=' +
        data.status_code +
        ', code=' +
        data.code +
        ', message: ' +
        data.message
    );
    /* eslint-enable */
    const errorData = {
      status: +response.status,
      code: data.code,
      message: data.message,
      data: data.data,
      fullMessage: this.getUnhandledErrorString(data.message),
      fields: data.fields
        ? _.mapValues(data.fields, (fieldError) => Object.values(fieldError)[0])
        : undefined,
      rawFields: data.fields,
    };
    return new ResponseError(errorData);
  }

  getPortal(url: string, ...args: any[]): Promise<any> {
    return this.portalApi.get(url, ...args);
  }

  hasToken() {
    return !!storage.get(GM_ACCESS_TOKEN);
  }

  decodeToken(token: string): ?Object {
    try {
      return jwtDecode(token);
    } catch (e) {
      return null;
    }
  }

  scopesofToken() {
    const hastoken = this.hasToken();
    const token = this.getToken();
    if (hastoken) {
      const decodeToken = this.decodeToken(token);
      const scopes = decodeToken.scopes;
      if (scopes && scopes['frontend']) return scopes['frontend'];
    } else {
      return hastoken;
    }
  }

  hasTokenIncludeScope(scope) {
    const hastoken = this.hasToken();
    if (hastoken) {
      const userScopes = this.scopesofToken();
      if (userScopes) {
        return userScopes.find((a) => a == scope);
      }
    }
    return undefined;
  }

  getToken(): ?string {
    return storage.get(GM_ACCESS_TOKEN);
  }

  setToken(token: string | null, isSession: ?boolean) {
    storage.remove(GM_ACCESS_TOKEN);
    if (token != null) {
      (isSession ? SessionStore : storage).set(GM_ACCESS_TOKEN, token);
    }
  }

  hasLegacyToken() {
    const token = this.getToken();
    return this.hasToken() && !token?.startsWith('Bearer');
  }

  hasAuth0Token() {
    const token = this.getToken();
    return this.hasToken() && token?.startsWith('Bearer');
  }
}

export default new API();
