import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Footer from 'components/scp/footer/Footer';
import ReleaseNotice from 'components/widgets/SCP/ReleaseVersionNotice/ReleaseNotice';

import { REDIRECT_AFTER_LOGIN } from 'ducks/misc/redirectActions';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';
import authService from 'services/api/authService';
import { AUTH0_LOGIN_ENABLED } from '../Auth0Wrapper';

export class PublicLayoutV2 extends Component {
  static propTypes = {
    children: PropTypes.element,
    redirectActions: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    match: PropTypes.object,
  };

  componentDidUpdate() {
    const {
      redirectActions: { actions },
      match: { params },
      history,
    } = this.props;
    if (params.activationToken) return;
    if (authService.getRedirectionAction() && !AUTH0_LOGIN_ENABLED && !this.redirected) {
      this.redirected = true;
      history.push('/auth0/login');
    } else if (
      (actions[REDIRECT_AFTER_LOGIN] || authService.getRedirectionAction()) &&
      !this.redirected
    )
      this.redirectAfterSignIn();
  }

  redirected = false;

  redirectAfterSignIn = () => {
    const { history } = this.props;
    this.redirected = true;
    history.push('/login');
  };

  render() {
    const { children } = this.props;
    return (
      <div className="main-container scp">
        <ReleaseNotice />
        {children}
        <Footer />
        <GlobalLoader />
      </div>
    );
  }
}

export default connect((state) => ({
  redirectActions: state.redirectActions,
}))(withRouter(PublicLayoutV2));
