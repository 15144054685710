import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import SuccessIcon from './success.svg';
import './TestOrderRequestConfirmDetail.scss';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import { orderStatuses } from 'constants/TestOrderConstants';

class TestOrderRequestConfirmDetail extends Component {
  static propTypes = {
    shippingAddress: PropTypes.object,
    personalInfo: PropTypes.object,
    currentScpEncounter: PropTypes.object,
  };

  renderOrderDetailDescription = () => {
    const { currentScpEncounter } = this.props;
    const status = _.get(currentScpEncounter, ['order', 'status'], null);
    switch (status) {
      case orderStatuses.awaitingApproval:
      case orderStatuses.approved:
      case orderStatuses.sentToLab:
      case orderStatuses.resultsReady:
        return (
          <ul style={{ marginBottom: '40px' }}>
            <li>
              To confirm that this test is medically appropriate for you, a Genome Medical provider
              is reviewing the information you have shared with us through this module.
            </li>
            <li>
              If there are no questions or concerns, the doctor will approve your requested test and
              Genome Medical will place the test order. If there are any questions upon review, you
              may hear from one of our coordinators to help us finalize the review and approval
              process.
            </li>
            <li>
              Once we place the test order, you can expect to receive your sample collection kit,
              with instructions, in the mail, directly from the laboratory.
            </li>
          </ul>
        );
      case orderStatuses.cancelled:
        return (
          <ul style={{ marginBottom: '40px' }}>
            <li>
              You should have already been notified by Genome Medical regarding the reason your test
              was canceled.
            </li>
            <li>
              If you would like to find out more regarding the reason your test order was canceled,
              please call
              <a href="tel:+18776880992">(877) 688-0992</a>.
            </li>
          </ul>
        );
      default:
        null;
    }
  };

  get dateAuthorized() {
    const { currentScpEncounter } = this.props;

    return moment(_.get(currentScpEncounter, ['order', 'dateTestAuthorized'])).isValid()
      ? moment(_.get(currentScpEncounter, ['order', 'dateTestAuthorized'])).format('MMM DD, YYYY')
      : '--';
  }

  get dateOrdered() {
    const { currentScpEncounter } = this.props;

    return moment(_.get(currentScpEncounter, ['order', 'dateTestOrdered'])).isValid()
      ? moment(_.get(currentScpEncounter, ['order', 'dateTestOrdered'])).format('MMM DD, YYYY')
      : '--';
  }

  render() {
    const { shippingAddress, personalInfo, currentScpEncounter } = this.props;
    const status = _.get(currentScpEncounter, ['order', 'status'], null);

    return (
      <div className="scp-test-order-detail-container">
        <h4 className="order-detail-title">What does this mean to you?</h4>
        {this.renderOrderDetailDescription()}
        <div style={{ textAlign: 'center' }}>
          {
            <ButtonV2 path="/patient/home" className="go-to-home-button">
              Go To Home Page
            </ButtonV2>
          }
        </div>
        <div className="scp-test-detail-wrapper" data-hj-suppress>
          {status != orderStatuses.awaitingApproval && (
            <Fragment>
              <div className="test-order-wrapper">
                <div className="test-info-wrapper">
                  <div className="title-label">
                    <span>Approving Physician</span>
                  </div>
                  <div className="title-value">
                    <span>
                      {_.isNil(_.get(currentScpEncounter, ['order', 'orderingPhysician'], '--'))
                        ? '--'
                        : _.get(currentScpEncounter, ['order', 'orderingPhysician'], '--')}
                    </span>
                  </div>
                </div>

                <div className="test-info-wrapper">
                  <div className="title-label">
                    <span>NPI</span>
                  </div>
                  <div className="title-value">
                    <span>
                      {_.isNil(_.get(currentScpEncounter, ['order', 'orderingPhysicianNpi'], '--'))
                        ? '--'
                        : _.get(currentScpEncounter, ['order', 'orderingPhysicianNpi'], '--')}
                    </span>
                  </div>
                </div>

                <div className="test-info-wrapper">
                  <div className="title-label">
                    <span>
                      <img src={SuccessIcon} className="success-icon" alt="Success" />Approved Date
                    </span>
                  </div>
                  <div className="title-value">
                    <span>{status === orderStatuses.cancelled ? '--' : this.dateAuthorized}</span>
                  </div>
                </div>

                <div className="test-info-wrapper">
                  <div className="title-label">
                    <span>Order Date</span>
                  </div>
                  <div className="title-value">
                    <span>{this.dateOrdered}</span>
                  </div>
                </div>
              </div>
              <span className="divider" />
            </Fragment>
          )}
          <div className="lab-detail-wrapper">
            <span className="lab-label">Test Name</span>
            <span className="lab-value">
              {_.isNil(_.get(currentScpEncounter, 'testName', '--'))
                ? '--'
                : _.get(currentScpEncounter, 'testName', '--')}
            </span>
            <span className="test-label">Lab</span>
            <span className="test-value">
              {_.isNil(_.get(currentScpEncounter, 'labName', '--'))
                ? '--'
                : _.get(currentScpEncounter, 'labName', '--')}
            </span>
          </div>
          <div className="test-detail-wrapper">
            <span className="test-label">Description</span>
            <div className="test-description">
              Invitae’s Comprehensive Carrier Screen is an expanded carrier screen that includes
              over 280 conditions including those recommended by professional societies and
              well-defined disorders that are common across many ethnicities.
              <p>
                Questions about your order? Chat or Contact us at{' '}
                <a href="tel:+18776880992">(877) 688-0992</a>
              </p>
            </div>
          </div>

          <span className="divider" />

          <div className="ship-address-detail-wrapper">
            <span className="ship-label">Shipping Address</span>
            <span className="ship-address-val">
              {_.get(shippingAddress, 'addressLine1', '')}{' '}
              {!_.isNil(_.get(shippingAddress, 'addressLine2', '')) && (
                <Fragment>
                  {' '}
                  <br /> {_.get(shippingAddress, 'addressLine2', '')}
                </Fragment>
              )}
              <br />
              {_.get(shippingAddress, 'addressCity', '')},{' '}
              {_.get(shippingAddress, 'addressState', '')}{' '}
              {_.get(shippingAddress, 'addressZipCode', '')}
            </span>
          </div>
          <div className="personal-info-wrapper">
            <span className="persnl-info-label">Personal Information</span>
            <span className="persnl-info-val" data-hj-suppress>
              {_.get(personalInfo, 'fullName', '')} <br /> {_.get(personalInfo, 'phone', '')}
            </span>
          </div>
        </div>

        <div className="question-title">
          The most important things you should know about this test are...
        </div>

        <div className="question">Why do we recommend this test for you?</div>
        <div className="answer">
          This expanded carrier screen is recommended for individuals interested in screening for
          the most comprehensive list of conditions, allowing you to take actions to avoid or
          prevent that outcome.
        </div>

        <div className="question">What can I learn from this test?</div>
        <div className="answer">
          Carrier screening can tell you if you have a change in your DNA that may increase your
          chance of having a child with a specific genetic condition.
        </div>

        <div className="question">What type of results are possible for this test?</div>
        <div className="answer">
          Positive carrier screening means you are a carrier of a genetic condition; additional
          recommendations are most often suggested. Negative screening means your chance of being a
          carrier is reduced, but not completely eliminated.
        </div>

        <div className="question">
          Are there specific reasons why I should consider a similar test from a different lab?
        </div>
        <div className="answer">
          This is the screening panel offered by your doctor’s office. If you are interested in a
          similar test from a different lab, we recommend speaking with one of our Certified Genetic
          Counselors.
        </div>

        <div className="question">How much does this testing cost?</div>
        <div className="answer">
          Your expected out-of-pocket cost for testing may vary based on your individual insurance
          plan coverage. Payment options may be available through certain labs. Whether you plan to
          self-pay or bill through your medical insurance, if you’d like more information about this
          lab’s billing plan or your potential out of pocket costs, you can contact the lab
          directly, contact your insurance, or chat with one of our care coordinators.
        </div>

        {/*<div className="more-question-container">
          <div className="more-question-wrapper">
            <span className="title-more-question">More questions? No problem!</span>
            <span className="description-more-question">
              Do you want more clarification before committing to a test? Click on the link below
              and we will connect you with one of our coordinators to answer any questions you may
              have
            </span>
            <ButtonV2>Chat With a Coordinator</ButtonV2>
          </div>
          <div className="illustration-wrapper">
            <span className="placeholder-illustration">Illustration</span>
          </div>
        </div>*/}
      </div>
    );
  }
}

export default TestOrderRequestConfirmDetail;
