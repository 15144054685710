import _, { isEmpty } from 'lodash';
import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import SelectField from 'components/forms/controls/V2/SelectField';
import InputField from 'components/forms/controls/V2/InputField';
import PhoneNumberField from 'components/forms/controls/V2/PhoneNumberField';
import DateField from 'components/forms/controls/V2/DateField';
import RadioButtonGroup from 'components/forms/controls/V2/RadioButtonGroup';

import {
  required,
  phoneOrEmpty,
  dateOfBirthStrictOrEmpty,
  validInsuranceZipCode,
} from 'services/validate';
import schedulingService from 'services/api/schedulingService';
import { HOME, formatPrice, getCountryStates, isInsuranceDeductiblePeriod } from 'services/utils';
import './InsuranceInformationFormV2.scss';
import { GetStates } from 'services/geo';
import gaTrack, { GA_TR_SCHEDULING_INSURANCE_PAGE } from '../../../../../services/gaTrack';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import documentsService from 'services/api/documentsService';
import { documentTypesNames, INS_FRONT_CARD, INS_BACK_CARD } from 'constants/DocumentsConstants';
import StatusMessages from 'constants/StatusMessages';
import moment from 'moment';
import { Loading, Tooltip } from 'gm-element-react';
import InfoModal from 'components/modals/SCP/InfoModal/InfoModal';
import maliciousIcon from '../../../../../containers/pages/patient/SCP/MyHealthPage/malicious.png';
import CheckBoxField from '../../../../forms/controls/V2/CheckBoxField';
import InsuranceDescription from './InsuranceDescription';
import { hydrateHomeAddressFields } from '../../../../../services/api/transformers/addressTransformers';
const states = GetStates();
export const SCP_INSURANCE_FORM_NAME = 'scp-insurance-information-form';
export const DEPENDENT_VALUES = {
  spouse: 'spouse',
  child: 'child',
  parent: 'parent',
  other: 'other',
};
const biologicalSexVariants = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];
const BYTES_IN_MB = 1024 * 1024;
const validCompanyName = (allowSelfPay) => (value) =>
  !allowSelfPay && selfPayEntered(value) ? 'Invalid company name' : undefined;
const selfPayEntered = (text) => {
  return text && text.replace(/[^a-zA-Z]/g, '').toLowerCase() == 'selfpay';
};

const isInsDeducatablePeriod = isInsuranceDeductiblePeriod();

const validateCompanyRequired = required('Company Name');
const validateInsuranceIDRequired = required('Insurnace ID');
const validatePolicyOrDependentRequired = required('Policyholder or dependent');
const validateSexRequired = required('Biological sex');
const validateReleationRequired = required('Relationship to policyholder');
const validateFirstNameRequired = required('First name');
const validateLastNameRequired = required('Last name');
const validateGroup = required('Group #');
const validateInsuranceAddress = required('Insurance Address');
const validateInsCity = required('Insurance City');
const validateInsState = required('Insurance State');
const validateZipCodeRequired = required('Zip Code');
const validateZipCode = validInsuranceZipCode('Zip Code');
const validateInsPhoneNumber = required('Insurance Phone Number');
const validateDOB = dateOfBirthStrictOrEmpty("The date doesn't look right", 'YYYY-MM-DD');

const validateAddress = required('Address Line 1');
const validateCity = required('City');
const validateState = required('State');

const validate = (values) => {
  const errors = {};
  if (values.payWithInsurance && isEmpty(values.insuranceCompanyId)) {
    errors.insuranceCompanyId = 'Insurance is required';
  }
};

const insurancecard = {
  accept: 'image/jpeg, image/png,image/gif',
  maxSize: 50 * BYTES_IN_MB,
  autoupload: true,
};
const RelationVariants = [
  { label: 'Self', value: true },
  { label: 'Dependent', value: false },
];
const InsuranceConfirmationVariants = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const DependantVariants = [
  { label: 'Spouse or Partner', value: DEPENDENT_VALUES.spouse },
  { label: 'Child', value: DEPENDENT_VALUES.child },
  { label: 'Parent', value: DEPENDENT_VALUES.parent },
  { label: 'Other', value: DEPENDENT_VALUES.other },
];

const InsuranceInformationForm = ({
  is_payment_mode_insurance_only,
  initialValues,
  change,
  loadDocuments,
  loadDocumentTypes,
  resetnsuranceCardFront,
  resetInsuranceCardBack,
  companies,
  loaded,
  allAddress,
  me,
  onSubmit,
  preventInsuranceCompanies,
  patients_biological_sex,
  initialize,
  handleSubmit,
  planTypesEnums,
  scheduling,
  documents,
  handlePayWithInsurance,
  submitting,
  toPreviousStep,
  isInsuranceDisable,
  frontInsuranceCardStatus,
  backInsuranceCardStatus,
  valid,
  isMobile,
  CountriesStateEnums: { states },
}) => {
  const { selectedProduct } = scheduling;

  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [showInsuranceFields, setShowInsuranceFields] = useState(false);
  const [showMainFields, setShowMainFields] = useState(false);
  const [showMainFieldsSecondary, setShowMainFieldsSecondary] = useState(false);
  const [showInsuranceName, setShowInsuranceName] = useState(false);
  const [showDependentCombo, setShowDependentCombo] = useState(false);
  const [insuranceVariants, setInsuranceVariants] = useState([]);
  const [initialStateValues, setInitialStateValues] = useState({});
  const [isShakeInsuranceDescription, setIsShakeInsuranceDescription] = useState(false);
  const [frontRejectFile, setFrontRejectFile] = useState(false);
  const [frontAcceptFiles, setFrontAcceptFiles] = useState([]);
  const [backRejectFile, setBackRejectFile] = useState(false);
  const [backAcceptFiles, setBackAcceptFiles] = useState([]);
  const [isInsuranceFieldsDisable, setIsInsuranceFieldsDisable] = useState(false);
  const [selfPayModal, setSelfPayModal] = useState(false);
  const [insuranceDeductibleModal, setInsuranceDeductibleModal] = useState(false);
  const [insuranceFrontImage, setInsuranceFrontImage] = useState(null);
  const [insuranceBackImage, setInsuranceBackImage] = useState(null);
  const [showDependentComboSecondary, setShowDependentComboSecondary] = useState(false);
  const [showInsuranceNameSecondary, setShowInsuranceNameSecondary] = useState(false);
  const [showSecondarySwitch, setShowSecondarySwitch] = useState(true);

  useEffect(() => {
    resetnsuranceCardFront();
    resetInsuranceCardBack();
    gaTrack(GA_TR_SCHEDULING_INSURANCE_PAGE);
    window.scrollTo(0, 0);
    setInitialState();
    if (is_payment_mode_insurance_only) {
      handleShowInsuranceFields(undefined, true);
      if (isInsDeducatablePeriod) handleDeductibleModal(true);
    }
    if (_.get(initialValues, 'payWithInsurance')) {
      handleShowInsuranceFields(undefined, true);
      if (!_.get(initialValues, 'primaryInsurance.insuranceCompanyId')) {
        setDisableSubmitButton(true);
      }
      if (isInsDeducatablePeriod) handleDeductibleModal(true);
    }
    if (_.get(initialValues, 'payWithInsurance', null) === false) {
      handleShowInsuranceFields(undefined, false);
      change('primaryInsurance', {});
      change('secondaryInsurance', {});
    }

    loadDocuments();
    loadDocumentTypes();
  }, []);

  useEffect(() => {
    if (loaded) {
      if (InsuranceCardFront) {
        documentsService.getDocumentLink(InsuranceCardFront.id).then((res) => {
          setInsuranceFrontImage(res);
        });
      }
      if (InsuranceCardBack) {
        documentsService.getDocumentLink(InsuranceCardBack.id).then((res) => {
          setInsuranceBackImage(res);
        });
      }

      if (isDocumentUnderScanning()) {
        setTimeout(() => {
          loadDocuments();
        }, 500);
      }
    }
  }, [loaded]);

  useEffect(() => {
    if (!_.isEmpty(companies) && _.isEmpty(insuranceVariants)) {
      setInsuranceVariants(
        addOtherinLastPosition(companies).map((c) => ({
          label: c.name,
          value: c.id,
        }))
      );
    }
  }, [companies, insuranceVariants]);
  const isDocumentUnderScanning = () => {
    if (InsuranceCardFront && _.isNull(InsuranceCardFront.isMalicious)) return true;
    if (InsuranceCardBack && _.isNull(InsuranceCardBack.isMalicious)) return true;

    return false;
  };

  const addOtherinLastPosition = (_companies) => {
    let filtredCompany = [..._companies];
    const Othercompany = filtredCompany.find((a) => a.name == 'Other');
    if (Othercompany) {
      filtredCompany = _.filter(companies, function (company) {
        return company && company.name !== 'Other';
      });
      filtredCompany.push(Othercompany);
    }
    return filtredCompany;
  };

  const setInitialState = () => {
    let isPH = initialValues?.primaryInsurance?.isPolicyholder;
    if (initialValues?.primaryInsurance?.isPolicyholder === undefined) {
      isPH = true;
    }

    let isSPH = initialValues?.secondaryInsurance?.isPolicyholder;
    if (
      initialValues.secondaryInsurance?.isPolicyholder === undefined ||
      initialValues.secondaryInsurance?.isPolicyholder === null
    ) {
      isSPH = true;
    }
    const companyData = getCompanyData(initialValues?.primaryInsurance?.insuranceCompanyId);
    const companyDataSecondary = getCompanyData(
      initialValues?.secondaryInsurance?.insuranceCompanyId
    );
    setShowMainFields(!_.isEmpty(companyData));
    setShowMainFieldsSecondary(!_.isEmpty(companyDataSecondary));
    setShowInsuranceName(companyData && companyData.nameOverrideRequired);
    setShowInsuranceNameSecondary(
      companyDataSecondary && companyDataSecondary.nameOverrideRequired
    );
    setShowDependentCombo(!isPH);
    setInitialStateValues(initialValues);
    setShowDependentComboSecondary(!isPH);
    setShowSecondarySwitch(initialValues.isSecondaryInsuranceAvailable);
  };

  const isInsuranceEmpty = (InsuranceDetails) => {
    return _.every(InsuranceDetails, (value, key) => {
      let result = false;
      if (key == 'isPolicyholder' && value == true) {
        result = true;
      } else if (key == 'insurance_type') {
        result = true;
      } else if (typeof value == 'string') {
        result = _.isEmpty(value);
      } else {
        result = _.isNil(value);
      }
      return result;
    });
  };

  const onSubmitForm = async (values) => {
    const isOther = showInsuranceName;
    const isOtherSecondary = showInsuranceNameSecondary;
    if (
      ((isOther && selfPayEntered(values?.primaryInsurance?.companyName)) ||
        (isOtherSecondary && selfPayEntered(values?.secondaryInsurance?.companyName))) &&
      allowSelfPay() &&
      values.payWithInsurance
    ) {
      handleSelfPayModal(true);
    } else {
      const company = getCompanyData(values?.primaryInsurance?.insuranceCompanyId);
      const companyDataSecondary = getCompanyData(values?.secondaryInsurance?.insuranceCompanyId);
      schedulingService.setUseInsurance(showInsuranceFields);
      let insuranceData = _.cloneDeep({
        ...values,
      });
      if (values.payWithInsurance) {
        insuranceData.primaryInsurance.companyName =
          values?.primaryInsurance?.companyName || (company && company.name);
        if (!isInsuranceEmpty(insuranceData.secondaryInsurance)) {
          insuranceData.secondaryInsurance.companyName =
            values?.secondaryInsurance?.companyName ||
            (companyDataSecondary && companyDataSecondary.name);
        }
      } else {
        insuranceData.payWithInsurance = false;
      }
      let newAddress = [...allAddress];
      let homeAddress = hydrateHomeAddressFields({
        ...values,
        fullName: _.get(me, 'fullName', ''),
      });

      const oldHomeAddressIndex = newAddress.findIndex(
        (el) => el.addressType && el.addressType.includes(HOME)
      );
      if (oldHomeAddressIndex > -1) {
        const oldAddressObj = newAddress[oldHomeAddressIndex];
        let oldType = [..._.get(oldAddressObj, 'addressType', [])];
        if (oldType.length > 1) {
          let homeIndex = oldType.indexOf(HOME);
          if (homeIndex > -1) {
            oldType.splice(homeIndex, 1);
            newAddress[oldHomeAddressIndex] = { ...oldAddressObj, addressType: oldType };
            newAddress.push({ ...homeAddress, addressType: [HOME], addressId: undefined });
          }
        } else {
          newAddress[oldHomeAddressIndex] = {
            ...homeAddress,
            addressType: [HOME],
          };
        }
      } else {
        newAddress.push({ ...homeAddress, addressType: [HOME], addressId: undefined });
      }
      await onSubmit(insuranceData, frontAcceptFiles, backAcceptFiles, newAddress);
    }
  };

  const handleShowInsuranceFields = (e, value) => {
    if (showInsuranceFields != value) {
      setDisableSubmitButton(false);
      setShowInsuranceFields(!!value);
    }
  };

  const handleShowMainFields = (e, value) => {
    const companyData = getCompanyData(value);
    setDisableSubmitButton(false);
    setShowMainFields(!_.isEmpty(companyData));
    setShowInsuranceName(companyData && companyData.nameOverrideRequired);
    setIsShakeInsuranceDescription(false);
    setIsInsuranceFieldsDisable(false);

    setTimeout(() => {
      const isSelectedPreventCompany = _.findIndex(
        preventInsuranceCompanies,
        (o) => o.id === value
      );
      if (isSelectedPreventCompany !== -1) {
        setIsShakeInsuranceDescription(true);
        setDisableSubmitButton(true);
        setIsInsuranceFieldsDisable(true);
      }
    }, 250);
  };

  const handleShowMainFieldsSecondary = (e, value) => {
    const companyData = getCompanyData(value);
    setShowMainFieldsSecondary(!_.isEmpty(companyData));
    setShowInsuranceNameSecondary(companyData && companyData.nameOverrideRequired);
  };

  const handleShowDependentCombo = (e, value) => {
    setShowDependentCombo(!value);
    if (biologicalSexVariants.find((a) => a && a.value == patients_biological_sex)) {
      let refinitialValues = initialStateValues;
      refinitialValues.primaryInsurance.policyholderBiolocialSex =
        value && patients_biological_sex
          ? patients_biological_sex
          : initialValues?.primaryInsurance?.policyholderBiolocialSex;
      initialize({
        ...refinitialValues,
      });
    }
  };

  const handleShowDependentComboSecondary = (e, value) => {
    setShowDependentComboSecondary(!value);
    if (biologicalSexVariants.find((a) => a && a.value == patients_biological_sex)) {
      let refinitialValues = initialStateValues;
      refinitialValues.secondaryInsurance.policyholderBiolocialSex =
        value && patients_biological_sex
          ? patients_biological_sex
          : initialValues?.secondaryInsurance?.policyholderBiolocialSex;
      initialize({
        ...refinitialValues,
      });
    }
  };

  const navigateToSelfPay = () => {
    change('payWithInsurance', false);
    setShowMainFields(false);
    setSelfPayModal(false);
    handleSubmit(onSubmitForm({ payWithInsurance: false }));
  };

  const allowSelfPay = () => {
    return !is_payment_mode_insurance_only;
  };

  const renderSelfPayModal = () => {
    let company_name = '';
    if (selfPayEntered(_.get(initialStateValues, 'primaryInsurance.companyName', ''))) {
      company_name = _.get(initialStateValues, 'primaryInsurance.companyName', '');
    } else if (selfPayEntered(_.get(initialStateValues, 'secondaryInsurance.companyName', ''))) {
      company_name = _.get(initialStateValues, 'secondaryInsurance.companyName', '');
    }
    return (
      selfPayModal &&
      allowSelfPay() && (
        <InfoModal
          className="info-modal self-pay-modal no-close-modal"
          title={<span className="info-title">Self Pay</span>}
          description={
            <div className="description">
              You have filled in, <span className="bold pre">{company_name}</span> for{' '}
              <span className="bold">Health Insurance Company Name</span> field. We will direct you
              to the self payment page to input your payment information.
            </div>
          }
          submitText="Continue"
          submitCallback={() => navigateToSelfPay()}
          cancelCallback={() => handleSelfPayModal(false)}
          footer={false}
        />
      )
    );
  };

  const renderDeductibleModal = () => {
    return (
      insuranceDeductibleModal && (
        <InfoModal
          className="info-modal deductable-modal no-close-modal"
          title={
            <span className="info-title">Reminder: Insurance deductibles reset on January 1st</span>
          }
          description={
            <div className="description">
              You may pay higher than average out-of-pocket costs for your regular healthcare
              expenses until you meet your deductible. For most people, this impacts the first few
              months of the year. For information on your specific benefits, please contact your
              insurance company.
            </div>
          }
          submitText="Continue"
          submitCallback={() => handleDeductibleModal(false)}
          cancelCallback={() => handleDeductibleModal(false)}
          footer={false}
        />
      )
    );
  };

  const handleSelfPayModal = (value) => {
    setSelfPayModal(value);
  };

  const handleDeductibleModal = (value) => {
    setInsuranceDeductibleModal(value);
  };

  const handleChange = (value, key) => {
    const isOther = showInsuranceName;
    const isOtherSecondary = showInsuranceNameSecondary;
    let refinitialValues = _.cloneDeep(initialStateValues);
    if (key.split('.')[0] === 'primaryInsurance') {
      refinitialValues.primaryInsurance[key.split('.')[1]] = value;
    } else if (key.split('.')[0] === 'secondaryInsurance') {
      refinitialValues.secondaryInsurance[key.split('.')[1]] = value;
    } else {
      refinitialValues[key] = value;
    }
    setInitialStateValues(refinitialValues);
    if (key == 'primaryInsurance.companyName' && isOther && selfPayEntered(value)) {
      handleSelfPayModal(true);
    } else if (
      key == 'secondaryInsurance.companyName' &&
      isOtherSecondary &&
      selfPayEntered(value)
    ) {
      handleSelfPayModal(true);
    } else if (key == 'primaryInsurance.isPolicyholder') {
      handleShowDependentCombo(undefined, value);
    } else if (key == 'payWithInsurance') {
      if (value == false) {
        resetnsuranceCardFront();
        resetInsuranceCardBack();
        change('primaryInsurance', {});
        change('secondaryInsurance', {});
        setFrontAcceptFiles([]);
        setBackAcceptFiles([]);
      }
      if (value && isInsDeducatablePeriod) {
        handleDeductibleModal(true);
      }
      handleShowInsuranceFields(undefined, value);
    } else if (key == 'primaryInsurance.insuranceCompanyId') {
      handleShowInsuranceFields(undefined, value);
      handleShowMainFields(undefined, value);
    } else if (key == 'secondaryInsurance.insuranceCompanyId') {
      handleShowMainFieldsSecondary(undefined, value);
    } else if (key == 'secondaryInsurance.isPolicyholder') {
      handleShowDependentComboSecondary(undefined, value);
    } else if (key == 'isSecondaryInsuranceAvailable') {
      let cloneinitialValues = initialStateValues;
      cloneinitialValues.secondaryInsurance = {
        is_policyholder: '',
        policyholder_relationship: '',
        policyholder_dob: null,
        policyholder_name: null,
        policyholder_first_name: '',
        policyholder_last_name: '',
        policyholder_biological_sex: '',
        insurance_type: 'secondary',
        insurance_no: '',
        company_name: '',
        phone: '',
        group_no: null,
        insurance_company_id: null,
        insurance_address: null,
        insurance_city: null,
        insurance_state: null,
        insurance_zipcode: null,
        athena_insurance_package_name: null,
        athena_insurance_package_id: null,
        employer: null,
        athena_insurance_id: null,
        insurance_plan_type_id: null,
        plan_name: null,
      };
      initialize({
        ...cloneinitialValues,
      });
    }
  };

  const getCompanyData = (id) => {
    return companies && companies.find((c) => c.id === id);
  };

  const GetPlanTypes = () => {
    const planName = _.get(initialValues, 'primaryInsurance.planName');
    let customPlanTypeEnums = planTypesEnums;
    if (!_.isEmpty(planTypesEnums)) {
      customPlanTypeEnums = [{ name: '', display_name: '' }, ...planTypesEnums];
      if (!_.isNil(planName)) {
        const isExistngPlanName = _.find(customPlanTypeEnums, (type) => {
          return type.name == planName;
        });
        if (!isExistngPlanName) {
          customPlanTypeEnums = [
            ...customPlanTypeEnums,
            {
              name: planName,
              display_name: planName,
            },
          ];
        }
      }
      return [...customPlanTypeEnums];
    }
    return [];
  };

  const GetPlanTypesSecondary = () => {
    const planName = _.get(initialValues, 'secondaryInsurance.planName');
    let customPlanTypeEnums = planTypesEnums;
    if (!_.isEmpty(planTypesEnums)) {
      customPlanTypeEnums = [{ name: '', display_name: '' }, ...planTypesEnums];
      if (!_.isNil(planName)) {
        const isExistngPlanName = _.find(customPlanTypeEnums, (type) => {
          return type.name == planName;
        });
        if (!isExistngPlanName) {
          customPlanTypeEnums = [
            ...customPlanTypeEnums,
            {
              name: planName,
              display_name: planName,
            },
          ];
        }
      }
      return [...customPlanTypeEnums];
    }
    return [];
  };

  const renderSecondaryInsuranceFields = () => {
    const isOther = showInsuranceNameSecondary;
    const _allowSelfPay = allowSelfPay();
    return (
      <div
        className={classnames(
          'main-fields-container',
          isInsuranceFieldsDisable ? 'cursor-not-allowed' : ''
        )}>
        <Fragment>
          {isOther && (
            <Fragment>
              <div className="form-row">
                <div className="form-col">
                  <div className="label-section required">Health Insurance Company Name</div>
                  <Field
                    name="secondaryInsurance.companyName"
                    type="text"
                    placeholder="Health Company ABC"
                    component={InputField}
                    validate={[validateCompanyRequired, validCompanyName(_allowSelfPay)]}
                    onChange={(e, value) => handleChange(value, 'secondaryInsurance.companyName')}
                  />
                </div>
                <div className="form-col">
                  <div className="label-section">Plan Type (optional)</div>
                  <Field
                    name="secondaryInsurance.planName"
                    component={SelectField}
                    placeholder="Select"
                    options={(GetPlanTypesSecondary() || []).map((obj) => {
                      return {
                        label: obj.display_name,
                        value: obj.name,
                        key: obj.name,
                      };
                    })}
                    onChange={(e, value) => handleChange(value, 'secondaryInsurance.planName')}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-col">
                  <div className="label-section">Group #</div>
                  <Field
                    name="secondaryInsurance.groupNo"
                    type="text"
                    placeholder="1234-567890"
                    component={InputField}
                    onChange={(e, value) => handleChange(value, 'secondaryInsurance.groupNo')}
                  />
                </div>
                <div className="form-col">
                  <div className="label-section">Employer</div>
                  <Field
                    name="secondaryInsurance.employer"
                    type="text"
                    placeholder="Company ABC"
                    component={InputField}
                    label="Employer"
                    onChange={(e, value) => handleChange(value, 'secondaryInsurance.employer')}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-col address-col">
                  <div className="label-section">Insurance Address</div>
                  <Field
                    name="secondaryInsurance.insuranceAddress"
                    type="text"
                    placeholder="123 Main Street"
                    component={InputField}
                    onChange={(e, value) =>
                      handleChange(value, 'secondaryInsurance.insuranceAddress')
                    }
                  />
                </div>
              </div>
              <div className="form-row addres-row">
                <div className="form-col city-col">
                  <div className="label-section">Insurance City</div>
                  <Field
                    name="secondaryInsurance.insuranceCity"
                    type="text"
                    placeholder="Enter"
                    component={InputField}
                    onChange={(e, value) => handleChange(value, 'secondaryInsurance.insuranceCity')}
                  />
                </div>
                <div className="form-col state-col">
                  <div className="label-section">Insurance State</div>
                  <Field
                    name="secondaryInsurance.insuranceState"
                    component={SelectField}
                    label="Insurance State"
                    placeholder="Select"
                    options={(states || []).map((obj) => {
                      return {
                        label: obj.value,
                        value: obj.value,
                        key: obj.value,
                      };
                    })}
                    onChange={(e, value) =>
                      handleChange(value, 'secondaryInsurance.insuranceState')
                    }
                  />
                </div>
                <div className="form-col zip-col">
                  <div className="label-section">Insurance Zip Code</div>
                  <Field
                    name="secondaryInsurance.insuranceZipCode"
                    type="text"
                    component={InputField}
                    placeholder="Enter"
                    label="Insurance zipcode"
                    onChange={(e, value) =>
                      handleChange(value, 'secondaryInsurance.insuranceZipCode')
                    }
                  />
                </div>
              </div>
            </Fragment>
          )}
          <div className="form-row">
            <div className="form-col">
              <div className="label-section required">Insurance ID #</div>
              <Field
                name="secondaryInsurance.insuranceNo"
                type="text"
                placeholder="1234-567890"
                component={InputField}
                validate={[validateInsuranceIDRequired]}
                onChange={(e, value) => handleChange(value, 'secondaryInsurance.insuranceNo')}
                disabled={isInsuranceFieldsDisable}
              />
            </div>
            <div className="form-col">
              <div className="label-section">Insurance Phone Number</div>
              <Field
                name="secondaryInsurance.phone"
                type="phone"
                component={PhoneNumberField}
                trim={true}
                placeholder="+1 (123) 456 - 7890"
                label="Insurance phone number"
                mask="+1 (999) 999 - 9999"
                maskChar={null}
                onChange={(e, value) => handleChange(value, 'secondaryInsurance.phone')}
                disabled={isInsuranceFieldsDisable}
              />
            </div>
          </div>
        </Fragment>
        <div className="form-row">
          <div className="form-col">
            <div className="label-section required">
              Are you the primary policyholder or a dependent?
            </div>
            <Field
              name="secondaryInsurance.isPolicyholder"
              className={classnames(
                'insurance-form-radiogroup fz15px',
                isInsuranceFieldsDisable ? 'cursor-not-allowed' : ''
              )}
              component={RadioButtonGroup}
              validate={[validatePolicyOrDependentRequired]}
              children={RelationVariants}
              onChange={(e, value) => handleChange(value, 'secondaryInsurance.isPolicyholder')}
              disabled={isInsuranceFieldsDisable}
            />
          </div>
          <div className="form-col">
            <div className="label-section required">Policyholder’s Biological Sex</div>
            <Field
              name="secondaryInsurance.policyholderBiolocialSex"
              component={SelectField}
              className={classnames('narrow-field')}
              placeholder="Select"
              options={(biologicalSexVariants || []).map((obj) => {
                return {
                  label: obj.label,
                  value: obj.value,
                  key: obj.value,
                };
              })}
              validate={[validateSexRequired]}
              onChange={(e, value) =>
                handleChange(value, 'secondaryInsurance.policyholderBiolocialSex')
              }
              disabled={isInsuranceFieldsDisable}
            />
          </div>
        </div>
        {showDependentComboSecondary && (
          <Fragment>
            <div className="form-row">
              <div className="form-col">
                <div className="label-section required">Your Relationship To Policyholder</div>
                <Field
                  name="secondaryInsurance.policyholderRelationship"
                  component={SelectField}
                  className="narrow-field"
                  placeholder="Select"
                  options={(DependantVariants || []).map((obj) => {
                    return {
                      label: obj.label,
                      value: obj.value,
                      key: obj.value,
                    };
                  })}
                  validate={[validateReleationRequired]}
                  onChange={(e, value) =>
                    handleChange(value, 'secondaryInsurance.policyholderRelationship')
                  }
                  disabled={isInsuranceFieldsDisable}
                />
              </div>
              <div className="form-col">
                <div className="label-section">Policyholder’s Date of Birth</div>
                <Field
                  name="secondaryInsurance.policyholderDob"
                  type="text"
                  placeholder="MM/DD/YYYY"
                  component={DateField}
                  onChange={(e, value) => handleChange(value, 'secondaryInsurance.policyholderDob')}
                  onBlur={(e, value) => handleChange(value, 'secondaryInsurance.policyholderDob')}
                  disabled={isInsuranceFieldsDisable}
                  disabledDate={(current) => {
                    const momentcurrent = moment(current);
                    return momentcurrent.isBefore(moment());
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <div className="label-section required">Policyholder’s First Name</div>
                <Field
                  name="secondaryInsurance.policyholderFirstName"
                  type="text"
                  placeholder="John"
                  component={InputField}
                  validate={[validateFirstNameRequired]}
                  onChange={(e, value) =>
                    handleChange(value, 'secondaryInsurance.policyholderFirstName')
                  }
                  disabled={isInsuranceFieldsDisable}
                />
              </div>
              <div className="form-col">
                <div className="label-section required">Policyholder’s Last Name</div>
                <Field
                  name="secondaryInsurance.policyholderLastName"
                  type="text"
                  placeholder="Doe"
                  component={InputField}
                  validate={[validateLastNameRequired]}
                  onChange={(e, value) =>
                    handleChange(value, 'secondaryInsurance.policyholderLastName')
                  }
                  disabled={isInsuranceFieldsDisable}
                />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  };

  const renderMainInsuranceFields = () => {
    const isOther = showInsuranceName;
    const _allowSelfPay = allowSelfPay();
    return (
      <Fragment>
        {isOther && (
          <Fragment>
            <div className="form-row">
              <div className="form-col">
                <div className="label-section required">Health Insurance Company Name</div>
                <Field
                  name="primaryInsurance.companyName"
                  type="text"
                  placeholder="Health Company ABC"
                  component={InputField}
                  validate={[validateCompanyRequired, validCompanyName(_allowSelfPay)]}
                  onChange={(e, value) => handleChange(value, 'primaryInsurance.companyName')}
                />
              </div>
              <div className="form-col">
                <div className="label-section">Plan Type (optional)</div>
                <Field
                  name="primaryInsurance.planName"
                  component={SelectField}
                  placeholder="Select"
                  options={(GetPlanTypes() || []).map((obj) => {
                    return {
                      label: obj.display_name,
                      value: obj.name,
                      key: obj.name,
                    };
                  })}
                  onChange={(e, value) => handleChange(value, 'primaryInsurance.planName')}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <div className="label-section required">Group #</div>
                <Field
                  name="primaryInsurance.groupNo"
                  type="text"
                  placeholder="1234-567890"
                  component={InputField}
                  validate={[validateGroup]}
                  onChange={(e, value) => handleChange(value, 'primaryInsurance.groupNo')}
                />
              </div>
              <div className="form-col">
                <div className="label-section">Employer</div>
                <Field
                  name="primaryInsurance.employer"
                  type="text"
                  placeholder="Company ABC"
                  component={InputField}
                  label="Employer"
                  onChange={(e, value) => handleChange(value, 'primaryInsurance.employer')}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col address-col">
                <div className="label-section required">Insurance Address</div>
                <Field
                  name="primaryInsurance.insuranceAddress"
                  type="text"
                  placeholder="123 Main Street"
                  component={InputField}
                  validate={[validateInsuranceAddress]}
                  onChange={(e, value) => handleChange(value, 'primaryInsurance.insuranceAddress')}
                />
              </div>
            </div>
            <div className="form-row addres-row">
              <div className="form-col city-col">
                <div className="label-section required">Insurance City</div>
                <Field
                  name="primaryInsurance.insuranceCity"
                  type="text"
                  placeholder="Enter"
                  component={InputField}
                  validate={[validateInsCity]}
                  onChange={(e, value) => handleChange(value, 'primaryInsurance.insuranceCity')}
                />
              </div>
              <div className="form-col state-col">
                <div className="label-section required">Insurance State</div>
                <Field
                  name="primaryInsurance.insuranceState"
                  component={SelectField}
                  label="Insurance State"
                  placeholder="Select"
                  options={(states || []).map((obj) => {
                    return {
                      label: obj.value,
                      value: obj.value,
                      key: obj.value,
                    };
                  })}
                  validate={[validateInsState]}
                  onChange={(e, value) => handleChange(value, 'primaryInsurance.insuranceState')}
                />
              </div>
              <div className="form-col zip-col">
                <div className="label-section required">Insurance Zip Code</div>
                <Field
                  name="primaryInsurance.insuranceZipCode"
                  type="text"
                  component={InputField}
                  placeholder="Enter"
                  label="Insurance zipcode"
                  validate={[validateZipCodeRequired, validateZipCode]}
                  onChange={(e, value) => handleChange(value, 'primaryInsurance.insuranceZipCode')}
                />
              </div>
            </div>
          </Fragment>
        )}
        <div className="form-row">
          <div className="form-col">
            <div className="label-section required">Insurance ID #</div>
            <Field
              name="primaryInsurance.insuranceNo"
              type="text"
              placeholder="1234-567890"
              component={InputField}
              validate={[validateInsuranceIDRequired]}
              onChange={(e, value) => handleChange(value, 'primaryInsurance.insuranceNo')}
              disabled={isInsuranceFieldsDisable}
            />
          </div>
          <div className="form-col">
            <div className="label-section required">Insurance Phone Number</div>
            <Field
              name="primaryInsurance.phone"
              type="phone"
              component={PhoneNumberField}
              trim={true}
              placeholder="+1 (123) 456 - 7890"
              label="Insurance phone number"
              validate={[phoneOrEmpty, validateInsPhoneNumber]}
              mask="+1 (999) 999 - 9999"
              maskChar={null}
              onChange={(e, value) => handleChange(value, 'primaryInsurance.phone')}
              disabled={isInsuranceFieldsDisable}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderMainFormFields = () => {
    const hideSecondaryQuestion = true;
    return (
      <div
        className={classnames(
          'main-fields-container',
          isInsuranceFieldsDisable ? 'cursor-not-allowed' : ''
        )}>
        {renderMainInsuranceFields()}
        <div className="form-row">
          <div className="form-col">
            <div className="label-section required">
              Are you the primary policyholder or a dependent?
            </div>
            <Field
              name="primaryInsurance.isPolicyholder"
              className={classnames(
                'insurance-form-radiogroup fz15px',
                isInsuranceFieldsDisable ? 'cursor-not-allowed' : ''
              )}
              component={RadioButtonGroup}
              children={RelationVariants}
              validate={[validatePolicyOrDependentRequired]}
              onChange={(e, value) => handleChange(value, 'primaryInsurance.isPolicyholder')}
              disabled={isInsuranceFieldsDisable}
            />
          </div>
          <div className="form-col">
            <div className="label-section required">Policyholder’s Biological Sex</div>
            <Field
              name="primaryInsurance.policyholderBiolocialSex"
              component={SelectField}
              className={classnames('narrow-field')}
              placeholder="Select"
              options={(biologicalSexVariants || []).map((obj) => {
                return {
                  label: obj.label,
                  value: obj.value,
                  key: obj.value,
                };
              })}
              validate={[validateSexRequired]}
              onChange={(e, value) =>
                handleChange(value, 'primaryInsurance.policyholderBiolocialSex')
              }
              disabled={isInsuranceFieldsDisable}
            />
          </div>
        </div>

        {showDependentCombo && renderDependentFields()}

        {!showSecondarySwitch && !hideSecondaryQuestion && (
          <div className="form-row">
            <div className="form-col">
              <div className="label-section required">Do you have secondary insurance?</div>
              <Field
                name="isSecondaryInsuranceAvailable"
                className={classnames(
                  'insurance-form-radiogroup fz15px',
                  isInsuranceFieldsDisable ? 'cursor-not-allowed' : ''
                )}
                component={RadioButtonGroup}
                children={InsuranceConfirmationVariants}
                onChange={(e, value) => handleChange(value, 'isSecondaryInsuranceAvailable')}
                disabled={isInsuranceFieldsDisable}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const price = (() => {
    const servicePrice = _.get(selectedProduct, 'price', 179);
    return '$' + formatPrice(servicePrice);
  })();

  const renderDependentFields = () => {
    return (
      <Fragment>
        <div className="form-row">
          <div className="form-col">
            <div className="label-section required">Your Relationship To Policyholder</div>
            <Field
              name="primaryInsurance.policyholderRelationship"
              component={SelectField}
              className="narrow-field"
              placeholder="Select"
              options={(DependantVariants || []).map((obj) => {
                return {
                  label: obj.label,
                  value: obj.value,
                  key: obj.value,
                };
              })}
              validate={[validateReleationRequired]}
              onChange={(e, value) =>
                handleChange(value, 'primaryInsurance.policyholderRelationship')
              }
              disabled={isInsuranceFieldsDisable}
            />
          </div>
          <div className="form-col">
            <div className="label-section">Policyholder’s Date of Birth</div>
            <Field
              name="primaryInsurance.policyholderDob"
              type="text"
              placeholder="MM/DD/YYYY"
              component={DateField}
              validate={[validateDOB]}
              onChange={(e, value) => handleChange(value, 'primaryInsurance.policyholderDob')}
              onBlur={(e, value) => handleChange(value, 'primaryInsurance.policyholderDob')}
              disabled={isInsuranceFieldsDisable}
              disabledDate={(current) => {
                const momentcurrent = moment(current);
                return momentcurrent.isBefore(moment());
              }}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-col">
            <div className="label-section required">Policyholder’s First Name</div>
            <Field
              name="primaryInsurance.policyholderFirstName"
              type="text"
              placeholder="John"
              component={InputField}
              validate={[validateFirstNameRequired]}
              onChange={(e, value) => handleChange(value, 'primaryInsurance.policyholderFirstName')}
              disabled={isInsuranceFieldsDisable}
            />
          </div>
          <div className="form-col">
            <div className="label-section required">Policyholder’s Last Name</div>
            <Field
              name="primaryInsurance.policyholderLastName"
              type="text"
              placeholder="Doe"
              component={InputField}
              validate={[validateLastNameRequired]}
              onChange={(e, value) => handleChange(value, 'primaryInsurance.policyholderLastName')}
              disabled={isInsuranceFieldsDisable}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const getPayVariants = () => {
    const PayVariantsObject = {
      'Self-Pay': {
        label: 'Self Pay',
        value: false,
        description: (
          <div>
            <h2 className="description-hint-header">Bill me directly</h2>
            Genome Medical offers a self-pay option for patients.
            <br />
            If you choose self-pay, we will not file a claim with your insurance. The cost is{' '}
            <strong>{price}</strong> per session. To complete the scheduling process, credit card
            information will be collected, but you will not be billed until after your appointment
            is completed.
          </div>
        ),
      },
      Insurance: {
        label: 'Insurance',
        value: true,
        description: (
          <div>
            <h2 className="description-hint-header">Insurance</h2>
            Genome Medical's services may be covered by your insurance. The specific coverage and
            amount you may owe out-of-pocket for these services will vary based on your insurance
            plan. Typical fees range from $100-$200 per visit, but many patients pay less with
            in-network insurance. Please contact your health insurance provider to discuss your
            specific coverage. To check if your insurance is in-network with Genome Medical, click{' '}
            <a target="_blank" href="https://www.genomemedical.com/insurance-coverage/">
              here
            </a>{' '}
            <br />
            <br />
            If you choose to bill insurance for your visit, we will collect your insurance
            information at this time and submit a claim on your behalf. We may also collect a $50
            deposit depending on your insurance plan. If we are unable to collect from your
            insurance, you will be liable for the remaining balance. for more info regarding our
            in-network payors
            <br />
            <br />
            If you have billing questions prior to scheduling, please contact us at{' '}
            <a href="tel:+18776880992">(877) 688-0992</a>
          </div>
        ),
      },
    };

    let PayVariants = [];
    if (!is_payment_mode_insurance_only) {
      PayVariants.push(PayVariantsObject['Self-Pay']);
    }
    PayVariants.push(PayVariantsObject['Insurance']);
    return PayVariants;
  };

  const InsuranceCardFilter = (_documents, type = INS_FRONT_CARD) => {
    const filterstring =
      documentTypesNames.insuranceCard.toLowerCase() + '-' + type.toLowerCase() + '-';
    const filtercards = _documents
      ? _.filter(_documents, function (doc) {
          return (
            doc &&
            doc.type &&
            doc.type.name &&
            doc.type.name.toLowerCase() == documentTypesNames.insuranceCard.toLowerCase() &&
            doc.fileName.toLowerCase().indexOf(filterstring) !== -1
          );
        })
      : [];
    const sorteddocument = filtercards ? _.sortBy(filtercards, (val) => val.createdAt) : [];
    const insuranceCard = _.last(sorteddocument);
    return insuranceCard;
  };

  const InsuranceCardFront = InsuranceCardFilter(documents, INS_FRONT_CARD);
  const InsuranceCardBack = InsuranceCardFilter(documents, INS_BACK_CARD);
  const getDocumentLink = (id) => documentsService.getDocumentLink(id);

  const DisplayFrontInsuranceCard = (insuranceCard) => {
    if (_.get(frontAcceptFiles, ['length'], 0 > 0)) {
      return _.get(frontAcceptFiles, ['0', 'preview']);
    }
    if (insuranceCard && insuranceCard.id) {
      return insuranceFrontImage;
    }
    return null;
  };
  const DisplayBackInsuranceCard = (insuranceCard) => {
    if (_.get(backAcceptFiles, ['length'], 0 > 0)) {
      return _.get(backAcceptFiles, ['0', 'preview']);
    }
    if (insuranceCard && insuranceCard.id) {
      return insuranceBackImage;
    }
    return null;
  };

  const uploadLocalFrontInsuranceCard = (acceptedFiles, rejectedFiles) => {
    resetnsuranceCardFront();
    setFrontAcceptFiles(acceptedFiles);
    setFrontRejectFile(_.get(rejectedFiles, 'length', 0) > 0 ? true : false);
  };

  const uploadLocalBackInsuranceCard = (acceptedFiles, rejectedFiles) => {
    resetInsuranceCardBack();
    setBackAcceptFiles(acceptedFiles);
    setBackRejectFile(_.get(rejectedFiles, 'length', 0) > 0 ? true : false);
  };

  const DeleteLocalFrontInsuranceCard = (e) => {
    e.stopPropagation();
    resetnsuranceCardFront();
    setFrontAcceptFiles([]);
  };
  const DeleteLocalBackInsuranceCard = (e) => {
    e.stopPropagation();
    resetInsuranceCardBack();
    setBackAcceptFiles([]);
  };

  const hasFrontAceeptedFile = () => {
    return frontAcceptFiles.length > 0;
  };
  const hasBackAceeptedFile = () => {
    return backAcceptFiles.length > 0;
  };
  const onBack = () => {
    handlePayWithInsurance(null);
    toPreviousStep();
  };

  const backboxclasses = !InsuranceCardBack
    ? 'uploaddoc border-box back-border-box '
    : 'uploaddoc border-box uplpad-img-radius';
  const frontboxclasses = !InsuranceCardFront
    ? 'uploaddoc border-box back-border-box '
    : 'uploaddoc border-box uplpad-img-radius';
  return (
    <div className="scp-schedule-wizard scp-insurance-stage">
      <h2 className="gm-select-service__header">Enter Insurance Information</h2>
      <form className="insurance-form" onSubmit={handleSubmit(onSubmitForm)}>
        <>
          <div className="scp-insurance-fields">
            <div className="insurance-group">
              <div className="scp-insurance-sections">
                <div className="scp-insurance-section insurance-name-section">
                  <div className="head-label-section-main">Primary Insurance</div>
                  <div
                    className={`label-section ${
                      initialStateValues?.payWithInsurance ? 'required' : ''
                    }`}>
                    <span>Insurance Name</span>
                  </div>
                  <div className="form-row" style={{ marginBottom: '0px' }}>
                    <Field
                      name="primaryInsurance.insuranceCompanyId"
                      component={SelectField}
                      label="What is your health insurance?"
                      placeholder="Select insurance"
                      typeahead={true}
                      clearable={true}
                      options={(insuranceVariants || []).map((obj) => {
                        return {
                          label: obj.label,
                          value: obj.value,
                          key: obj.value,
                        };
                      })}
                      onChange={(e, value) => {
                        change('payWithInsurance', true);
                        change('isSelfPay', false);
                        handleChange(value, 'primaryInsurance.insuranceCompanyId');
                      }}
                      disabled={isInsuranceDisable}
                    />
                  </div>
                </div>

                {is_payment_mode_insurance_only || (
                  <Field
                    name="isSelfPay"
                    type="checkbox"
                    extraclassName="insurance-form-checkbox"
                    label={
                      <Fragment>
                        <span className="checkmark" />
                        <div className="label-section">
                          I don't have insurance / my insurance is not listed
                        </div>
                      </Fragment>
                    }
                    onChange={(_e, value) => {
                      change('payWithInsurance', !value);
                      handleChange(!value, 'payWithInsurance');
                    }}
                    component={CheckBoxField}
                  />
                )}

                {showInsuranceFields && showMainFields && (
                  <>
                    <div className="scp-insurance-section upload-section">
                      <Fragment>
                        <div className="head-label-section">Your Insurance Card</div>
                        <div className="upload-content info-text">
                          Please upload the front and back images of your insurance card here OR
                          email them to{' '}
                          <a href="mailto: clinical@genomemedical.com">
                            {' '}
                            clinical@genomemedical.com
                          </a>
                        </div>
                      </Fragment>

                      {/* start upload section */}
                      <Fragment>
                        <div
                          className={classnames(
                            'insurance-upload-block',
                            isInsuranceFieldsDisable ? 'disable-insurance-upload-block' : ''
                          )}
                          data-hj-suppress>
                          <div className="upload-card front">
                            <span className="uploaddoctitle">Front</span>
                            {_.isNull(_.get(InsuranceCardFront, ['isMalicious'], false)) ? (
                              <Loading
                                text="Scanning for viruses"
                                loading={true}
                                className={classnames(frontboxclasses, 'scanning')}>
                                <div className="card-img">
                                  <img
                                    className="doc-img"
                                    src={DisplayFrontInsuranceCard(InsuranceCardFront)}
                                  />
                                </div>
                              </Loading>
                            ) : (
                              <Dropzone
                                className={classnames(frontboxclasses, {
                                  'is-malicious':
                                    _.get(InsuranceCardFront, ['isMalicious'], false) &&
                                    !_.get(frontAcceptFiles, ['length'], 0 > 0),
                                  'file-selected':
                                    !!InsuranceCardFront || frontAcceptFiles.length > 0,
                                })}
                                autoupload={insurancecard.autoupload}
                                multiple={false}
                                maxSize={insurancecard.maxSize}
                                accept={insurancecard.accept}
                                onDrop={(acceptedFiles, rejectedFiles) => {
                                  uploadLocalFrontInsuranceCard(acceptedFiles, rejectedFiles);
                                }}>
                                {!(
                                  InsuranceCardFront || _.get(frontAcceptFiles, ['length'], 0) > 0
                                ) ? (
                                  <div>
                                    <div className="uploaddoc-dropblock">
                                      <div className="text-center">
                                        {' '}
                                        <img
                                          className="cloud-img"
                                          src={require('images/upload.svg')}
                                        />
                                      </div>
                                      <div>
                                        {' '}
                                        <p className="uploaddoctext">
                                          {!isMobile ? (
                                            <Fragment>
                                              Drop file here, or{' '}
                                              <span className="click-to-upload">
                                                Click to upload
                                              </span>
                                            </Fragment>
                                          ) : (
                                            <span className="click-to-upload">Click to upload</span>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : _.get(InsuranceCardFront, ['isMalicious'], false) &&
                                  !_.get(frontAcceptFiles, ['length'], 0 > 0) ? (
                                  <Tooltip
                                    placement="top"
                                    content="This file contains a virus and is blocked"
                                    effect="dark">
                                    <div className="card-img">
                                      <img className="doc-img malicious-icon" src={maliciousIcon} />
                                      <div className="update-image">
                                        <div className="text-center hoverdoc-block">
                                          {' '}
                                          <img
                                            className="camera-img"
                                            src={require('images/camera.svg')}
                                          />
                                        </div>
                                        <p className="dochover-text">Update</p>
                                      </div>
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <div className="card-img">
                                    <img
                                      className="doc-img"
                                      src={DisplayFrontInsuranceCard(InsuranceCardFront)}
                                    />
                                    {hasFrontAceeptedFile() && (
                                      <div
                                        className="cross-icon"
                                        onClick={(e) => DeleteLocalFrontInsuranceCard(e)}>
                                        <i className="fa fa-times" />
                                      </div>
                                    )}
                                    <div className="update-image">
                                      <div className="text-center hoverdoc-block">
                                        {' '}
                                        <img
                                          className="camera-img"
                                          src={require('images/camera.svg')}
                                        />
                                      </div>
                                      <p className="dochover-text">Update</p>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            )}
                            {((frontInsuranceCardStatus &&
                              frontInsuranceCardStatus.uploadingResult &&
                              frontInsuranceCardStatus.uploadingResult.status === false) ||
                              frontRejectFile == true) && (
                              <div className="error-div">
                                <span className="error">
                                  {(frontInsuranceCardStatus.uploadingResult &&
                                    frontInsuranceCardStatus.uploadingResult.error) ||
                                    (frontRejectFile == true && StatusMessages.rejectFiles)}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="upload-card">
                            <span className="uploaddoctitle">Back</span>
                            {_.isNull(_.get(InsuranceCardBack, ['isMalicious'], false)) ? (
                              <Loading
                                text="Scanning for viruses"
                                loading={true}
                                className={classnames(backboxclasses, 'scanning')}>
                                <div className="card-img">
                                  <img
                                    className="doc-img"
                                    src={DisplayBackInsuranceCard(InsuranceCardBack)}
                                  />
                                </div>
                              </Loading>
                            ) : (
                              <Dropzone
                                className={classnames(backboxclasses, {
                                  'is-malicious':
                                    _.get(InsuranceCardBack, ['isMalicious'], false) &&
                                    !_.get(backAcceptFiles, ['length'], 0 > 0),
                                  'file-selected':
                                    !!InsuranceCardBack || backAcceptFiles.length > 0,
                                })}
                                autoupload={insurancecard.autoupload}
                                multiple={false}
                                maxSize={insurancecard.maxSize}
                                accept={insurancecard.accept}
                                onDrop={(acceptedFiles, rejectedFiles) => {
                                  uploadLocalBackInsuranceCard(acceptedFiles, rejectedFiles);
                                }}>
                                {!(
                                  InsuranceCardBack || _.get(backAcceptFiles, ['length'], 0) > 0
                                ) ? (
                                  <div>
                                    <div className="uploaddoc-dropblock">
                                      <div className="text-center">
                                        {' '}
                                        <img
                                          className="cloud-img"
                                          src={require('images/upload.svg')}
                                        />
                                      </div>
                                      <div>
                                        {' '}
                                        <p className="uploaddoctext">
                                          {!isMobile ? (
                                            <Fragment>
                                              Drop file here, or{' '}
                                              <span className="click-to-upload">
                                                Click to upload
                                              </span>
                                            </Fragment>
                                          ) : (
                                            <span className="click-to-upload">Click to upload</span>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : _.get(InsuranceCardBack, ['isMalicious'], false) &&
                                  !_.get(backAcceptFiles, ['length'], 0 > 0) ? (
                                  <Tooltip
                                    placement="top"
                                    content="This file contains a virus and is blocked"
                                    effect="dark">
                                    <div className="card-img">
                                      <img className="doc-img malicious-icon" src={maliciousIcon} />
                                      <div className="update-image">
                                        <div className="text-center hoverdoc-block">
                                          {' '}
                                          <img
                                            className="camera-img"
                                            src={require('images/camera.svg')}
                                          />
                                        </div>
                                        <p className="dochover-text">Update</p>
                                      </div>
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <div className="card-img">
                                    <img
                                      className="doc-img"
                                      src={DisplayBackInsuranceCard(InsuranceCardBack)}
                                    />
                                    {hasBackAceeptedFile() && (
                                      <div
                                        className="cross-icon"
                                        onClick={(e) => DeleteLocalBackInsuranceCard(e)}>
                                        <i className="fa fa-times" />
                                      </div>
                                    )}
                                    <div className="update-image">
                                      <div className="text-center hoverdoc-block">
                                        {' '}
                                        <img
                                          className="camera-img"
                                          src={require('images/camera.svg')}
                                        />
                                      </div>
                                      <p className="dochover-text">Update</p>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            )}

                            {((backInsuranceCardStatus &&
                              backInsuranceCardStatus.uploadingResult &&
                              backInsuranceCardStatus.uploadingResult.status === false) ||
                              backRejectFile == true) && (
                              <div className="error-div">
                                <span className="error">
                                  {(backInsuranceCardStatus.uploadingResult &&
                                    backInsuranceCardStatus.uploadingResult.error) ||
                                    (backRejectFile == true && StatusMessages.rejectFiles)}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </Fragment>
                      {/* end upload section */}
                    </div>
                    <div className="scp-insurance-section insurance-section">
                      <div className="head-label-section">Your Insurance Info</div>
                      {renderMainFormFields()}
                    </div>
                    <div className="scp-insurance-section address-section">
                      <div className="head-label-section">Your Address</div>
                      <div className="upload-content info-text">
                        We need this information to bill your insurance
                        <div className="main-fields-container">
                          <div className="form-row">
                            <div className="form-col address-line-1">
                              <div className="label-section required">Address Line 1</div>
                              <Field
                                name="addressLine1"
                                placeholder="123 Main Street"
                                type="text"
                                component={InputField}
                                validate={[validateAddress]}
                                onChange={(e, value) => handleChange(value, 'addressLine1')}
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="form-col address-line-2">
                              <div className="label-section">Address Line 2</div>
                              <Field
                                name="addressLine2"
                                placeholder="Apt 123"
                                type="text"
                                component={InputField}
                                onChange={(e, value) => handleChange(value, 'addressLine2')}
                              />
                            </div>
                          </div>
                          <div className="form-row city-state-zip-row">
                            <div className="form-col address-city-col">
                              <div className="label-section required">City</div>
                              <Field
                                name="addressCity"
                                type="text"
                                placeholder="Enter"
                                component={InputField}
                                validate={[validateCity]}
                                onChange={(e, value) => handleChange(value, 'addressCity')}
                              />
                            </div>

                            <div className="form-col address-state-col">
                              <div className="label-section required">State</div>
                              <Field
                                name="addressState"
                                component={SelectField}
                                placeholder="Select"
                                options={getCountryStates(states, 'US', 'code', 'code')}
                                validate={[validateState]}
                                onChange={(e, value) => handleChange(value, 'addressState')}
                                typeahead={true}
                                clearable={true}
                              />
                            </div>
                            <div className="form-col address-zip-col">
                              <div className="label-section required">Zip Code</div>
                              <Field
                                name="addressZipCode"
                                type="text"
                                placeholder="Enter"
                                component={InputField}
                                label="Zipcode"
                                validate={[validateZipCodeRequired, validateZipCode]}
                                onChange={(e, value) => handleChange(value, 'addressZipCode')}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {initialStateValues.isSecondaryInsuranceAvailable && showInsuranceFields && (
                <div className="scp-insurance-sections secondary-section">
                  <div className="scp-insurance-section">
                    <div className="head-label-section-main">Secondary Insurance</div>
                    <div className="label-section">Insurance Name</div>
                    <div className="form-row" style={{ marginBottom: '0px' }}>
                      <Field
                        name="secondaryInsurance.insuranceCompanyId"
                        component={SelectField}
                        label="What is your health insurance?"
                        placeholder="Select"
                        options={(insuranceVariants || []).map((obj) => {
                          return {
                            label: obj.label,
                            value: obj.value,
                            key: obj.value,
                          };
                        })}
                        onChange={(e, value) =>
                          handleChange(value, 'secondaryInsurance.insuranceCompanyId')
                        }
                        disabled={isInsuranceDisable}
                        typeahead={true}
                        clearable={true}
                      />
                    </div>
                  </div>
                  {showInsuranceFields && showMainFieldsSecondary && (
                    <div className="scp-insurance-section insurance-section">
                      <div className="head-label-section">Your Insurance Info</div>
                      {renderSecondaryInsuranceFields()}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="info-text insurance-type-description">
              <InsuranceDescription isMobile={isMobile} selectedProduct={selectedProduct} />
            </div>
          </div>
        </>

        <div className="action-buttons">
          {toPreviousStep && (
            <button onClick={onBack} className="btn-back">
              Back
            </button>
          )}
          <button
            disabled={submitting || disableSubmitButton || !valid}
            type="submit"
            className="btn-next">
            Next
          </button>
        </div>
      </form>
      {renderSelfPayModal()}
      {renderDeductibleModal()}
    </div>
  );
};

InsuranceInformationForm.propTypes = {
  submitting: PropTypes.bool,
  toPreviousStep: PropTypes.function,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  companies: PropTypes.array,
  initialValues: PropTypes.object,
  scheduling: PropTypes.object,
  isInsuranceDisable: PropTypes.bool,
  preventInsuranceCompanies: PropTypes.array,
};

export default reduxForm({
  form: SCP_INSURANCE_FORM_NAME,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  validate,
})(InsuranceInformationForm);
