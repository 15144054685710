export const APPOINTMENT_TYPE_VIDEO = 'VIDEO';
export const APPOINTMENT_TYPE_PHONE = 'PHONE';

export const PHONE_MASK_BRACKETS = '+1 (999) 999-9999';

export const PAYMENTBYINSURANCE = 'INSURANCE';
export const PAYMENTBYSELFPAY = 'SELFPAY';
export const PAYMENTNOTSET = 'NONE';

export const invalidInsuranceCompanyName = ['Medicaid', 'Medicare', 'Tricare'];

export const PhoneTypes = [
  { label: 'Home', value: 'Home' },
  { label: 'Office', value: 'Office' },
  { label: 'Mobile', value: 'Mobile' },
];

export const SchedulingPlatformTypes = {
  VSEE: 'VSee',
  PIONEER: 'Pioneer',
};

export const ConsentStatus = {
  SKIPPED: 'skipped',
  CONSENTED: 'consented',
  REVOKED: 'revoked',
  PROCESSING: 'processing',
};

export const JOTFORM_URL = 'https://genomemedical.jotform.com/';

export const Scope = {
  '/clinical-consent': 'consent',
};

export const EligibilityFlowPartners = [
  'Illumina',
  'GenomicHealthEmployee',
  'Novartis',
  'Novartis_Galleri',
];
export const SelectionFlowPartners = ['Genentech'];
