import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { showLoader, closeLoader } from 'ducks/ui';
import api from 'services/api/api';
import { AUTH0_CONFIG } from './Auth0Wrapper';
import authService from 'services/api/authService';
import { setStage } from 'ducks/registrationWizard';
import { CREATE_ACCOUNT } from 'services/wizardWorkflows';
import { requestLogoutStatus } from 'ducks/user';
import { logout } from '../ducks/auth/email';

const Auth0Login = function Auth0Login(props) {
  const { history, dispatch } = props;
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    logout: auth0Logout,
  } = useAuth0();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isAuthenticated) {
      async function getAccessToken() {
        try {
          dispatch(showLoader());
          const at = await getAccessTokenSilently({
            authorizationParams: {
              audience: AUTH0_CONFIG.audience,
            },
          });
          const token = `Bearer ${at}`;
          const res = await authService.checkLink({ token, type: 'auth0' });
          if (res && _.isEmpty(res.user)) {
            setStage(CREATE_ACCOUNT);
            history.push('/signup/account');
          } else {
            const url = authService.getRedirectionAction() || '/patient/home';
            authService.resetRedirectionAction();
            api.setToken(token);
            history.push(url);
          }
          dispatch(closeLoader());
        } catch (e) {
          console.error(e);
          if (e.message === 'Unknown or invalid refresh token.') {
            auth0Logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            }).then(() => {
              return dispatch(logout());
            });
          } else {
            setError(e);
          }
          dispatch(closeLoader());
        }
      }
      void getAccessToken();
    } else {
      try {
        const query = new URLSearchParams(window.location.search);
        const source = query.get('source') || 'email';

        void loginWithRedirect({
          authorizationParams: {
            connection: source,
            redirect_uri: window.location.origin,
          },
        });
      } catch (_) {}
    }
  }, [isAuthenticated, isLoading]);

  return error && <div>{error}</div>;
};

export default connect(null, (dispatch) => ({
  dispatch: dispatch,
}))(Auth0Login);
