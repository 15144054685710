import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import InputField from 'components/forms/controls/V2/InputField';
import PhoneNumberField from 'components/forms/controls/V2/PhoneNumberField';
import SelectField from 'components/forms/controls/V2/SelectField';
import Button from 'components/forms/controls/ButtonV2';
import { required, phone, dateOfBirthOrEmpty } from 'services/validate';
import { PHONE_MASK_BRACKETS, PhoneTypes } from 'constants/CommonConstants';
import get from 'lodash/get';
import classnames from 'classnames';
import CheckboxGroup from '../../../../../components/scp/ProfilePage/CheckboxGroup';
import CheckBox from '../../../../../components/forms/controls/V2/CheckBoxField';

const GenderVariants = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Transgender Female', value: 'transgender - male to female' },
  { label: 'Transgender Male', value: 'transgender - female to male' },
  {
    label: 'Non-binary/genderqueer/gender non-conforming',
    value: 'genderqueer/gender-fluid/gender nonconforming',
  },
  { label: 'Agender', value: 'agender' },
  { label: 'Other', value: 'other' },
];

const relationshipVarients = [
  { label: 'Child', value: 'Child' },
  { label: 'Parent', value: 'Parent' },
  { label: 'Spouse/Domestic Partner', value: 'Spouse/Domestic Partner' },
  { label: 'Sibling', value: 'Sibling' },
  { label: 'Legal Guardian', value: 'Legal Guardian' },
  { label: 'Other', value: 'Other' },
];

const sexAssignedAtBirthVarients = [
  { label: 'Female', value: 'female' },
  { label: 'Male', value: 'male' },
  { label: 'Intersex', value: 'intersex' },
];

const pronounsVariants = [
  { label: 'She/Her/Hers', value: 'she/her/hers' },
  { label: 'He/Him/His', value: 'he/him/his' },
  { label: 'They/Them/Their', value: 'they/them/their' },
];

const emailLabel = 'Email Address',
  firstNameLabel = 'First Name',
  lastNameLabel = 'Last Name',
  preferredNameLabel = 'Preferred Name',
  pronounsLabel = 'Pronouns',
  dobLabel = 'Date of Birth',
  ethnicityLabel = 'Ethnicity',
  genderLabel = 'Gender',
  raceLabel = 'Race',
  sexAssignedAtBirthLabel = 'Sex Assigned at Birth',
  primaryPhoneLabel = 'Primary Phone Number',
  primaryPhoneTypeLabel = 'Primary Phone Type',
  guardianPrimaryPhoneLabel = 'Your Primary Phone Number',
  guardianPrimaryPhoneTypeLabel = 'Primary Phone Type',
  guardianFirstNameLabel = 'Your First Name',
  guardianLastNameLabel = 'Your Last Name',
  guardianDobLabel = 'Your Date of Birth',
  guardianRelationshipLabel = 'Relationship to Patient',
  guardianPreferredNameLabel = 'Your Preferred Name',
  guardianPronounsLabel = 'Your Pronouns',
  consentToTextLabel = 'Consent to Text';

const validateSexAssignAtBirthRequired = required(sexAssignedAtBirthLabel);
const validatePhoneRequired = required(primaryPhoneLabel);
const validateDateOfBirth = dateOfBirthOrEmpty();

const UserInfoForm = ({
  initialValues,
  getEthnicityEnums,
  getRaceEnums,
  raceEnum,
  change,
  ethnicitiesEnum,
  handleSubmit,
  submitting,
  title,
  invalid,
  sexAssignedAtBirthIsNull,
  race,
  onClickHandler }) => {

  const [raceDict, setRaceDict] = useState({});
  const [secondaryRaces, setSecondaryRaces] = useState({});
  const [primaryPhoneType, setPrimaryPhoneType] = useState(get(initialValues, ['primaryPhoneType'], null));
  const [guardianPrimaryPhoneType, setGuardianPrimaryPhoneType] = useState(get(initialValues, ['guardianPrimaryPhoneType'], null));

  useEffect(() => {
    getEthnicityEnums();
    getRaceEnums();

  }, []);

  useEffect(() => {

    setRaceDict(raceEnum.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.label]: curr,
      }),
      {}
    ))

  }, [raceEnum]);

  const handleChangeRace = (...args) => {
    // const [, newValue] = args;
    // this.setState({
    //   race: newValue,
    // });

    change('secondaryRaces', {});
  }

  const handlePrimaryPhoneType = (value) => {
    setPrimaryPhoneType(value);
  };

  const handleGuardianPrimaryPhoneType = (value) => {
    setGuardianPrimaryPhoneType(value);
  };

  const consentToTextMessage = () => {
    return 'By checking this box, I agree to receive automated text notifications from Genome Medical. The notifications may be about appointments, test results, and more.';
  }
  const sexAssignedAtBirthOptions = [...sexAssignedAtBirthVarients];
  if (sexAssignedAtBirthIsNull)
    sexAssignedAtBirthOptions.push({ label: 'Unknown', value: 'unknown' });

  const isUnborn = get(initialValues, ['isUnborn'], false);
  const patientLabelPrefix = get(initialValues, ['hasGuardian'], false)
    ? "Patient's"
    : '';
  const secondaryRaceOptions = get(raceDict[race], ['secondary_races'], []);
  const consentRecontact = get(initialValues, ['consentRecontact'], false);

  return (
    <form
      className="gm-form scp-user-info-form"
      onSubmit={(data) => {
        handleSubmit(data);
      }}
    >
      <div className="back" onClick={() => onClickHandler(0)}>
        Back
      </div>
      <p className="form-title">{title}</p>
      <p className="sub-title">Personal Information</p>
      <div className="line-fields">
        <div className="form-field first-name">
          <label className="field-title">
            {patientLabelPrefix} {firstNameLabel}
          </label>
          <Field name="firstName" type="text" component={InputField} disabled={true} />
        </div>
        <div className="form-field last-name">
          <label className="field-title">
            {patientLabelPrefix} {lastNameLabel}
          </label>
          <Field name="lastName" type="text" component={InputField} disabled={true} />
        </div>
      </div>
      <div className="line-fields">
        <div className="form-field dob">
          <label className="field-title">
            {patientLabelPrefix} {dobLabel}
          </label>
          <Field
            name="dob"
            type="text"
            component={InputField}
            mask="11/11/1111"
            placeholder="MM/DD/YYYY"
            disabled={true}
          />
        </div>
        <div className="form-field sex-assigned-at-birth">
          <label className="field-title required">
            {patientLabelPrefix} {sexAssignedAtBirthLabel}
          </label>
          <Field
            name="sexAssignedAtBirth"
            type="select"
            placeholder="Select"
            component={SelectField}
            options={sexAssignedAtBirthOptions}
            validate={[validateSexAssignAtBirthRequired]}
          />
        </div>
      </div>
      <div className="line-fields">
        <div className="form-field preferred-name">
          <label className="field-title">
            {patientLabelPrefix} {preferredNameLabel}
          </label>
          <Field
            name="preferredName"
            type="text"
            component={InputField}
            placeholder="Preferred Name"
          />
        </div>
        <div className="form-field gender">
          <label className={classnames('field-title')}>
            {patientLabelPrefix} {genderLabel}
          </label>
          <Field
            name="gender"
            type="select"
            placeholder="Select"
            component={SelectField}
            options={GenderVariants}
          />
        </div>
      </div>
      <div className="line-fields">
        <div className="form-field pronouns">
          <label className="field-title">
            {patientLabelPrefix} {pronounsLabel} <span>(select all that apply)</span>
          </label>
          <CheckboxGroup field="pronouns" options={pronounsVariants} parentField="pronouns" />
        </div>
      </div>
      <div className="line-fields line-race-eth-fields">
        <div className="form-field race">
          <label className="field-title">
            {patientLabelPrefix} {raceLabel}
          </label>
          <Field
            name="race"
            type="select"
            placeholder="Select"
            component={SelectField}
            options={raceEnum}
            onChange={handleChangeRace}
          />
        </div>
        <div className="form-field ethnicity">
          <label className="field-title">
            {patientLabelPrefix} {ethnicityLabel}
          </label>
          <Field
            name="ethnicity"
            type="select"
            placeholder="Select"
            component={SelectField}
            options={ethnicitiesEnum}
          />
        </div>
        {secondaryRaceOptions.length ? (
          <div className="line-fields">
            <div className="form-field mixed-race">
              <label className="field-title">
                Please Specify Your Race <span>(select all that apply)</span>
              </label>
              <CheckboxGroup
                field="race"
                options={secondaryRaceOptions}
                parentField="secondaryRaces"
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="update-info">
        To update your name and/or date of birth, please contact us at{' '}
        <a href="tel:+18776880992">1 (877) 688-0992</a>.
      </div>
      {!get(this, ['props', 'initialValues', 'hasGuardian'], false) && (
        <Fragment>
          <div className="form-field">
            <label className="field-title">{emailLabel}</label>
            <Field name="email" type="text" component={InputField} disabled={true} />
          </div>
          <div className="line-fields">
            <div className="form-field phone">
              <label className="field-title required">{primaryPhoneLabel}</label>
              <Field
                name="primaryPhone"
                type="phone"
                component={PhoneNumberField}
                trim={true}
                placeholder="Primary Phone Number"
                label={primaryPhoneLabel}
                validate={[validatePhoneRequired, phone]}
                mask={PHONE_MASK_BRACKETS}
                maskChar={null}
              />
            </div>
            <div className="form-field phone-type">
              <label className="field-title">{primaryPhoneTypeLabel}</label>
              <Field
                name="primaryPhoneType"
                type="select"
                placeholder="Select"
                component={SelectField}
                options={PhoneTypes}
                onChange={(e, value) => handlePrimaryPhoneType(value)}
              />
            </div>
          </div>
          <div className="line-fields">
            <div className="form-field">
              <label className="field-title">{consentToTextLabel}</label>
              <div className="profile-page-checkbox solo-radio">
                <div className={`checkbox-wrapper input-field is-disabled`}>
                  <Field
                    name="primaryPhoneConsentToText"
                    type="checkbox"
                    className="checkbox-wrapper input-field"
                    disabled={true}
                    label={
                      <Fragment>
                        <span className={`checkmark is-disabled`} />
                        <div className="label-section">{consentToTextMessage()}</div>
                      </Fragment>
                    }
                    component={CheckBox}
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
      {get(this, ['props', 'initialValues', 'hasGuardian'], false) && (
        <Fragment>
          <p className="sub-title guardian-info">Your Information</p>
          <div className="line-fields">
            <div className="form-field first-name">
              <label className="field-title">{guardianFirstNameLabel}</label>
              <Field
                name="usersGuardian.firstName"
                type="text"
                component={InputField}
                disabled={true}
              />
            </div>
            <div className="form-field last-name">
              <label className="field-title">{guardianLastNameLabel}</label>
              <Field
                name="usersGuardian.lastName"
                type="text"
                component={InputField}
                disabled={true}
              />
            </div>
          </div>
          <div className="form-field">
            <label className="field-title">{emailLabel}</label>
            <Field name="email" type="text" component={InputField} disabled={true} />
          </div>
          <div className="line-fields">
            <div className="form-field phone">
              <label className="field-title required">{guardianPrimaryPhoneLabel}</label>
              <Field
                name="guardianPrimaryPhone"
                type="phone"
                component={PhoneNumberField}
                trim={true}
                placeholder="Primary Phone Number"
                label={primaryPhoneLabel}
                validate={[validatePhoneRequired, phone]}
                mask={PHONE_MASK_BRACKETS}
                maskChar={null}
              />
            </div>
            <div className="form-field phone-type">
              <label className="field-title">{guardianPrimaryPhoneTypeLabel}</label>
              <Field
                name="guardianPrimaryPhoneType"
                type="select"
                placeholder="Select"
                component={SelectField}
                options={PhoneTypes}
                onChange={(e, value) => handleGuardianPrimaryPhoneType(value)}
              />
            </div>
          </div>
          <div className="line-fields">
            <div className="form-field">
              <label className="field-title">{consentToTextLabel}</label>
              <div className="profile-page-checkbox solo-radio">
                <div className={`checkbox-wrapper input-field ${'is-disabled'}`}>
                  <Field
                    name="guardianPrimaryPhoneConsentToText"
                    type="checkbox"
                    className="checkbox-wrapper input-field"
                    disabled={true}
                    label={
                      <Fragment>
                        <span className={`checkmark is-disabled`} />
                        <div className="label-section">{consentToTextMessage()}</div>
                      </Fragment>
                    }
                    component={CheckBox}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="line-fields">
            <div className="form-field guardian-relationship">
              <label className="field-title">{guardianRelationshipLabel}</label>
              <Field
                name="usersGuardian.relationship"
                type="select"
                placeholder="Select"
                component={SelectField}
                options={relationshipVarients}
                disabled={true}
              />
            </div>
            {isUnborn && (
              <div className="form-field guardian-dob">
                <label className="field-title guardian-dob">{guardianDobLabel}</label>
                <Field
                  name="guardianDob"
                  type="text"
                  mask="99/99/9999"
                  trim={true}
                  maskChar={null}
                  placeholder={'MM/DD/YYYY'}
                  component={PhoneNumberField}
                  disabled={true}
                  validate={[validateDateOfBirth]}
                />
              </div>
            )}
          </div>
          <div className="line-fields">
            <div className="form-field preferred-name">
              <label className="field-title">{guardianPreferredNameLabel}</label>
              <Field
                name="guardianPreferredName"
                type="text"
                component={InputField}
                placeholder="Your Preferred Name"
              />
            </div>
          </div>
          <div className="line-fields">
            <div className="form-field pronouns">
              <label className="field-title">
                {guardianPronounsLabel} <span>(select all that apply)</span>
              </label>
              <CheckboxGroup
                field="guardianPronouns"
                options={pronounsVariants}
                parentField="guardianPronouns"
              />
            </div>
          </div>
        </Fragment>
      )}

      <div className="line-fields recontact">
        <div className="form-field">
          <label className="field-title">Patient Authorization to Recontact</label>
          <div className="profile-page-checkbox solo-radio">
            <div
              className={classnames(`checkbox-wrapper input-field`, {
                'is-disabled': !consentRecontact,
              })}
            >
              <Field
                name="consentRecontact"
                type="checkbox"
                className="checkbox-wrapper input-field"
                disabled={!consentRecontact}
                label={
                  <Fragment>
                    <span
                      className={classnames(`checkmark`, { 'is-disabled': !consentRecontact })}
                    />
                    <div className="label-section">
                      I consent to Genome Medical using and sharing my Health Information as
                      described in the Patient Authorization to Recontact form.
                    </div>
                  </Fragment>
                }
                component={CheckBox}
              />
            </div>
          </div>
        </div>
      </div>

      <Button type="submit" className="btn-save" disabled={invalid || submitting}>
        <span>Save</span>
      </Button>
    </form>
  );
}

UserInfoForm.propTypes = {
  change: PropTypes.func,
  children: PropTypes.element,
  ethnicitiesEnum: PropTypes.array,
  getEthnicityEnums: PropTypes.func,
  getRaceEnums: PropTypes.func,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
  race: PropTypes.string,
  raceEnum: PropTypes.array,
  sexAssignedAtBirthIsNull: PropTypes.bool,
  showDOB: PropTypes.bool,
  submitting: PropTypes.bool,
  title: PropTypes.string,
};

const mapStateToProps = (state) => ({
  race:
    state.form['user-info-form'] &&
    state.form['user-info-form'].values &&
    state.form['user-info-form'].values.race,
});

export default reduxForm({
  form: 'user-info-form',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
})(connect(mapStateToProps)(UserInfoForm));
