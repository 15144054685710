import React, { useState, useEffect, Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import fetchHoc from 'components/high-order-components/FetchHOC';
import Alert from 'components/widgets/Alert/Alert';
import eligibilityService from 'services/api/eligibilityService';
import _ from 'lodash';
import './EligibilityCheckForm.scss';
import gaTrack, { GA_TR_PARTNER_ELGIBILITY_PAGE } from '../../../../services/gaTrack';
import InputField from '../../controls/V2/InputField';
import SelectField from '../../controls/V2/SelectField';
import Button from '../../controls/ButtonV2';
import { isEmpty } from '../../../../services/validate';

const Messages = {
  Illumina: {
    Header: 'Illumina Employee Eligibility Verification',
    Errorinform: (
      <Fragment>
        {' '}
        We are unable to verify your eligibility per the information. Please call 877-688-4791 for
        further assistance.
      </Fragment>
    ),
    Footer:
      'Genome Medical services are available to Illumina employees and their family members at no cost. We collect Illumina Employee IDs for eligibility verification purposes only. We will not share this information with Illumina or any other 3rd party.',
  },
  Novartis: {
    Header: 'Novartis Eligibility Verification',
    Errorinform: (
      <div>
        {' '}
        We are unable to verify your eligibility per the information. Please contact Trenace
        Davenport at trenace.davenport@atlantichealth.org for further assistance.
      </div>
    ),
    Footer: (
      <div>
        Genome Medical services are available to eligible Novartis employees at no cost. A referral
        code is required to proceed with accessing these services. <br />
        <br />
        If you did not obtain a referral code, please contact Trenace Davenport at
        <br /> &nbsp;
        <span className="gm-link">
          <a href="mailto:trenace.davenport@atlantichealth.org">
            trenace.davenport@atlantichealth.org
          </a>
        </span>
        .
      </div>
    ),
  },
  GenomicHealthEmployee: {
    Header: 'Genomic Health Employee Eligibility Verification',
    Errorinform: (
      <div>
        {' '}
        We are unable to verify your eligibility per the information. Please call{' '}
        <a href="tel:+18776880992">(877) 688-0992</a> for further assistance.
      </div>
    ),
    Footer:
      'Genome Medical services are available to eligible Genomic Health employees at no cost. You can find your EMP ID on Ulti-Pro by clicking on “Employee Summary”. We collect Genomic Health Employee IDs for eligibility verification purposes only. We will not share this information with Genomic Health or any other 3rd party.',
  },
  Illumina_scp: {
    Header: 'Illumina Employee Eligibility Verification',
    Errorinform: (
      <Fragment>
        {' '}
        We are unable to verify your eligibility per the information. Please call 877-688-4791 for
        further assistance.
      </Fragment>
    ),
    Footer:
      'Genome Medical services are available to Illumina employees and their family members at no cost. We collect Illumina Employee IDs for eligibility verification purposes only. We will not share this information with Illumina or any other 3rd party.',
  },
  Novartis_Galleri: {
    Header: 'Novartis Eligibility Verification',
    Errorinform: (
      <div>
        {' '}
        We are unable to verify your eligibility. Please contact your primary care provider at
        Atlantic Health for further assistance.
      </div>
    ),
    Footer: (
      <div>
        Genome Medical services are available to eligible Novartis employees at no cost. A referral
        code is required to proceed with accessing these services. <br />
        <br />
        If you did not receive a referral code, please contact your primary care provider at
        Atlantic Health.
      </div>
    ),
  },
};

const ValidateRequired = _.memoize(
  (label = '', exact = false) =>
    (value) =>
      !isEmpty(value) ? undefined : exact ? label : label ? `${label} is required` : 'Required'
);

const renderTextField = ({ label, name, required }) => {
  const validate = required ? [ValidateRequired(label)] : [];
  return (
    <div className="col-section">
      <div className={classnames('label-section', required ? 'required' : '')}>{label}</div>
      <div className="input-section">
        <Field
          name={name}
          type="text"
          placeholder={label}
          label={label}
          component={InputField}
          validate={validate}
        />
      </div>
    </div>
  );
};

const renderSelectField = ({ label, name, values, required }) => {
  const options = values?.map((v) => ({ label: v, value: v })) || [];
  return (
    <div className="col-section">
      <div className={classnames('label-section', required ? 'required' : '')}>{label}</div>
      <div className="input-section">
        <Field
          name={name}
          type="select"
          placeholder="Select"
          component={SelectField}
          options={options}
          validate={required ? [ValidateRequired(label)] : []}
        />
      </div>
    </div>
  );
};

const EligibilityCheckForm = ({
  partner,
  config,
  handleSubmit,
  submitting,
  formError,
  valid,
  history,
  clearErrors,
  redirectTo,
}) => {
  const [needEligibility, setNeedEligibility] = useState(null);

  useEffect(() => {
    const referralName = eligibilityService.getReferral() || partner;
    gaTrack(GA_TR_PARTNER_ELGIBILITY_PAGE);
    eligibilityService
      .getPartnerInfo(referralName)
      .then((response) => {
        const isNeed = response.eligibility_required;
        if (!isNeed) {
          if (redirectTo) redirectTo();
          else history.push('/register/account');
        } else {
          setNeedEligibility(isNeed);
        }
      })
      .catch(() => {
        if (redirectTo) redirectTo();
        else history.push('/register/account');
      });

    if (!config.data && !config.loading) config.fetch(partner);
  }, []);

  const message = Messages[partner] || {};
  const partnerIsNotIllumina = partner !== 'Illumina';

  return (
    needEligibility && (
      <section className="eligibility-check-wrapper">
        <div className="eligibility-check-section">
          {config.loaded && (
            <div className="form-wrapper">
              <div className="genome-header-logo">
                <img className="genome-header-img" src={require('images/logo.svg')} />
              </div>
              <form
                className="gm-eligibility-check-form"
                autoComplete="off"
                onSubmit={handleSubmit}
                noValidate
              >
                <h3 className="gm-eligibility-check-header">{_.get(message, 'Header', '')}</h3>
                <div className="gm-eligibility-check-fields">
                  {config.data.map((item, key) => {
                    const { type, name } = item;
                    const renderer = item.type === 'text' ? renderTextField : renderSelectField;
                    return (
                      <div className="gm-eligibility-check-field" key={key}>
                        {renderer({ ...item })}
                        {partnerIsNotIllumina && formError && formError.fields && (
                          <Alert message={formError.fields[name]} />
                        )}
                      </div>
                    );
                  })}

                  {formError && (
                    <div className={'gm-helper-text'}>
                      {_.get(message, 'Errorinform', '')}
                      {!_.isNil(_.get(formError, ['fields', 'country_code'], '')) &&
                      !_.isEmpty(_.get(formError, ['fields', 'country_code'], ''))
                        ? ' (' + (_.get(formError, ['fields', 'country_code'], '') + ')')
                        : ''}
                    </div>
                  )}

                  <div
                    className={classnames('gm-eligibility-field submit-button', {
                      disabled: submitting,
                    })}
                  >
                    <div className="gm-eligibility-control">
                      <Button
                        className="submit-button"
                        type="submit"
                        disabled={submitting || !valid}
                      >
                        <span>Continue</span>
                      </Button>
                    </div>
                  </div>
                  <div className="eligibility-warning">{_.get(message, 'Footer', '')}</div>
                </div>
              </form>
            </div>
          )}
        </div>
      </section>
    )
  );
};

EligibilityCheckForm.propTypes = {
  partner: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
  config: PropTypes.object.isRequired,
};

const form = reduxForm({
  form: 'eligibility-check-form',
})(EligibilityCheckForm);

export default fetchHoc({
  config: (partner) => eligibilityService.getEligibilityConfig(partner),
})(form);
