import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';

const Auth0SignupPage = ({ redirectAfterAuth0 }) => {
  const { isLoading, isAuthenticated, loginWithRedirect, error } = useAuth0();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isAuthenticated) {
      if (!error) redirectAfterAuth0();
    } else {
      try {
        void loginWithRedirect({
          authorizationParams: {
            connection: 'email',
            redirect_uri: window.location.href,
            screen_hint: 'signup',
          },
        });
      } catch (_) {}
    }
  }, [isAuthenticated, isLoading]);

  return error ? <div>{error}</div> : null;
};

export default Auth0SignupPage;
