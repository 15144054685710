import * as Sentry from '@sentry/react';

import { me, meLoadProfile } from '../user';
import authService from 'services/api/authService';
import schedulingService from 'services/api/schedulingService';
import StatusMessages from 'constants/StatusMessages';
import { clearSchedulingInfo } from 'ducks/scheduling';
import { showLoader, closeLoader } from 'ducks/ui';
import { reduceSoftSignupResponseData } from 'services/utils';
import api from 'services/api/api';

const REQUEST_START = 'gm/auth/email/REQUEST_START';
const REQUEST_SUCCESS = 'gm/auth/email/REQUEST_SUCCESS';
const REQUEST_FAILURE = 'gm/auth/email/REQUEST_FAILURE';

const initialState = {
  authError: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_START:
      return {
        ...state,
        authError: null,
      };
    case REQUEST_FAILURE:
      return {
        ...state,
        authError:
          action.payload.errorMessage ||
          action.payload.message ||
          action.payload.fullMessage ||
          StatusMessages.registerDefaultError,
      };
    default:
      return state;
  }
}

export function setRequestStart() {
  return { type: REQUEST_START };
}

export function setRequestSuccess() {
  return { type: REQUEST_SUCCESS };
}

export function setRequestFailure(message) {
  return { type: REQUEST_FAILURE, payload: message };
}

export function login({ email, password, rememberMe }) {
  return (dispatch) => {
    dispatch(setRequestStart());
    return authService
      .login({ email, password }, rememberMe)
      .then(() => {
        Sentry.setUser({ email });
        dispatch(clearSchedulingInfo());
        dispatch(setRequestSuccess());
        dispatch(me());
      })
      .catch((error) => {
        Sentry.setUser(null);
        if (error.submissionError) throw error.submissionError;
        dispatch(setRequestFailure(error));
        throw error;
      });
  };
}

export function logout(redirect = true) {
  return (dispatch) => {
    dispatch(setRequestStart());
    return authService
      .logout()
      .then(() => {
        Sentry.setUser(null);
        schedulingService.clearSchedulingData();
        schedulingService.clearSourceType();
        authService.resetGrailDisqualification();
        dispatch(setRequestSuccess());
        if (redirect) {
          window.location = '/';
        }
      })
      .catch(({ error }) => dispatch(setRequestFailure(error)));
  };
}

export function activateEmail(activationToken) {
  return (dispatch) => {
    dispatch(setRequestStart());
    dispatch(showLoader());
    return authService
      .verifyAccount({ token: activationToken })
      .then(() => {
        dispatch(setRequestSuccess());
        dispatch(closeLoader());
        return dispatch(me());
      })
      .catch((error) => {
        dispatch(setRequestFailure(error));
        dispatch(closeLoader());
        throw error;
      });
  };
}

export function loginWithoutApi(token) {
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      api.setToken(null);
      api.setToken(token);
      const data = await dispatch(meLoadProfile());
      dispatch(closeLoader());
      return data;
    } catch (error) {
      dispatch(closeLoader());
      throw error;
    }
  };
}
