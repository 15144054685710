import _ from 'lodash';
import React, { useState } from 'react';
import UpArrowIcon from './arrow-up.svg';
import DownArrowIcon from './arrow-down.svg';

const InsuranceDescription = (props) => {
  const [show, setShow] = useState(false);

  const showDescription = props.isMobile ? show : true;
  const price = _.get(props.selectedProduct, 'price', '');

  return (
    <div>
      {props.isMobile ? (
        <div className="heading" onClick={() => setShow(!show)}>
          <div>View billing details</div>
          <img src={show ? UpArrowIcon : DownArrowIcon} />
        </div>
      ) : (
        <div className="heading">Billing Details</div>
      )}
      {showDescription && (
        <>
          <div className="subheading">What is the cost?</div>
          <div className="description">
            <p>
              The cost of a genetic counseling session will be no more than ${'250'}*, depending on
              if you pay out-of-pocket or choose to bill insurance.{' '}
            </p>
            <br />
            <p>
              The cost of genetic counseling does not include the cost of any genetic testing.
              Genetic testing is billed directly to you by the laboratory.
            </p>
            <br />

            <p>*Pricing subject to change</p>
          </div>
          <div className="subheading">Is this covered by insurance?</div>
          <div className="description">
            <p>
              Contact your insurance provider for your exact coverage and payment responsibility.
              Reference insurance CPT codes 96041 and S0265 and our NPI number 1407389695.
            </p>
            <br />

            <p>
              We are an in-network provider with most commercial health insurance plans. See a list
              at{' '}
              <a target="_blank" href="https://www.genomemedical.com/insurance-coverage/">
                genomemedical.com/insurance-coverage.
              </a>{' '}
            </p>
            <br />

            <p>
              If you use insurance we will submit a claim on your behalf. You may get a bill for any
              outstanding balance depending on your coverage.
            </p>
            <br />

            <p>
              At this time, we do not accept government-affiliated insurance providers including
              Medicaid, Medicare and/or Tricare as a primary or secondary insurance (including
              Managed Medicare and Medicaid plans).
            </p>
          </div>
          <div className="subheading">Questions? </div>
          <div className="description">
            <p>
              Contact us at <a href="tel:+18776880992">877-688-0992</a>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default InsuranceDescription;
