import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import api from 'services/api/api';
import {
  addAction,
  REDIRECT_AFTER_LOGIN,
  OPEN_SWITCH_TO_PHONE,
  OPEN_SWITCH_TO_VIDEO,
  DOCUMENT_VIEW,
  MANUAL_CONSENT_OUTREACH,
  OPEN_QUESTIONNAIRE,
  OPEN_OUTREACH_APPOINTMENT,
  REDIRECT_PATIENT,
} from 'ducks/misc/redirectActions';
import GlobalLoader from 'components/widgets/GlobalLoader/GlobalLoader';
import gaTrack, {
  GA_TR_PATIENT_SURVEY_PAGE,
  GA_TR_APPOINTMENT_SWITCH_TO_PHONE_PAGE,
} from '../../services/gaTrack';

import _ from 'lodash';
import authService from 'services/api/authService';
import { AUTH0_LOGIN_ENABLED } from '../Auth0Wrapper';

class RedirectFromOutside extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    route: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object,
    redirectActions: PropTypes.object,
  };

  componentDidMount() {
    const {
      dispatch,
      history,
      location: { search },
    } = this.props;
    const queryParams = new URLSearchParams(search);
    const islegacy = queryParams.get('legacy');
    const redirect = (url) => {
      if (api.hasToken()) {
        history.push(url);
      }
      if ((islegacy !== 'true' && !AUTH0_LOGIN_ENABLED) || AUTH0_LOGIN_ENABLED) {
        authService.saveRedirectionAction(url);
      } else {
        dispatch(addAction(REDIRECT_AFTER_LOGIN, url));
      }
    };
    switch (this.props.name) {
      case 'questionnaire':
        gaTrack(GA_TR_PATIENT_SURVEY_PAGE);
        dispatch(addAction(OPEN_QUESTIONNAIRE));
        redirect('/patient/questionnaire');
        break;
      case 'switch-to-phone':
        gaTrack(GA_TR_APPOINTMENT_SWITCH_TO_PHONE_PAGE);
        dispatch(addAction(OPEN_SWITCH_TO_PHONE));
        redirect('/patient');
        break;
      case 'switch-to-video':
        gaTrack(GA_TR_APPOINTMENT_SWITCH_TO_PHONE_PAGE);
        dispatch(addAction(OPEN_SWITCH_TO_VIDEO));
        redirect('/patient');
        break;
      case 'document-view':
        dispatch(addAction(DOCUMENT_VIEW));
        redirect(`/patient/results`);
        break;
      case 'appointment-outreach':
        dispatch(addAction(OPEN_OUTREACH_APPOINTMENT));
        if (!_.isNil(_.get(this, ['props', 'match', 'params', 'appointmentId']))) {
          redirect(
            `/patient/outreach/${_.get(this, ['props', 'match', 'params', 'appointmentId'])}`
          );
        } else {
          redirect(`/patient/outreach`);
        }

        break;
      case 'consent-outreach':
        dispatch(addAction(MANUAL_CONSENT_OUTREACH));
        redirect('/patient/home');
        break;
      case 'patient':
      case 'login':
        dispatch(addAction(REDIRECT_PATIENT));
        redirect('/patient');
    }
  }

  render() {
    return this.props.name ? <div /> : <GlobalLoader />;
  }
}

export default connect()(RedirectFromOutside);
