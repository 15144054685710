import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from 'components/forms/controls/ButtonV2';
import InputField from 'components/forms/controls/V2/InputField';
import Alert from 'components/widgets/Alert/Alert';
import AppointmentSchedulingFailedModal from 'components/modals/AppointmentSchedulingFailedModal';
import ModalDialog from 'components/modals/ModalDialog';
import { clearFormError } from 'ducks/registrationWizard';
import { getConsultation, GetSpeciality } from 'ducks/scheduling';
import { setModalMode } from 'ducks/modals';
import {
  getSCPFormattedDateAndTime,
  getFormattedAppointmentType,
  formatPriceFree,
} from 'services/utils';
import schedulingService from 'services/api/schedulingService';
import classnames from 'classnames';
import gaTrack, { GA_TR_SCHEDULING_CONFIRM_PAGE } from '../../../../../services/gaTrack';
import './ConfirmAppointmentForm.scss';
import { required } from 'services/validate';
import warningIcon from '../../../../../containers/pages/public/Scp/TestOrderRequestConfirm/warning-red.svg';

const validateReferringProvider = required('Referring Provider');
const validateReferringProviderRecom = required("Referring Provider's Recommended Test");
const validateProviderEmailFax = required("Provider's Email or Fax Number");

const ConfirmAppointmentForm = ({
  appointment,
  selectedServiceName,
  paymentMethod,
  clientData,
  scheduling,
  insurance,
  newPrice,
  companies,
  me,
  handleSubmit,
  toPreviousStep,
  formError,
  submitting,
  onHide,
  show,
  bookAppointmentTimedOutError,
  dispatch,
  modals,
}) => {
  useEffect(() => {
    gaTrack(GA_TR_SCHEDULING_CONFIRM_PAGE);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!_.isNil(formError)) {
      dispatch(setModalMode('appointmentSchedulingFailedModal', { open: true }));
    }
  }, [formError]);

  useEffect(() => {
    if (!_.isNil(bookAppointmentTimedOutError)) {
      dispatch(setModalMode('bookAppointmentErrorModal', { open: true }));
    }
  }, [bookAppointmentTimedOutError]);

  const formatSpeciality = (specialty) => {
    const displaySpeciality = GetSpeciality(specialty);
    return displaySpeciality != null ? displaySpeciality.replace('_', ' ') : displaySpeciality;
  };

  const formFields = () => {
    const specialityName = _.get(appointment, 'vsee_specialty')
      ? appointment.vsee_specialty
      : _.get(scheduling, 'calendarSpeciality', 'N/A');
    const { start, end, timezone, consultation, provider, duration } = appointment;
    const { calendarModality } = scheduling;
    const fullName = provider && (provider.full_name || provider.fullName);
    let creditCard = '';
    const withInsurance =
      schedulingService.getUseInsurance() &&
      !_.isNil(insurance?.primaryInsurance?.insuranceCompanyId);
    if (totalPrice() === 0) {
      creditCard = 'N/A';
    } else if (_.isEmpty(paymentMethod) && withInsurance) {
      creditCard = 'Insurance';
    } else if ((paymentMethod && paymentMethod.cardNumberLast4) || withInsurance) {
      creditCard = 'Credit card ending in ' + paymentMethod.cardNumberLast4;
    }
    const isPhoneOnly = me.isPhoneOnly;
    const appointmentType = calendarModality || isPhoneOnly ? 'Phone' : 'Video';
    const fields = [
      {
        caption: 'Consultation Type',
        text: consultation && getFormattedAppointmentType(consultation, duration),
      },
    ];

    fields.push(
      {
        caption: 'Appointment Type',
        text: appointmentType,
      },
      {
        caption: 'Date and Time',
        text: getSCPFormattedDateAndTime(start, end, timezone),
      },
      {
        caption: 'Specialty',
        text: formatSpeciality(specialityName),
      },
      {
        caption: 'Payment Method',
        text: creditCard,
      },
      {
        caption: 'Phone Number',
        text: clientData
          ? `${
              clientData.hasGuardian
                ? clientData?.usersGuardian?.guardianPrimaryPhone || ''
                : clientData?.primaryPhone || ''
            }`
          : '',
      },
      {
        caption: 'Service Selected',
        text: selectedServiceName,
      },
      {
        caption: 'Counselor',
        text: fullName,
      }
    );

    return fields;
  };

  const totalPrice = () => {
    const { selectedProduct, selectedServiceDescriptor, consultationTypes } = scheduling;

    return newPrice != null
      ? newPrice
      : getConsultation(selectedServiceDescriptor, selectedProduct, consultationTypes).price;
  };

  const insurancePrice = () => {
    const insuranceCompany = companies.find(
      (c) => c.id === insurance?.primaryInsurance?.insuranceCompanyId
    );

    return insuranceCompany ? insuranceCompany.price : '';
  };

  const fieldsToShow = () => {
    return formFields().filter((tf) => !!tf.text);
  };
  const specificAffiliation = () => {
    return me.affiliation == 'InvitaeCancer';
  };

  if (!appointment) return null;

  const itemsClassnames = classnames('confirm-form-items', {
    'confirm-form-items__reduced': fieldsToShow().length < formFields().length,
  });

  const withInsurance =
    schedulingService.getUseInsurance() &&
    !_.isNil(insurance?.primaryInsurance?.insuranceCompanyId);

  return (
    <div className={classnames('scp-schedule-wizard scp-confirm-form', 'ninetyDays')}>
      <h2 className="gm-select-service__header">{'Review All Details'}</h2>
      <div className="confirm-form-content" data-hj-suppress>
        <div className={itemsClassnames}>
          {fieldsToShow().map((tf) => {
            return (
              <div className="confirm-content-item" key={tf.caption}>
                <div className="gm-service-text__grey">{tf.caption}</div>
                <div className="gm-service-text">{tf.text}</div>
              </div>
            );
          })}
        </div>
      </div>
      <form onSubmit={handleSubmit} className={classnames('review-form')}>
        {specificAffiliation() && (
          <div style={{ marginBottom: '30px' }}>
            <div className="form-row">
              <div className="form-col">
                <div className="label-section required">Referring Provider</div>
                <Field
                  name="referring_provider_name"
                  type="text"
                  component={InputField}
                  placeholder={'John Doe'}
                  validate={[validateReferringProvider]}
                />
              </div>
              <div className="form-col">
                <div className="label-section required">Referring Provider's Recommended Test</div>
                <Field
                  name="requested_test_via_provider"
                  type="text"
                  component={InputField}
                  placeholder={'Test A'}
                  validate={[validateReferringProviderRecom]}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col">
                <div className="label-section required">Provider's Email or Fax Number</div>
                <Field
                  name="referring_provider_fax_email"
                  type="text"
                  component={InputField}
                  placeholder={'+1 (123) 456 - 7890 or email@email.com'}
                  validate={[validateProviderEmailFax]}
                />
              </div>
            </div>
          </div>
        )}
        <Alert message={formError} />
        <AppointmentSchedulingFailedModal
          show={modals.appointmentSchedulingFailedModal?.open}
          closeCallback={() => {
            dispatch(setModalMode('appointmentSchedulingFailedModal', { open: false }));
            dispatch(clearFormError());
          }}
        />
        {!_.isNil(bookAppointmentTimedOutError) &&
          (bookAppointmentTimedOutError.status == 503 ||
            bookAppointmentTimedOutError.status == 504) && (
            <ModalDialog
              className={`book-appointment-error-modal`}
              modalHeader={
                <Fragment>
                  <img src={warningIcon} alt="Warning" className="warning-icon" />
                  Scheduling Error
                </Fragment>
              }
              show={show}
              onHide={onHide}
            >
              <div className="wrapper">
                <div className="book-appointment-error-content">
                  Sorry! Something has gone wrong on our side. Please contact your care coordinator
                  at <span className="cc-contact-number">(877) 688-0992</span> to schedule your
                  appointment.
                </div>
              </div>
            </ModalDialog>
          )}
        {withInsurance && (
          <div className="fine-print">
            <span className="star">*</span>
            <span>
              After your session, we will submit a claim to your insurance on your behalf. Once your
              insurance processes your claim, taking into account your co-insurance and deductible,
              we may follow up with you to collect any outstanding payment. If you have questions at
              any time, please feel free to call one of our Care Coordinators at{' '}
              <a href="tel:+18776880992">(877) 688-0992</a>.
            </span>
          </div>
        )}
        <div className="cancellation-policy-section">
          <div className="visit-amount-section" data-hj-suppress>
            {withInsurance ? (
              <div className="total-fee-section">
                <span>Visit Deposit: </span>
                <span className="visit-fee">${insurancePrice()}</span>
              </div>
            ) : (
              <div className="total-fee-section">
                <span>Visit fee: </span>
                <span className="visit-fee">{formatPriceFree(totalPrice())}</span>
              </div>
            )}
          </div>
          <div className="cancellation-policy-header">Cancellation Policy</div>
          <div className="cancellation-policy-body">
            Please call <a href="tel:+18776880992">(877) 688-0992</a> at least 24
            hours prior to your scheduled appointment to notify us of any changes or cancellations.
          </div>
        </div>
        <div className="buttons">
          {toPreviousStep && (
            <Button className="button button-back" onClick={toPreviousStep}>
              Back
            </Button>
          )}
          <Button
            className="button button-next book-appointment"
            type="submit"
            disabled={submitting}
          >
            Book Appointment
          </Button>
        </div>
      </form>
    </div>
  );
};

ConfirmAppointmentForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  toPreviousStep: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  formError: PropTypes.string,
  scheduling: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  newPrice: PropTypes.number,
  companies: PropTypes.object,
  insurance: PropTypes.object,
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  appointment: PropTypes.object.isRequired,
  selectedServiceName: PropTypes.string,
  paymentMethod: PropTypes.object,
  clientData: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = ({ modals, scheduling, user }) => ({
  modals,
  scheduling,
  user,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'scp-confirm-appointment-form',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(ConfirmAppointmentForm)
);
