import React, { Component } from 'react';
import 'services/polyfill';
import { Provider } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { i18n } from 'gm-element-react';
import locale from 'gm-element-react/src/locale/lang/en';
import { isNull } from 'lodash';
import api from 'services/api/api';
import authService from 'services/api/authService';
import { me, requestAuthorizationStatus } from 'ducks/user';
import { fetchInitialMeta, checkLetestMeta } from './ducks/appmeta';
import { logout } from 'ducks/auth/email';
import { removeAction, REDIRECT_AFTER_LOGIN } from 'ducks/misc/redirectActions';
import { setSessionSourceType } from 'ducks/scheduling';
import configureStore from './store/configureStore';
import Containers from './containers';
import GeneralLayout, {
  PUBLIC_LAYOUT_TYPE_V2,
  SCP_PATIENT_LAYOUT_TYPE,
} from 'containers/layouts/GeneralLayout';
import GuardComponent from 'containers/GuardComponent';
import AfterLoginGuard from 'containers/AfterLoginGuard';
import './styles/styles.scss';
import gaTrack, { GA_TR_REDIRECT_TO_PORTAL } from 'services/gaTrack';
import { Scope } from './constants/CommonConstants';
import { createBrowserHistory } from 'history';
import 'react-select/dist/react-select.css';
import { AUTH0_LOGIN_ENABLED, Auth0Wrapper } from './containers/Auth0Wrapper';
import Auth0Login from './containers/Auth0LoginPage';
import AuthProvider from './containers/AuthProvider';
import Auth0RegistrationWizardPage from './containers/pages/public/RegistrationWizardPage/Auth0RegistrationWizardPage';

const history = createBrowserHistory();

const store = configureStore();
i18n.use(locale);

api.api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { dispatch } = store;
    if (error.response && error.response.status === 401 && api.hasToken()) {
      dispatch(requestAuthorizationStatus(false));
    }
    return Promise.reject(error);
  }
);

const PrivateRoute = ({ component: Component, render, isAuthenticated, ...rest }) => {
  if (api.hasToken()) {
    ensureUserIsLoaded();
    clearRedirectActions();
  }
  return (
    <AuthProvider>
      <Route
        {...rest}
        render={(props) =>
          api.hasToken() ? (
            Component ? (
              <Component {...props} />
            ) : (
              render(props)
            )
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    </AuthProvider>
  );
};

export function scrollToTop() {
  document.getElementById(appId).scrollTop = 0;
}

export function clearRedirectActions() {
  const { dispatch } = store;
  const {
    redirectActions: { actions },
  } = store.getState();
  if (actions[REDIRECT_AFTER_LOGIN]) {
    dispatch(removeAction(REDIRECT_AFTER_LOGIN));
  }
}

export function ensureUserIsLoaded() {
  const currentUser = authService.getCurrentUser(store.getState());
  const { dispatch } = store;
  if (api.hasToken() && currentUser === null) {
    if (api.hasLegacyToken() && api.scopesofToken()) {
      const scopeIncludeAstrik = api.hasTokenIncludeScope('*');
      if (!scopeIncludeAstrik) {
        return;
      }
    }
    dispatch(me());
  }
}

class App extends Component {
  constructor() {
    super();

    const { dispatch } = store;
    const url_medium = window.location.search.match(/url_medium=+\w+/)?.[0] ?? null;
    if (!isNull(url_medium)) dispatch(setSessionSourceType(url_medium.replace('url_medium=', '')));
  }

  componentDidMount() {
    const referrer = document.referrer;
    if (referrer) gaTrack(GA_TR_REDIRECT_TO_PORTAL);
    const timeoutmiliseonds = process.env.GM_ENV === 'production' ? 60 * 60000 : 5 * 60000;
    const { dispatch } = store;
    dispatch(fetchInitialMeta());
    this.interval = setInterval(() => {
      dispatch(checkLetestMeta());
    }, timeoutmiliseonds);

    ensureUserIsLoaded();
    this.onRouteUpdate();
    this.unlisten = history.listen((location, action) => {
      this.onRouteUpdate();
    });
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  onRouteUpdate() {
    const { dispatch } = store;
    // scroll to top on any route change
    scrollToTop();
    if (api.hasLegacyToken() && api.scopesofToken()) {
      const userScopes = api.scopesofToken();
      const scopeIncludeAstrik = api.hasTokenIncludeScope('*');
      if (!scopeIncludeAstrik) {
        const pathName = window.location.pathname;
        const selectedPathNameScope = Scope[pathName];
        const hasUserScope = userScopes.find((a) => a == selectedPathNameScope);
        if (!hasUserScope) {
          dispatch(logout());
        }
      }
    }
  }

  getRouter() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/privacy" exact component={Containers.PrivacyPage} />
          <Route path="/consent" exact component={Containers.ConsentToTreatmentPage} />
          <Route path="/selection/thankyou" exact component={Containers.ThankuYouSelectionPage} />
          <Route
            path="/clinical-consent"
            exact
            component={Containers.ClinicalConsentContainerBeforeLogin}
          />
          <Route path="/referral" exact component={Containers.ReferralIframe} />
          <Redirect path="/appointment/completed" to="/patient" />
          <Route
            path="/patient/survey"
            exact
            render={() => (
              <GeneralLayout layoutType={PUBLIC_LAYOUT_TYPE_V2}>
                <Switch>
                  <Route
                    exact
                    path="/patient/survey"
                    render={(props) => (
                      <Containers.RedirectFromOutside {...props} name="questionnaire" />
                    )}
                  />
                </Switch>
              </GeneralLayout>
            )}
          />
          <Route
            path="/patient/consultation"
            render={() => (
              <GeneralLayout layoutType={PUBLIC_LAYOUT_TYPE_V2}>
                <Switch>
                  <Route
                    exact
                    path="/patient/consultation"
                    render={(props) => <Containers.RedirectFromOutside {...props} name="patient" />}
                  />
                </Switch>
              </GeneralLayout>
            )}
          />
          <Route
            path="/patient/consent-outreach"
            render={() => (
              <GeneralLayout layoutType={PUBLIC_LAYOUT_TYPE_V2}>
                <Switch>
                  <Route
                    exact
                    path="/patient/consent-outreach"
                    render={(props) => (
                      <Containers.RedirectFromOutside {...props} name="consent-outreach" />
                    )}
                  />
                </Switch>
              </GeneralLayout>
            )}
          />
          <Route
            path="/account/activate/:activationToken"
            render={() => (
              <GeneralLayout layoutType={PUBLIC_LAYOUT_TYPE_V2}>
                <Switch>
                  <Route
                    exact
                    path="/account/activate/:activationToken"
                    render={(props) => <Containers.AccountActivate {...props} />}
                  />
                </Switch>
              </GeneralLayout>
            )}
          />

          <Route
            path="/book/appointment/"
            render={() => (
              <GeneralLayout layoutType={PUBLIC_LAYOUT_TYPE_V2}>
                <Switch>
                  <Route
                    exact
                    path="/book/appointment/:service?"
                    component={Containers.RedirectToSchedule}
                  />
                </Switch>
              </GeneralLayout>
            )}
          />

          <Route
            path="/documents"
            render={() => (
              <GeneralLayout layoutType={PUBLIC_LAYOUT_TYPE_V2}>
                <Switch>
                  <Route
                    exact
                    path="/documents/:documentid/view"
                    render={(props) => (
                      <Containers.RedirectFromOutside {...props} name="document-view" />
                    )}
                  />
                  <Route
                    exact
                    path="/documents/view"
                    render={(props) => (
                      <Containers.RedirectFromOutside {...props} name="document-view" />
                    )}
                  />
                </Switch>
              </GeneralLayout>
            )}
          />
          <PrivateRoute
            path="/patient/clinical-consent"
            exact
            render={(props) => (
              <GeneralLayout layoutType={SCP_PATIENT_LAYOUT_TYPE}>
                <Containers.ClinicalTrialContainer {...props} isSkipFlow={true} />
              </GeneralLayout>
            )}
          />
          <PrivateRoute
            path="/patient"
            render={() => (
              <GuardComponent>
                <GeneralLayout layoutType={SCP_PATIENT_LAYOUT_TYPE}>
                  <AfterLoginGuard>
                    <Switch>
                      <Route path="/patient" exact component={Containers.SCPMyGuidePage} />
                      <Route path="/patient/home" exact component={Containers.SCPHome} />
                      <Route path="/patient/profile" exact component={Containers.SCPProfile} />
                      <Route path="/patient/results" exact component={Containers.SCPMyHealthPage} />
                      <Route
                        path="/patient/reschedule"
                        exact
                        component={Containers.SCPReschedulePage}
                      />
                      <Route
                        path="/patient/grailgalleri"
                        exact
                        component={Containers.GrailGalleriPage}
                      />
                      <Route
                        path="/patient/switchaffiliation"
                        exact
                        component={Containers.GrailWelcomePage}
                      />
                      <Route
                        path="/patient/redirect-for-switch"
                        exact
                        component={Containers.SwitchAffiliation}
                      />
                      <Route
                        path="/patient/consent"
                        exact
                        component={Containers.ClinicalTrialContainer}
                      />
                      <Route
                        path="/patient/questionnaire"
                        exact
                        component={Containers.QuestionnairePage}
                      />
                      <Route
                        path="/patient/outreach/:appointmentId?"
                        exact
                        component={Containers.OutreachAppointment}
                      />
                      <Route
                        path="/patient/testrequest/:step"
                        exact
                        component={Containers.SCPShippingWizardPage}
                      />
                      <Route
                        path="/patient/testorder"
                        exact
                        component={Containers.SCPTestOrderRequestConfirmContainer}
                      />
                    </Switch>
                  </AfterLoginGuard>
                </GeneralLayout>
              </GuardComponent>
            )}
          />
          <Route
            exact
            path="/appointments/outreach/:appointmentId?"
            component={Containers.AppointmentOutreach}
          />
          <Route
            path="/appointments"
            render={() => (
              <GeneralLayout layoutType={PUBLIC_LAYOUT_TYPE_V2}>
                <Switch>
                  <Route
                    exact
                    path="/appointments/:appointmentId/waiting-room"
                    component={Containers.SCPWaitingRoom}
                  />
                  <Route
                    exact
                    path="/appointments/:appointmentId/switch-to-phone"
                    render={(props) => (
                      <Containers.RedirectFromOutside {...props} name="switch-to-phone" />
                    )}
                  />
                  <Route
                    exact
                    path="/appointments/:appointmentId/switch-to-video"
                    render={(props) => (
                      <Containers.RedirectFromOutside {...props} name="switch-to-video" />
                    )}
                  />
                </Switch>
              </GeneralLayout>
            )}
          />
          <PrivateRoute
            path="/scheduling"
            render={(_props) => (
              <GuardComponent>
                <GeneralLayout layoutType={SCP_PATIENT_LAYOUT_TYPE}>
                  <AfterLoginGuard>
                    <Switch>
                      <Route
                        path="/scheduling/:service/:step"
                        component={Containers.SCPSchedulingWizardPage}
                      />
                      <Route
                        path="/scheduling/:step"
                        component={Containers.SCPSchedulingWizardPage}
                      />
                    </Switch>
                  </AfterLoginGuard>
                </GeneralLayout>
              </GuardComponent>
            )}
          />
          <Route
            path="/registration/:partner"
            exact
            component={
              AUTH0_LOGIN_ENABLED
                ? Containers.Auth0RegistrationEntryPoint
                : Containers.RegistrationEntryPoint
            }
          />
          <Route
            path="/legacy/registration/:partner"
            exact
            component={Containers.RegistrationEntryPoint}
          />
          <Route
            path="/auth0/registration/:partner"
            exact
            component={Containers.Auth0RegistrationEntryPoint}
          />
          <Route
            path="/test/registration/:partner"
            exact
            component={Containers.TestRequestRegistrationEntryPoint}
          />
          <Route
            path="/"
            render={() => (
              <GeneralLayout layoutType={PUBLIC_LAYOUT_TYPE_V2}>
                <Switch>
                  <Route
                    path="/"
                    exact
                    component={(props) =>
                      AUTH0_LOGIN_ENABLED ? (
                        <Auth0Login {...props} />
                      ) : (
                        <Containers.LoginPage {...props} hideSignIn />
                      )
                    }
                  />
                  <Route
                    path="/login"
                    exact
                    component={(props) =>
                      AUTH0_LOGIN_ENABLED ? (
                        <Auth0Login {...props} />
                      ) : (
                        <Containers.LoginPage {...props} hideSignIn />
                      )
                    }
                  />
                  <Route
                    path="/auth0/login"
                    exact
                    component={(props) => <Auth0Login {...props} />}
                  />

                  <Route
                    path="/legacy/login"
                    exact
                    component={(props) => <Containers.LoginPage {...props} hideSignIn />}
                  />
                  <Route
                    path="/complete-setup/:patientUUID"
                    exact
                    component={Containers.CompleteSetup}
                  />
                  <Route path="/password/forgot" exact component={Containers.ForgotPasswordPage} />
                  <Route
                    path="/password/reset/:resetPasswordToken"
                    exact
                    render={(props) => <Containers.SetPasswordPage {...props} reset />}
                  />
                  <Route
                    path="/password/set/:resetPasswordToken"
                    exact
                    component={Containers.SetPasswordPage}
                  />
                  <Route path="/signup/account" exact component={Auth0RegistrationWizardPage} />
                  <Route
                    path="/register/:service/:step"
                    exact
                    component={Containers.RegistrationWizardPage}
                  />
                  <Route
                    path="/register/:step"
                    exact
                    component={Containers.RegistrationWizardPage}
                  />
                  <Route
                    path="/test/register"
                    exact
                    component={Containers.TestRequestWizardPage}
                  ></Route>
                  <Redirect path="*" to="/" />
                </Switch>
              </GeneralLayout>
            )}
          />
          <Redirect path="*" to="/" />
        </Switch>
      </Router>
    );
  }

  render() {
    return (
      <Provider store={store}>
        <Auth0Wrapper>{this.getRouter()}</Auth0Wrapper>
      </Provider>
    );
  }
}

export default App;
export const appId = 'app';
export function getStore() {
  return store;
}
