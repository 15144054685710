import appMetaService from 'services/api/appMetaService';
import { requestLogoutStatus } from './user';

const INITIAL_META_REQUEST = 'gm/appmeta/INITIAL_META_REQUEST';
const INITIAL_META_REQUEST_SUCCESS = 'gm/appmeta/INITIAL_META_REQUEST_SUCCESS';
const INITIAL_META_REQUEST_FAILURE = 'gm/appmeta/INITIAL_META_REQUEST_FAILURE';

const CHECKING_LATEST_META_REQUEST = 'gm/appmeta/CHECKING_LATEST_META_REQUEST';
const CHECKING_LATEST_META_SUCCESS = 'gm/appmeta/CHECKING_LATEST_META_SUCCESS';
const CHECKING_LATEST_META_FAILURE = 'gm/appmeta/CHECKING_LATEST_META_FAILURE';
const SHOW_RELEASE_NOTICE = 'gm/appmeta/SHOW_RELEASE_NOTICE';

const initialState = {
  defaultMetaLoading: false,
  currentappMeta: null,
  checkingLatestMeta: false,
  error: null,
  showReleaseNotice: false,
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INITIAL_META_REQUEST:
      return {
        ...state,
        defaultMetaLoading: true,
        error: null,
        currentappMeta: null,
      };
    case INITIAL_META_REQUEST_SUCCESS:
      return {
        ...state,
        defaultMetaLoading: false,
        error: null,
        currentappMeta: action.payload,
      };
    case INITIAL_META_REQUEST_FAILURE:
      return {
        ...state,
        defaultMetaLoading: false,
        error: action.payload,
        currentappMeta: action.payload,
      };
    case CHECKING_LATEST_META_REQUEST:
      return {
        ...state,
        checkingLatestMeta: true,
      };
    case CHECKING_LATEST_META_SUCCESS:
      return {
        ...state,
        checkingLatestMeta: false,
      };
    case CHECKING_LATEST_META_FAILURE:
      return {
        ...state,
        checkingLatestMeta: false,
      };
    case SHOW_RELEASE_NOTICE:
      return {
        ...state,
        showReleaseNotice: true,
      };
    default:
      return state;
  }
}

export function fetchInitialMeta() {
  return (dispatch, getState) => {
    if (getState().appmeta.defaultMetaLoading) return;
    dispatch(initMetaRequest());
    return appMetaService
      .getCurrentMeta()
      .then((data) => {
        dispatch(initMetaRequestSuccess(data));
      })
      .catch((err) => {
        dispatch(initMetaRequestError(err));
      });
  };
}

export function checkLetestMeta() {
  return (dispatch, getState) => {
    if (getState().appmeta.defaultMetaLoading) return;
    if (getState().appmeta.checkingLatestMeta) return;
    if (getState().appmeta.error) return fetchInitialMeta();
    dispatch(checkLatestMetaRequest());
    return appMetaService
      .getCurrentMeta()
      .then((data) => {
        const currentAppMeta = getState().appmeta.currentappMeta;
        const latestAppMeta = data;
        if (currentAppMeta.version < latestAppMeta.version) {
          const forceLogoutPrevRelease =
            currentAppMeta.version < latestAppMeta.lastforcelogoutversion;
          if (latestAppMeta.forcelogout || forceLogoutPrevRelease) {
            //user will be logout
            setTimeout(() => {
              dispatch(requestLogoutStatus(true));
            }, 9000);
            dispatch(updateShowReleaseNotice(true));
          } else {
            //page will be refreshed and new version available
            setTimeout(() => {
              window.location.reload(true);
            }, 9000);
            dispatch(updateShowReleaseNotice(true));
          }
        } else {
          // no action is required.
        }
        dispatch(checkLatestMetaRequestSuccess());
      })
      .catch(() => {
        dispatch(checkLatestMetaRequestfailure());
      });
  };
}

export function initMetaRequest() {
  return { type: INITIAL_META_REQUEST };
}

export function initMetaRequestSuccess(payload) {
  return { type: INITIAL_META_REQUEST_SUCCESS, payload };
}

export function initMetaRequestError(payload) {
  return { type: INITIAL_META_REQUEST_FAILURE, payload };
}

export function checkLatestMetaRequest() {
  return { type: CHECKING_LATEST_META_REQUEST };
}

export function checkLatestMetaRequestSuccess() {
  return { type: CHECKING_LATEST_META_SUCCESS };
}

export function checkLatestMetaRequestfailure() {
  return { type: CHECKING_LATEST_META_FAILURE };
}

export function updateShowReleaseNotice(payload) {
  return { type: SHOW_RELEASE_NOTICE, payload };
}
