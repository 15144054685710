import React, { Component } from 'react';
import { connect } from 'react-redux';
import webinarService from 'services/api/webinarService';
import authService from 'services/api/authService';
import fetchHOC from 'components/high-order-components/FetchHOC';
import _ from 'lodash';
import testRequestService from '../../../../services/api/testRequestService';
import { closeLoader, showLoader } from '../../../../ducks/ui';
import { loadLabTest } from '../../../../ducks/geneticTest';
import { clearTestRequestData } from '../../../../ducks/testRequestWizard';
import GlobalLoader from '../../../../components/widgets/GlobalLoader/GlobalLoader';

class TestRequestRegistrationEntryPoint extends Component {
  componentDidMount() {
    const {
      match: { params },
      location: { search },
      dispatch,
      loadLabTest,
      history,
    } = this.props;
    let newUrl = '/test/register';
    const partner = params.partner && params.partner.toLowerCase();
    const queryParams = new URLSearchParams(search);

    this.clearSessionStorage();
    try {
      const lab = queryParams.get('lab');
      const test = queryParams.get('test');
      if (partner && lab && test) {
        dispatch(showLoader());
        this.props.partners.fetch().then(() => {
          const { partners } = this.props;
          const partnersData = _.get(partners, 'data', []);
          let knownPartner = partnersData.find(
            (a) => a.name && a.name !== null && a.name.toLowerCase() === partner
          );
          if (!_.isEmpty(knownPartner)) {
            const data = {
              labName: lab,
              testName: test,
              partnerID: knownPartner.id,
            };
            return loadLabTest(data).then((res) => {
              authService.saveAffiliationId(knownPartner?.id);
              authService.saveAffiliation(knownPartner);
              if (res.uuid) testRequestService.saveGeneticTestUUID(res.uuid);
              if (res.lab_uuid) testRequestService.saveTestRequestLabUUID(res.lab_uuid);

              testRequestService.saveGeneticTestDetai({
                testDisplayName: res.display_name,
                labDisplayName: _.get(res, ['labs', '0', 'display_name']),
                PatientDisplayName: _.get(res, ['patient_display_name'], null),
                price: _.get(res,['price'])
              });
              dispatch(closeLoader());
              history.push(newUrl);
              return res;
            });
          } else {
            this.redirectToLogin();
          }
        });
      } else {
        this.redirectToLogin();
      }
    } catch (error) {
      this.redirectToLogin();
    }
  }

  redirectToLogin() {
    const { dispatch, history } = this.props;
    dispatch(closeLoader());
    history.push('/login');
  }

  clearSessionStorage() {
    clearTestRequestData();
    authService.resetAffiliationId();
    authService.resetAffiliation();
    testRequestService.clearGeneticTestUUID();
    testRequestService.clearTestRequestLabUUID();
    testRequestService.clearGeneticTestDetai();
  }

  render() {
    return (
      <div>
        <GlobalLoader />
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {};
  },
  (dispatch) => {
    return {
      dispatch,
      loadLabTest: (data) => dispatch(loadLabTest(data)),
    };
  }
)(
  fetchHOC({
    partners: () => webinarService.getPartners(),
  })(TestRequestRegistrationEntryPoint)
);
