import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import SetPasswordForm from 'components/forms/SetPasswordForm/SetPasswordForm';
import PrivacyPolicyForm from 'components/forms/SetPasswordForm/PrivacyPolicyForm';
import ConsentForm from 'components/forms/SetPasswordForm/ConsentForm';
import ABNPage from 'components/forms/SetPasswordForm/ABNPage';
import { destroy, SubmissionError } from 'redux-form';
import SchedulingIntroPage from '../RegistrationWizardPage/SchedulingIntroPage';
const SETPASSWORD_FORM = 'SETPASSWORD_FORM';
const ABN_FORM = 'ABN_FORM';
const PRIVACY_FORM = 'PRIVACY_FORM';
const CONSENT_FORM = 'CONSENT_FORM';
const SCHEDULING_SPLASH_SCREEN = 'SCHEDULING_SPLASH_SCREEN';

const ABNFields = {
  abnDate: 1,
  abnOption: 1,
  abnSignature: 1,
};
const defaultErrors = {
  ABN_FORM: {},
};

class SetPasswordcontainer extends Component {
  state = {
    activeStage: SETPASSWORD_FORM,
    isABNFlow: false,
    errors: { ...defaultErrors },
  };

  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const queryParams = new URLSearchParams(search);
    const showSchedulingSplash = queryParams.get('show_scheduling_splash');
    if (showSchedulingSplash && showSchedulingSplash === 'true') {
      this.setState({ activeStage: SCHEDULING_SPLASH_SCREEN });
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(destroy('SetPasswordForm'));
  }

  goback = (goToStage, valid = undefined, errorStage = undefined) => {
    if (_.isBoolean(valid) && valid != false) {
      const errors = { ...this.state.errors };
      errors[errorStage] = {};
      this.setState({
        activeStage: goToStage,
        errors,
      });
    } else this.setState({ activeStage: goToStage });
    return false;
  };

  submitSetPasswordInfo = (values) => {
    if (this.props.showTos) {
      return new Promise((resolve) => {
        resolve(values);
      }).then(() => {
        if (this.props.isABNFlow) {
          this.setState({ activeStage: ABN_FORM });
          const ABNErrors = this.state.errors.ABN_FORM;
          throw new SubmissionError(ABNErrors);
        } else this.setState({ activeStage: PRIVACY_FORM });
      });
    } else {
      this.props.onResetSubmit(values);
    }
  };

  submitABNInformation = (values) => {
    return new Promise((resolve) => {
      resolve(values);
    }).then(() => {
      const errors = { ...this.state.errors };
      errors[ABN_FORM] = {};
      this.setState({ activeStage: PRIVACY_FORM, errors });
    });
  };
  submitPrivacyInformation = (values) => {
    return new Promise((resolve) => {
      resolve(values);
    }).then(() => {
      this.setState({ activeStage: CONSENT_FORM });
    });
  };

  submitConsentForm = (values) => {
    values =
      values.abnDate && moment(values.abnDate, 'MM/DD/YYYY', true).isValid()
        ? { ...values, abnDate: moment(values.abnDate).format('YYYY-MM-DD') }
        : { ...values };
    this.setState({ errors: { ...defaultErrors } });
    return this.props
      .onSubmit(values)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        const errors = { ...this.state.errors };
        const setPasswordKeys = _.difference(_.keys(_.get(err, 'errors', {})), _.keys(ABNFields));
        const abnFormKeys = _.intersection(_.keys(_.get(err, 'errors', {})), _.keys(ABNFields));
        if (_.get(abnFormKeys, 'length', 0) > 0) {
          _.map(abnFormKeys, (key) => {
            errors[ABN_FORM][key] = _.get(err, ['errors', key], '');
          });
          this.setState({ errors });
        }
        if (
          this.props.isABNFlow & (_.get(setPasswordKeys, 'length', 0) == 0) &&
          _.get(abnFormKeys, 'length', 0) > 0
        ) {
          this.setState({ activeStage: ABN_FORM });
        } else {
          this.setState({ activeStage: SETPASSWORD_FORM });
        }

        throw err;
      });
  };

  get fullName() {
    const { is_unborn, users_guardian, first_name, last_name } = this.props.CheckPasswordData;
    const PatientFullName = `${first_name || ''} ${last_name || ''}`;
    if (is_unborn) {
      const { last_name: guardianLastName } = users_guardian;
      return `Baby ${guardianLastName || ''}`;
    }
    return PatientFullName;
  }

  get guardianFullName() {
    const { has_guardian, first_name, last_name, users_guardian } = this.props.CheckPasswordData;

    const PatientFullName = `${first_name || ''} ${last_name || ''}`;
    if (has_guardian) {
      const { first_name: guardianFirstName, last_name: guardianLastName } = users_guardian;
      return `${guardianFirstName || ''} ${guardianLastName || ''}`;
    }
    return PatientFullName;
  }

  render() {
    const { activeStage } = this.state;
    const { formError, emailValue, showTos, CheckPasswordData } = this.props;
    return (
      <Fragment>
        {activeStage == SCHEDULING_SPLASH_SCREEN && (
          <SchedulingIntroPage
            partnerName={_.get(CheckPasswordData, ['partner', 'name'])}
            setActiveStage={() => {
              this.setState({ activeStage: SETPASSWORD_FORM });
            }}
          />
        )}
        {activeStage === SETPASSWORD_FORM && (
          <SetPasswordForm
            emailValue={emailValue}
            onSubmit={this.submitSetPasswordInfo}
            formError={formError}
            initialValues={{ email: emailValue, guardianFullName: this.guardianFullName }}
            showTos={showTos}
          />
        )}
        {activeStage === ABN_FORM && (
          <ABNPage
            onSubmit={this.submitABNInformation}
            formError={formError}
            goBack={(valid) => this.goback(SETPASSWORD_FORM, valid, ABN_FORM)}
            fullName={this.fullName}
            guardianFullName={this.guardianFullName}
          />
        )}
        {activeStage === PRIVACY_FORM && (
          <PrivacyPolicyForm
            onSubmit={this.submitPrivacyInformation}
            formError={formError}
            goBack={() => this.goback(this.props.isABNFlow ? ABN_FORM : SETPASSWORD_FORM)}
          />
        )}
        {activeStage === CONSENT_FORM && (
          <ConsentForm
            onSubmit={this.submitConsentForm}
            formError={formError}
            goBack={() => this.goback(PRIVACY_FORM)}
          />
        )}
      </Fragment>
    );
  }
}

SetPasswordcontainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(null)(SetPasswordcontainer);
