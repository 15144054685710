import _ from 'lodash';
import { reset } from 'redux-form';
import usersService from 'services/api/usersService';
import authService from 'services/api/authService';
import { meLoadProfile } from 'ducks/user';
import { showLoader, closeLoader } from 'ducks/ui';
import { loadConsetDocuments } from 'ducks/documents';
import StatusMessages from 'constants/StatusMessages';
import api from 'services/api/api';

export const changePasswordFormName = 'change-password-form';

const ENTER_PROFILE_PAGE = 'gm/profile/ENTER_PROFILE_PAGE';
const RESET_STATUS = 'gm/profile/RESET_STATUS';
const UPDATE_START = 'gm/profile/UPDATE_START';
const UPDATE_SUCCESS = 'gm/profile/UPDATE_SUCCESS';
const UPDATE_FAILURE = 'gm/profile/UPDATE_FAILURE';
const GO_TO_STEP = 'gm/profile/GO_TO_STEP';
const CLICK_TO_STEP = 'gm/profile/CLICK_TO_STEP';

export const SELECTION_PROFILE_STEP = 0;
export const USER_INFO_PROFILE_STEP = 1;
export const CHANGE_PASSWORD_PROFILE_STEP = 2;
export const PAYMENT_METHOD_PROFILE_STEP = 2;
export const OLD_PAYMENT_METHOD_PROFILE_STEP = 3;

const getInitialState = () => {
  let steps = [
    { header: 'Profile selection', done: false, icon: 'card' },
    { header: 'Personal Information', done: false, icon: 'card' },
  ]
    .concat(api.hasLegacyToken() ? [{ header: 'Change Password', done: false, icon: 'lock' }] : [])
    .concat([{ header: 'Payment Information', done: false, icon: 'payment' }]);
  return {
    operationResult: null,
    statusMessage: null,
    steps: steps,
    activeStepIndex: USER_INFO_PROFILE_STEP,
  };
};

export default function reducer(state = getInitialState(), action = {}) {
  let step, steps;

  switch (action.type) {
    case ENTER_PROFILE_PAGE:
      return getInitialState();
    case RESET_STATUS:
    case UPDATE_START:
      return { ...state, operationResult: null };
    case UPDATE_SUCCESS:
      return { ...state, operationResult: true, statusMessage: action.payload };
    case UPDATE_FAILURE:
      return {
        ...state,
        operationResult: false,
        statusMessage: action.payload || StatusMessages.generalError,
      };
    case CLICK_TO_STEP:
      step = action.payload.step;
      if (step < 0) step = 0;
      if (step >= state.steps.length) step = state.steps.length - 1;
      steps = _.cloneDeep(state.steps);
      return { steps, activeStepIndex: step };
    case GO_TO_STEP:
      step = action.payload.step;
      if (step < 0) step = 0;
      if (step >= state.steps.length) step = state.steps.length - 1;
      if (step > state.activeStepIndex + 1) step = state.activeStepIndex + 1;
      if (state.steps[step].done) return Object.assign({}, state, { activeStepIndex: step });
      steps = _.cloneDeep(state.steps);
      return { steps, activeStepIndex: step };

    default:
      return state;
  }
}

export function resetStatus() {
  return { type: RESET_STATUS };
}

export function updateStart() {
  return { type: UPDATE_START };
}

export function updateSuccess(message) {
  return { type: UPDATE_SUCCESS, payload: message };
}

export function updateFailure(message) {
  return { type: UPDATE_FAILURE, payload: message };
}

export function enterProfilePage() {
  return { type: ENTER_PROFILE_PAGE };
}

export function goToStep(step) {
  return { type: GO_TO_STEP, payload: { step } };
}

export function clickToStep(step) {
  return { type: CLICK_TO_STEP, payload: { step } };
}

export function updateProfile(values, loadMe = true) {
  return (dispatch) => {
    dispatch(updateStart());
    return usersService
      .updateMyProfile(values)
      .then(() => {
        dispatch(updateSuccess(StatusMessages.updateProfile));
        loadMe && dispatch(meLoadProfile());
      })
      .catch((error) => {
        if (error.submissionError) throw error.submissionError;
        return dispatch(updateFailure(error.message));
      });
  };
}

export function updateConsent(values, isABNFlow = false) {
  return (dispatch) => {
    dispatch(showLoader());
    dispatch(updateStart());
    return usersService
      .updateConsent(values)
      .then(() => (isABNFlow === true ? dispatch(loadConsetDocuments(0)) : Promise.resolve()))
      .then(() => {
        dispatch(closeLoader());
        return;
      })
      .catch((error) => {
        dispatch(closeLoader());
        if (error.submissionError) throw error.submissionError;
        dispatch(updateFailure(StatusMessages.generalError));
        throw error;
      });
  };
}

export function changePassword(values) {
  return (dispatch) => {
    dispatch(updateStart());
    return authService
      .changePassword(values)
      .then(() => {
        dispatch(updateSuccess(StatusMessages.updatePassword));
        dispatch(reset(changePasswordFormName));
      })
      .catch((error) => {
        if (error.submissionError) throw error.submissionError;
        return dispatch(updateFailure(error.message));
      });
  };
}
