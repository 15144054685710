import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import SignupFormV2 from 'components/forms/WizardForm/CreateAccountForm/SignupFormV2';
import PrivacyPolicyForm from 'components/forms/WizardForm/CreateAccountForm/PrivacyPolicyForm';
import ConsentForm from 'components/forms/WizardForm/CreateAccountForm/ConsentForm';
import ABNForm from 'components/forms/WizardForm/CreateAccountForm/ABNForm';
import moment from 'moment';
import { getRaceEnums, getEthnicityEnums } from 'ducks/enums';
import {
  getReferralSource,
  GenentechReferral,
  getWarmReferralSource,
  getWarmReferralURL,
} from 'services/misc/ReferralSource';
import { destroy, SubmissionError } from 'redux-form';
const REGISTER_FORM = 'REGISTER_FORM';
const ABN_FORM = 'ABN_FORM';
const PRIVACY_FORM = 'PRIVACY_FORM';
const CONSENT_FORM = 'CONSENT_FORM';

const ABNFields = {
  abnDate: 1,
  abnOption: 1,
  abnSignature: 1,
};
const defaultErrors = {
  ABN_FORM: {},
};

class CreateAccountPage extends Component {
  /* set isMedicareBenificiary to string "default" as there is bug in redux Form which is consider undefined null and false
    as a same so it will not rerender the component when someone directly choose the false value from inital value undefined and null*/
  state = {
    activeStage: REGISTER_FORM,
    registrationInfo: {
      isPatient: true,
      isNotPatient: false,
      isMedicareBeneficiary: 'default',
      primaryPhoneConsentToText: true,
      guardianPrimaryPhoneConsentToText: true,
    },
    isABNFlow: false,
    errors: { ...defaultErrors },
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(destroy('sign-up-form'));
  }

  submitRegistrationInformation = (values) => {
    return new Promise((resolve) => {
      resolve(values);
    }).then((values) => {
      if (values && values.isMedicareBeneficiary && values.isMedicareBeneficiary === true) {
        this.setState({ activeStage: ABN_FORM, isABNFlow: true });
        const ABNErrors = this.state.errors.ABN_FORM;
        throw new SubmissionError(ABNErrors);
      } else {
        this.setState({ activeStage: PRIVACY_FORM, isABNFlow: false });
      }
    });
  };

  goback = (goToStage, valid = undefined, errorStage = undefined) => {
    if (_.isBoolean(valid) && valid != false) {
      const errors = { ...this.state.errors };
      errors[errorStage] = {};
      this.setState({ activeStage: goToStage, errors });
    } else this.setState({ activeStage: goToStage });
    return false;
  };

  submitABNInformation = (values) => {
    return new Promise((resolve) => {
      resolve(values);
    }).then(() => {
      const errors = { ...this.state.errors };
      errors[ABN_FORM] = {};
      this.setState({ activeStage: PRIVACY_FORM, errors });
    });
  };

  submitPrivacyInformation = (values) => {
    return new Promise((resolve) => {
      resolve(values);
    }).then(() => {
      this.setState({ activeStage: CONSENT_FORM });
    });
  };

  submitConsentForm = (values) => {
    const referral = this.props.referral || getReferralSource();
    values = referral == GenentechReferral ? { ...values, areaInterest: 'Other' } : { ...values };
    values = { ...values, isPatient: !values.isNotPatient, isPatientUnborn: false };
    values =
      values.dob && moment(values.dob, 'MM/DD/YYYY', true).isValid()
        ? { ...values, dob: moment(values.dob).format('YYYY-MM-DD') }
        : { ...values };

    values =
      values.abnDate && moment(values.abnDate, 'MM/DD/YYYY', true).isValid()
        ? { ...values, abnDate: moment(values.abnDate).format('YYYY-MM-DD') }
        : { ...values };

    values =
      values.isMedicareBeneficiary && values.isMedicareBeneficiary === 'default'
        ? { ...values, isMedicareBeneficiary: null }
        : { ...values };

    values =
      values.guardianDob && moment(values.guardianDob, 'MM/DD/YYYY', true).isValid()
        ? { ...values, guardianDob: moment(values.guardianDob).format('YYYY-MM-DD') }
        : { ...values };

    values = !_.isEmpty(values.guardianPronouns)
      ? { ...values, guardianPronouns: Object.keys(values.guardianPronouns) }
      : { ...values };

    values = !_.isEmpty(values.pronouns)
      ? { ...values, pronouns: Object.keys(values.pronouns) }
      : { ...values };

    this.setState({ errors: { ...defaultErrors } });
    return this.props
      .submitAccountInformation({
        ...values,
        referral: this.props.referral || getReferralSource(),
        consentToTreat: 'consented_via_portal',
        warm_referral_src: getWarmReferralSource(),
        warm_referral_url: getWarmReferralURL(),
      })
      .catch((err) => {
        const errors = { ...this.state.errors };
        const signupFormKeys = _.difference(_.keys(_.get(err, 'errors', {})), _.keys(ABNFields));
        const abnFormKeys = _.intersection(_.keys(_.get(err, 'errors', {})), _.keys(ABNFields));
        if (_.get(abnFormKeys, 'length', 0) > 0) {
          _.map(abnFormKeys, (key) => {
            errors[ABN_FORM][key] = _.get(err, ['errors', key], '');
          });
          this.setState({ errors });
        }
        if (
          values.isMedicareBeneficiary == true &&
          _.get(signupFormKeys, 'length', 0) == 0 &&
          _.get(abnFormKeys, 'length', 0) > 0
        ) {
          this.setState({ activeStage: ABN_FORM });
        } else {
          this.setState({ activeStage: REGISTER_FORM });
        }

        throw err;
      });
  };

  render() {
    const { activeStage, registrationInfo } = this.state;
    const { formError, showMedicareQuestion, hasAuth0, email } = this.props;
    return (
      <Fragment>
        {activeStage === REGISTER_FORM && (
          <SignupFormV2
            onSubmit={this.submitRegistrationInformation}
            formError={formError}
            initialValues={{ ...registrationInfo, email }}
            showMedicareQuestion={showMedicareQuestion}
            hasAuth0={hasAuth0}
          />
        )}
        {activeStage === ABN_FORM && (
          <ABNForm
            onSubmit={this.submitABNInformation}
            formError={formError}
            goBack={(valid) => this.goback(REGISTER_FORM, valid, ABN_FORM)}
          />
        )}
        {activeStage === PRIVACY_FORM && (
          <PrivacyPolicyForm
            onSubmit={this.submitPrivacyInformation}
            formError={formError}
            goBack={() => this.goback(this.state.isABNFlow ? ABN_FORM : REGISTER_FORM)}
          />
        )}
        {activeStage === CONSENT_FORM && (
          <ConsentForm
            onSubmit={this.submitConsentForm}
            formError={formError}
            goBack={() => this.goback(PRIVACY_FORM)}
          />
        )}
      </Fragment>
    );
  }
}

CreateAccountPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(null, (dispatch) => ({
  getRaceEnums: () => dispatch(getRaceEnums()),
  getEthnicityEnums: () => dispatch(getEthnicityEnums()),
  dispatch: dispatch,
}))(CreateAccountPage);
