import React from 'react';
import { connect } from 'react-redux';
import { requestLogoutStatus } from 'ducks/user';
import './noaccess.scss';

const NoAccessPage = (props) => {
  const { requestLogout } = props;

  return (
    <div className="no-access">
      <div className="noaccesstext">
        Unfortunately, we were unable to complete your request using the email address you provided.
        If you are trying to log in using your work email address, please try using your personal
        email address to manage your patient account. If you need additional assistance, please call{' '}
        <a href="tel:+18776880992">(877) 688-0992</a>
        <br /> <br />
        <span className="noaccess_goback" onClick={() => requestLogout()}>
          Logout
        </span>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  requestLogout: () => dispatch(requestLogoutStatus(true)),
});

export default connect(null, mapDispatchToProps)(NoAccessPage);
