import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';

import {
  STAGE_SPLASH_SCREEN,
  STAGE_AUTH0_SIGNUP,
  ELIGIBILITY_CHECK,
  SELECTION_DETAILS,
  CREATE_ACCOUNT,
} from 'services/wizardWorkflows';
import authService from 'services/api/authService';
import {
  submitAccount,
  submitAuditLogs,
  verifyEligibility,
  verifySelection,
} from 'ducks/registrationWizard';
import Auth0SignupPage from './Auth0SignupPage';
import SchedulingIntroPage from './SchedulingIntroPage';
import EligibilityCheckForm from 'components/forms/WizardForm/EligibilityCheck/EligibilityCheckForm';
import SelectionForm from 'components/forms/WizardForm/SelectionForm/SelectionForm';
import {
  clearWarmReferralSource,
  clearWarmReferralURL,
  getReferralPartnerName,
  getStage,
  removeStage,
  setStage,
} from 'services/misc/ReferralSource';
import CreateAccountPage from './CreateAccountPage';
import schedulingService from 'services/api/schedulingService';
import './RegistrationWizardPage.scss';
import { EligibilityFlowPartners, SelectionFlowPartners } from 'constants/CommonConstants';
import eligibilityService from 'services/api/eligibilityService';
import selectionService from 'services/api/selectionService';
import { closeLoader, loaderSession, showLoader } from 'ducks/ui';
import { downloadConsentDocuments } from 'ducks/documents';
import { updateHoldLoggedIn } from 'ducks/user';
import ABNDownloadModal from 'components/modals/SCP/ABNDownLoadModal/ABNDownloadModal';
import { AUTH0_CONFIG, AUTH0_LOGIN_ENABLED } from 'containers/Auth0Wrapper';
import api from 'services/api/api';
import gaTrack, { GA_TR_DOWNLOAD_PAGE } from 'services/gaTrack';

const RegistrationWizardPage = (props) => {
  const {
    history,
    dispatch,
    gotoLogin,
    registrationWizard,
    auth0,
    gotoThankYouPage,
    gotoHome,
    goToSelectService,
  } = props;
  const { user, getAccessTokenSilently } = auth0;
  const { formError } = registrationWizard;
  const showMedicareQuestion = schedulingService.getShowMedicare();
  const referral = getReferralPartnerName() || 'DTC_Proactive';

  const [activeStage, setActiveStage] = useState();
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [continueHandler, setContinueHandler] = useState(() => () => {});

  useEffect(() => {
    const exsistingStage = getStage();
    if (exsistingStage) {
      handleActiveStage(exsistingStage);
    } else {
      const service = authService.getPreselectedService();
      if (service) handleActiveStage(STAGE_SPLASH_SCREEN);
      else handleAfterSplashStage();
    }
  }, []);

  const handleActiveStage = (stage) => {
    setActiveStage(stage);
    setStage(stage);
  };

  const handleAfterSplashStage = () => {
    if (EligibilityFlowPartners.includes(referral)) handleActiveStage(ELIGIBILITY_CHECK);
    else if (SelectionFlowPartners.includes(referral)) handleActiveStage(SELECTION_DETAILS);
    else handleActiveStage(STAGE_AUTH0_SIGNUP);
  };

  const submitEligibilityCheck = async (values) => {
    dispatch(showLoader());
    return verifyEligibility(referral, values, dispatch)
      .then((res) => {
        dispatch(closeLoader());
        if (_.get(res, ['action'], '') == 'redirect_to_login') {
          gotoLogin();
          return;
        } else if (_.get(res, ['action']) == 'nothing_to_do') {
          return;
        }
        handleActiveStage(STAGE_AUTH0_SIGNUP);
      })
      .catch(() => {
        dispatch(closeLoader());
      });
  };

  const submitSelection = async (values) => {
    dispatch(showLoader());
    try {
      await verifySelection(referral, values, dispatch);
      dispatch(closeLoader());
      handleActiveStage(STAGE_AUTH0_SIGNUP);
    } catch (err) {
      dispatch(closeLoader());
      throw err;
    }
  };

  const submitAccountInformation = (values) => {
    const preSelected = authService.getPreselectedService();
    const eligibilityData = eligibilityService.getVerificationData();
    const selectionData = selectionService.getVerificationData();
    if (eligibilityData) {
      const { eligibility_token, additionalData } = eligibilityData;
      values = { ...values, eligibility: { eligibility_token, additionalData } };
    }
    if (selectionData) {
      const { selection_token, additionalData } = selectionData;
      values = { ...values, selection: { selection_token, additionalData } };
    }
    const istokenstored =
      !AUTH0_LOGIN_ENABLED && values.referral && values.referral === 'Genentech' ? false : true;
    const isABNFlow =
      values && values.isMedicareBeneficiary && values.isMedicareBeneficiary === true;
    return loaderSession(
      dispatch,
      submitAccount(values, dispatch, istokenstored, isABNFlow).then(async () => {
        const selectionreferral = values.referral === 'Genentech';
        clearSessionStorage();
        if (AUTH0_LOGIN_ENABLED) {
          const at = await getAccessTokenSilently({
            authorizationParams: {
              audience: AUTH0_CONFIG.audience,
            },
          });
          api.setToken(`Bearer ${at}`);
        }
        let handler = () => {};
        if (selectionreferral && selectionData) {
          const { additionalData } = selectionData;
          if (additionalData) handler = gotoThankYouPage;
          else handler = preSelected ? goToSelectService : gotoHome;
        } else {
          handler = preSelected ? goToSelectService : gotoHome;
        }
        if (istokenstored && isABNFlow) {
          gaTrack(GA_TR_DOWNLOAD_PAGE);
          setContinueHandler(() => handler);
          setDownloadModalVisible(true);
        } else {
          handler();
        }
      })
    );
  };

  const clearSessionStorage = () => {
    clearWarmReferralSource();
    clearWarmReferralURL();
    removeStage();
    schedulingService.removeShowMedicare();
  };
  const redirectAfterAuth0 = async () => {
    try {
      dispatch(showLoader());
      const at = await getAccessTokenSilently({
        authorizationParams: {
          audience: AUTH0_CONFIG.audience,
        },
      });
      const token = `Bearer ${at}`;
      const res = await authService.checkLink({ token, type: 'auth0' });
      dispatch(closeLoader());
      if (res && res.user && !_.isEmpty(res.user)) {
        api.setToken(token);
        gotoHome();
      } else handleActiveStage(CREATE_ACCOUNT);
    } catch (_) {
      dispatch(closeLoader());
      handleActiveStage(CREATE_ACCOUNT);
    }
  };

  const handleContinueHandler = () => {
    const values = { reason: 'Continue' };
    return dispatch(submitAuditLogs(values)).then(() => {
      setDownloadModalVisible(false);
      dispatch(updateHoldLoggedIn(false));
      continueHandler();
    });
  };

  const downloadConsentDocs = () => {
    dispatch(downloadConsentDocuments());
  };
  const renderTosModal = () => {
    return (
      <ABNDownloadModal
        roleModal={'scp-modal'}
        description={
          <div className="abn-content">
            A copy of your signed ABN document has been generated. You can{' '}
            <span
              onClick={() => {
                downloadConsentDocs();
              }}
            >
              download here{' '}
            </span>
            or access it through your patient portal.
          </div>
        }
        show={true}
        confirmText="Continue"
        onConfirm={() => {
          handleContinueHandler();
        }}
        onHide={() => setDownloadModalVisible(false)}
      />
    );
  };

  return (
    <div className="scheduling-page full-height no-footer">
      {activeStage == STAGE_SPLASH_SCREEN && (
        <SchedulingIntroPage setActiveStage={() => handleAfterSplashStage()} hasAuth0={true} />
      )}
      {activeStage === ELIGIBILITY_CHECK && (
        <EligibilityCheckForm
          partner={getReferralPartnerName()}
          onSubmit={submitEligibilityCheck}
          formError={formError}
          history={history}
          redirectTo={handleActiveStage}
        />
      )}
      {activeStage === SELECTION_DETAILS && (
        <SelectionForm
          partner={getReferralPartnerName()}
          onSubmit={submitSelection}
          formError={formError}
          history={history}
          redirectTo={handleActiveStage}
        />
      )}
      {activeStage == STAGE_AUTH0_SIGNUP && (
        <Auth0SignupPage redirectAfterAuth0={redirectAfterAuth0} />
      )}
      {activeStage === CREATE_ACCOUNT && (
        <CreateAccountPage
          submitAccountInformation={submitAccountInformation}
          formError={formError}
          showMedicareQuestion={showMedicareQuestion}
          hasAuth0={true}
          email={user?.email}
        />
      )}

      {downloadModalVisible && renderTosModal()}
    </div>
  );
};

const mapStateToProps = ({ registrationWizard, user, enums }) => ({
  registrationWizard,
  holdLoggedIn: user.holdLoggedIn,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { registrationWizard, holdLoggedIn } = stateProps;
  const { dispatch } = dispatchProps;
  const { history, match } = ownProps;
  const selectedService = match.params.service;
  const location = ownProps.location;
  return {
    dispatch,
    registrationWizard,
    holdLoggedIn,
    selectedService,
    location,
    gotoLogin: () => history.push('/'),
    gotoThankYouPage: () => history.push(`/selection/thankyou`),
    goToSelectService: () => history.push(`/scheduling/select-service`),
    gotoHome: () => history.push('/patient/home'),
    history,
  };
};

export default connect(
  mapStateToProps,
  null,
  mergeProps
)(withRouter(withAuth0(RegistrationWizardPage)));
