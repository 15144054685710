import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import RedirectFromOutside from './RedirectFromOutside';
import { setReferralPartnerName } from 'services/misc/ReferralSource';
import { closeLoader, showLoader } from 'ducks/ui';
import usersService from 'services/api/usersService';
import authService from 'services/api/authService';
import SchedulingIntroPage from './public/RegistrationWizardPage/SchedulingIntroPage';
import GeneralLayout, { PUBLIC_LAYOUT_TYPE_V2 } from '../layouts/GeneralLayout';

const AppointmentOutreach = (props) => {
  const {
    match,
    history,
    location: { search },
    dispatch,
  } = props;
  const { params } = match;
  const queryParams = new URLSearchParams(search);
  const patientUUID = queryParams.get('patient_uuid');
  const actionToken = queryParams.get('action_token');
  const isLegacy = queryParams.get('legacy');
  const appointmentId = _.get(params, 'appointmentId');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!patientUUID || actionToken || isLegacy) return;
    const getPartnerDetail = async () => {
      try {
        dispatch(showLoader());
        let URL = '/patient/outreach';
        if (appointmentId) {
          URL += `/${appointmentId}`;
        }
        authService.saveRedirectionAction(URL);
        const res = await usersService.getUserInfo(patientUUID);
        if (res?.partner?.name) {
          setReferralPartnerName(res.partner.name);
        }
      } finally {
        setLoading(false);
        dispatch(closeLoader());
      }
    };

    getPartnerDetail();
  }, [patientUUID]);

  if (actionToken || isLegacy) {
    return (
      <GeneralLayout layoutType={PUBLIC_LAYOUT_TYPE_V2}>
        <RedirectFromOutside {...props} name="appointment-outreach" />
      </GeneralLayout>
    );
  }

  if (!loading) {
    return (
      <SchedulingIntroPage
        setActiveStage={() => history.push('/auth0/login')}
        textToScheduleFlow={true}
      />
    );
  }

  return null;
};

export default connect(null, (dispatch) => ({
  dispatch: dispatch,
}))(AppointmentOutreach);
