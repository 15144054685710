import _ from 'lodash';

const ADD_ACTION = 'gm/ui/ADD_ACTION';
const REMOVE_ACTION = 'gm/ui/REMOVE_ACTION';
const CLEAR_ALL_ACTIONS = 'gm/ui/CLEAR_ALL_ACTIONS';

// Types of redirects
export const REDIRECT_AFTER_LOGIN = 'REDIRECT_AFTER_LOGIN';
export const GOTO_WAITING_ROOM = 'GOTO_WAITING_ROOM';
export const OPEN_SWITCH_TO_PHONE = 'OPEN_SWITCH_TO_PHONE';
export const OPEN_SWITCH_TO_VIDEO = 'OPEN_SWITCH_TO_VIDEO';
export const DOCUMENT_VIEW = 'DOCUMENT_VIEW';
export const MANUAL_CONSENT_OUTREACH = 'MANUAL_CONSENT_OUTREACH';
export const OPEN_QUESTIONNAIRE = 'OPEN_QUESTIONNAIRE';
export const OPEN_OUTREACH_APPOINTMENT = 'OPEN_OUTREACH_APPOINTMENT';
export const REDIRECT_PATIENT = 'REDIRECT_PATIENT';

export default function reducer(state = { actions: {} }, action = {}) {
  switch (action.type) {
    case ADD_ACTION:
      return {
        ...state,
        actions: { ...state.actions, [action.payload.action]: action.payload.data },
      };
    case REMOVE_ACTION:
      return { ...state, actions: _.pickBy(state.actions, (value, key) => key !== action.payload) };
    case CLEAR_ALL_ACTIONS:
      return { ...state, actions: {} };
    default:
      return state;
  }
}

export function addAction(action, data) {
  return { type: ADD_ACTION, payload: { action, data: data || true } };
}

export function removeAction(action) {
  return { type: REMOVE_ACTION, payload: action };
}

export function clearAllActions() {
  return { type: CLEAR_ALL_ACTIONS };
}
