import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ResendPasswordLinkForm from 'components/forms/ResendPasswordLinkForm/ResendPasswordLinkForm';
import SetPasswordContainer from './SetPasswordContainer';
import ABNDownloadModal from 'components/modals/SCP/ABNDownLoadModal/ABNDownloadModal';
import _ from 'lodash';

import {
  setPassword,
  resetPassword,
  checkPasswordLink,
  sendSetPasswordLink,
} from 'ducks/auth/password';
import api from 'services/api/api';
import { showLoader, closeLoader } from 'ducks/ui';
import ResponseCodes from 'constants/ResponseCodes';
import gaTrack, {
  GA_TR_USER_SET_PASSWORD_PAGE,
  GA_TR_USER_RESET_PAGE,
  GA_TR_DOWNLOAD_PAGE,
} from '../../../../services/gaTrack';
import { downloadConsentDocuments, loadConsetDocuments } from 'ducks/documents';
import { submitAuditLogs } from 'ducks/registrationWizard';

class SetPasswordPage extends Component {
  static redirects = {
    service_selector: '/scheduling/select-service',
  };

  static compareErrorPasswordSet(error) {
    if (!error) return false;
    const { status, code } = error;
    return (
      status === ResponseCodes.passwordAlreadySet.status &&
      code === ResponseCodes.passwordAlreadySet.code
    );
  }

  static compareErrorTokenNotFound(error) {
    if (!error) return false;
    const { status, code } = error;
    return status == ResponseCodes.NotFound.status && code == ResponseCodes.NotFound.code;
  }

  static compareErrorReSendSendFound(error) {
    if (!error) return false;
    const { status, code } = error;
    return (
      (status == ResponseCodes.ExpiredReSendReset.status &&
        code == ResponseCodes.ExpiredReSendReset.code) ||
      (status == ResponseCodes.ExpiredSendReset.status &&
        code == ResponseCodes.ExpiredSendReset.code)
    );
  }

  static compareErrorTokenNotFoundInSet(error) {
    if (!error) return false;
    const { status, code } = error;
    return status == ResponseCodes.Expired.status && code == ResponseCodes.Expired.code;
  }

  static compareErrorTokenNotFoundInReset(error) {
    if (!error) return false;
    const { status, code } = error;
    return status == ResponseCodes.Expired.status && code == ResponseCodes.Expired.code;
  }

  state = { emailIsResend: false, downloadModalVisible: false };

  redirectAuthorizedUser = () => {
    const isSetPasswordPath = window.location.pathname.includes('/password/set');

    if (api.hasLegacyToken()) {
      const userScopes = api.scopesofToken();
      const scopeIncludeAstrik = userScopes.find((a) => a == '*');
      if (!scopeIncludeAstrik && isSetPasswordPath) {
        api.setToken(null);
      } else {
        this.props.history.push('/patient/home');
      }
    }
  };

  componentDidMount() {
    this.redirectAuthorizedUser();
    if (this.isReset) {
      gaTrack(GA_TR_USER_RESET_PAGE);
    } else {
      gaTrack(GA_TR_USER_SET_PASSWORD_PAGE);
    }
    this.redirectIfPasswordSet();
  }

  async redirectIfPasswordSet() {
    const { history } = this.props;
    try {
      const type = this.isReset ? 'reset' : 'set';
      await this.props.dispatch(
        checkPasswordLink({
          token: this.resetToken,
          type: type,
        })
      );
    } catch (e) {
      if (!this.isReset && this.passwordIsSetValidToken) history.push('/login');
      else throw e;
    }
  }
  handleSubmitSetPassword = (values) => {
    const { dispatch } = this.props;
    dispatch(showLoader());
    return dispatch(setPassword({ resetPasswordToken: this.resetToken, ...values }))
      .then(() => {
        return this.isABNFlow ? dispatch(loadConsetDocuments(0)) : Promise.resolve();
      })
      .then(() => {
        if (this.isABNFlow) {
          gaTrack(GA_TR_DOWNLOAD_PAGE);
          this.setState({ downloadModalVisible: true });
        } else {
          this.navigateToAfterLogin();
        }
        dispatch(closeLoader());
        return;
      })
      .catch((err) => {
        dispatch(closeLoader());
        throw err;
      });
  };
  navigateToAfterLogin = () => {
    if (api.hasToken()) {
      const defaultpath = '/patient/home';
      window.location.assign(this.props.redirectPath || this.redirectPathFromQuery || defaultpath);
    }
  };

  handleSubmit = async ({ password }) => {
    const { dispatch } = this.props;
    dispatch(showLoader());
    try {
      if (this.isReset) {
        await dispatch(
          resetPassword({ email: this.email, resetPasswordToken: this.resetToken, password })
        );
      }
    } catch (error) {
      dispatch(closeLoader());
    }
    if (api.hasToken()) {
      dispatch(closeLoader());
      const defaultpath = '/patient/home';
      window.location.assign(this.props.redirectPath || this.redirectPathFromQuery || defaultpath);
    } else {
      dispatch(closeLoader());
    }
  };

  handleSubmitSendEmail = async ({ captcha }) => {
    const { dispatch, history } = this.props;
    const email = this.email;
    if (this.state.emailIsResend) this.setState({ emailIsResend: false });
    try {
      await dispatch(sendSetPasswordLink({ email, captcha }));
      this.setState({ emailIsResend: true });
    } catch (e) {
      if (this.passwordIsSetInvalidToken) history.push('/password/forgot');
      else throw e;
    }
  };

  get passwordIsSetValidToken() {
    return SetPasswordPage.compareErrorPasswordSet(this.props.authLinkError);
  }

  get NotFound() {
    return SetPasswordPage.compareErrorTokenNotFound(this.props.authLinkError);
  }

  get ResetSendFound() {
    return SetPasswordPage.compareErrorReSendSendFound(this.props.authLinkError);
  }

  get passwordIsSetInvalidToken() {
    return SetPasswordPage.compareErrorPasswordSet(this.props.sendPasswordLinkError);
  }

  get ResetExpiredLink() {
    return SetPasswordPage.compareErrorTokenNotFoundInReset(this.props.resetPasswordExpiredError);
  }

  get SetExpiredLink() {
    return SetPasswordPage.compareErrorTokenNotFoundInSet(this.props.setPasswordExpiredError);
  }

  get isReset() {
    return this.props && this.props.reset;
  }

  get email() {
    return (this.props.authEmail && this.props.authEmail) || '';
  }

  get isABNFlow() {
    const { is_medicare_beneficiary } = this.props.CheckPasswordData;
    return !_.isNil(is_medicare_beneficiary) && is_medicare_beneficiary == true;
  }

  get resetToken() {
    return this.props.resetToken || this.props.match.params.resetPasswordToken;
  }

  get redirectPathFromQuery() {
    const {
      location: { search },
    } = this.props;
    const queryParams = new URLSearchParams(search);
    const next_step = queryParams.get('next_step');
    if (next_step) {
      return SetPasswordPage.redirects[next_step];
    }
    return '';
  }

  get redirectToLogin() {
    const reset = this.props.reset ? this.props.reset : undefined;
    const set = !this.props.reset ? true : undefined;
    return (
      (set && this.NotFound) || (reset && this.NotFound) || (set && this.passwordIsSetValidToken)
    );
  }

  get showResendForm() {
    const reset = this.props.reset ? this.props.reset : undefined;
    const set = !this.props.reset ? true : undefined;
    return (
      (set && this.ResetSendFound) ||
      (reset && this.ResetSendFound) ||
      (set && this.SetExpiredLink) ||
      (reset && this.ResetExpiredLink)
    );
  }

  renderRedirectToLogin = () => {
    if (this.redirectToLogin) {
      this.props.history.push('/login');
    }
  };

  get ShowSetPassword() {
    const { authLinkIsValid, authEmail } = this.props;
    return !(this.SetExpiredLink || this.ResetExpiredLink) && authLinkIsValid && authEmail
      ? true
      : false;
  }

  continueHandler = () => {
    const { dispatch } = this.props;
    const values = { reason: 'Continue' };
    return dispatch(submitAuditLogs(values)).then(() => {
      this.setState({ downloadModalVisible: false }, () => {
        this.navigateToAfterLogin();
      });
    });
  };

  downloadConsentDocs = () => {
    const { dispatch } = this.props;
    dispatch(downloadConsentDocuments());
  };

  renderTosModal = () => {
    return (
      <ABNDownloadModal
        roleModal={'scp-modal'}
        description={
          <div className="abn-content">
            A copy of your signed ABN document has been generated. You can{' '}
            <span
              onClick={() => {
                this.downloadConsentDocs();
              }}
            >
              download here{' '}
            </span>
            or access it through your patient portal.
          </div>
        }
        show={true}
        confirmText="Continue"
        onConfirm={() => this.continueHandler()}
        onHide={() => this.setState({ downloadModalVisible: false })}
      />
    );
  };

  render() {
    const {
      resetPasswordError,
      authLinkIsValid,
      sendPasswordLinkError,
      CheckPasswordData,
      location,
    } = this.props;
    if (authLinkIsValid === null) return null;
    const resendFormError =
      !this.passwordIsSet && sendPasswordLinkError && sendPasswordLinkError.message;
    this.renderRedirectToLogin();
    return (
      <div className="registration-page full-height">
        {this.ShowSetPassword === true ? (
          <SetPasswordContainer
            emailValue={this.email}
            onSubmit={this.handleSubmitSetPassword}
            onResetSubmit={this.handleSubmit}
            formError={resetPasswordError}
            initialValues={{ email: this.email }}
            showTos={!this.isReset}
            isABNFlow={this.isABNFlow}
            CheckPasswordData={CheckPasswordData}
            location={location}
          />
        ) : this.showResendForm === true ? (
          <ResendPasswordLinkForm
            formError={resendFormError}
            emailIsResend={this.state.emailIsResend}
            onSubmit={this.handleSubmitSendEmail}
          />
        ) : (
          <div />
        )}

        {this.state.downloadModalVisible && this.renderTosModal()}
      </div>
    );
  }
}

SetPasswordPage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  resetToken: PropTypes.string,
  email: PropTypes.string,
  redirectPath: PropTypes.string,
  resetPasswordError: PropTypes.string,
  authLinkIsValid: PropTypes.bool,
  authLinkError: PropTypes.object,
  sendPasswordLinkError: PropTypes.object,
  authEmail: PropTypes.string,
  isABNFlow: PropTypes.bool,
};

export default connect(
  (state) => {
    const {
      resetPasswordError,
      authLinkIsValid,
      authLinkError,
      sendPasswordLinkError,
      authEmail,
      CheckPasswordData,
      setPasswordExpiredError,
      resetPasswordExpiredError,
    } = state.auth.password;
    return {
      resetPasswordError,
      authLinkIsValid,
      authLinkError,
      sendPasswordLinkError,
      authEmail,
      CheckPasswordData,
      setPasswordExpiredError,
      resetPasswordExpiredError,
    };
  },
  (dispatch) => {
    return { dispatch };
  }
)(withRouter(SetPasswordPage));
